<template>
  <b-modal id="ssoi-modal" scrollable title="SSOI: New Report" @show="getPrefillData" @hidden="resetModal" size="xl">
    <template v-if="!isLoading">
      <ValidationObserver ref="form">
        <h4 class="mx-0 mb-3 ssoi-modal-title">{{ modalTitle }}</h4>
        <form>
          <b-row v-for="(fields, step) in steps" :key="step">
            <b-col v-if="currentStep === step">
              <b-row>
                <b-col v-for="(fieldname, fieldIndex) in fields" :key="fieldIndex" :md="getColSize(fieldname)">
                  <ValidationProvider :rules="validationRules(fieldname)" v-slot="{ valid, errors }">
                    <b-form-group
                      v-if="prefillData[currentIndex(fieldname)].name === fieldname"
                      :label="prefillData[currentIndex(fieldname)].label"
                      :label-for="fieldname"
                      invalid-feedback="This field is invalid"
                    >
                      <!-- Array of objects -->
                      <div
                        v-if="isOtherRevenueExpense(fieldname)"
                        :label="prefillData[currentIndex(fieldname)].label"
                        :label-for="fieldname"
                        class="flex flex-col gap-1 w-100 mt-3"
                      >
                        <b-row
                          v-for="(subfield, subfieldIndex) in prefillData[currentIndex(fieldname)].array_schema"
                          :key="subfieldIndex"
                        >
                          <b-col md="6" v-for="(subsubfield, subsubfieldIndex) in subfield" :key="subsubfieldIndex">
                            <ValidationProvider
                              :vid="`${subsubfield.name}-${subfieldIndex}`"
                              v-slot="{ valid, errors }"
                              :rules="revenueExpensesRules[fieldname][subfieldIndex][subsubfield.name]"
                              :name="subsubfield.name"
                            >
                              <component
                                :is="ARRAY_SCHEMA[subsubfieldIndex].parent"
                                :id="`${subsubfield.name}-${subfieldIndex}`"
                                :label="otherRevenueExpenseLabel(subsubfield.label)"
                                :label-for="subsubfield.name"
                                label-cols="1"
                                label-align="left"
                                label-size="xs"
                                label-class="mt-1"
                                style="font-size: 0.8rem"
                              >
                                <component
                                  :is="ARRAY_SCHEMA[subsubfieldIndex].child"
                                  :type="ARRAY_SCHEMA[subsubfieldIndex].type"
                                  :id="`${subsubfield.name}-${subfieldIndex}`"
                                  :placeholder="otherRevenueExpensePlaceholder(subsubfield.label)"
                                  :state="fieldValidationState(errors, valid)"
                                  v-model="formData[fieldname][subfieldIndex][subsubfield.name]"
                                  @input="setRules(fieldname, subfieldIndex)"
                                  icon
                                  :error="errors[0]"
                                ></component>
                                <b-form-invalid-feedback>
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </component>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </div>
                      <!-- Currency Fields -->
                      <template v-if="prefillData[currentIndex(fieldname)].type === 'currency'">
                        <NumberFormattedInput
                          :state="fieldValidationState(errors, valid)"
                          v-model="formData[fieldname]"
                          v-bind="bindAttrs(fieldname)"
                        ></NumberFormattedInput>
                      </template>
                      <!-- The rest of the fields -->
                      <component
                        :is="SCHEMA[prefillData[currentIndex(fieldname)].type]"
                        :state="fieldValidationState(errors, valid)"
                        v-bind="bindAttrs(fieldname)"
                        v-model="formData[fieldname]"
                      >
                        <span v-if="prefillData[currentIndex(fieldname)].type === 'checkbox'">Yes</span>
                      </component>
                    </b-form-group>
                  </ValidationProvider>
                  <template v-if="fieldname.includes('hasFirmParticipate')">
                    <div class="pb-3">
                      For each offering in which the firm participated in the Reporting Period, provide the following
                      information. If firm engaged in more than five (5) such offerings during the Reporting Period,
                      list the five (5) largest offerings in terms of total securities sold by dollar amount in
                      descending order.
                    </div>
                    <div class="steps-container">
                      <ul class="progressbar">
                        <li
                          v-for="miniStep in miniSteps.length"
                          :key="miniStep"
                          :class="{ active: step - 2 === miniStep }"
                        ></li>
                      </ul>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </template>
    <template v-else>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="mx-2">Loading...</strong>
      </div>
    </template>
    <template #modal-footer>
      <b-row>
        <b-col md="12" style="text-align: center">
          <b-button v-if="currentStep > 0" variant="secondary" style="margin-right: 10px" @click="prevStep()">
            Back
          </b-button>
          <!--<b-button
            v-if="currentStep === steps.length - 1"
            variant="primary"
            style="margin-right: 10px"
            @click="onPreview()"
          >
            Preview & Submit
          </b-button>-->
          <b-button variant="primary" @click.prevent="nextStep()">
            {{ currentStep === steps.length - 1 ? 'Submit' : 'Next' }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
const FORM_DATA = {
  threeLargestComponentsOfOtherRevenue: [
    {
      description: '',
      money: '',
    },
    {
      description: '',
      money: '',
    },
    {
      description: '',
      money: '',
    },
  ],
  threeLargestComponentsOfOtherExpenses: [
    {
      description: '',
      money: '',
    },
    {
      description: '',
      money: '',
    },
    {
      description: '',
      money: '',
    },
  ],
  hasFirmParticipate: false,
}

import _ from 'lodash'
import { mapActions } from 'vuex'
import FormTimeReportInput from './TimeReportInput.vue'

export default {
  name: 'SSOIModal',
  components: {
    FormTimeReportInput,
  },
  props: {
    header: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      steps: [
        ['endDate', 'isPortionOfItemM', 'periodFrom', 'to'],
        ['threeLargestComponentsOfOtherRevenue'],
        ['threeLargestComponentsOfOtherExpenses'],
        [
          'hasFirmParticipate1',
          'nameOfIssuerOrSponsor1',
          'filerId1',
          'industryGroup1',
          'minimumInvestmentAccepted1',
          'totalOfferingAmount1',
          'isIndefinite1',
          'totalAmountOfSecuritiesSold1',
          'totalCustomersSecuritiesSold1',
          'amountOfRevenueEarned1',
          'federalExemptionsExclusionsClaimed1',
          'typesOfSecuritiesOffered1',
        ],
        [
          'hasFirmParticipate2',
          'nameOfIssuerOrSponsor2',
          'filerId2',
          'industryGroup2',
          'minimumInvestmentAccepted2',
          'totalOfferingAmount2',
          'isIndefinite2',
          'totalAmountOfSecuritiesSold2',
          'totalCustomersSecuritiesSold2',
          'amountOfRevenueEarned2',
          'federalExemptionsExclusionsClaimed2',
          'typesOfSecuritiesOffered2',
        ],
        [
          'hasFirmParticipate3',
          'nameOfIssuerOrSponsor3',
          'filerId3',
          'industryGroup3',
          'minimumInvestmentAccepted3',
          'totalOfferingAmount3',
          'isIndefinite3',
          'totalAmountOfSecuritiesSold3',
          'totalCustomersSecuritiesSold3',
          'amountOfRevenueEarned3',
          'federalExemptionsExclusionsClaimed3',
          'typesOfSecuritiesOffered3',
        ],
        [
          'hasFirmParticipate4',
          'nameOfIssuerOrSponsor4',
          'filerId4',
          'industryGroup4',
          'minimumInvestmentAccepted4',
          'totalOfferingAmount4',
          'isIndefinite4',
          'totalAmountOfSecuritiesSold4',
          'totalCustomersSecuritiesSold4',
          'amountOfRevenueEarned4',
          'federalExemptionsExclusionsClaimed4',
          'typesOfSecuritiesOffered4',
        ],
        [
          'hasFirmParticipate5',
          'nameOfIssuerOrSponsor5',
          'filerId5',
          'industryGroup5',
          'minimumInvestmentAccepted5',
          'totalOfferingAmount5',
          'isIndefinite5',
          'totalAmountOfSecuritiesSold5',
          'totalCustomersSecuritiesSold5',
          'amountOfRevenueEarned5',
          'federalExemptionsExclusionsClaimed5',
          'typesOfSecuritiesOffered5',
        ],
        [
          'hasFirmParticipate6',
          'nameOfIssuerOrSponsor6',
          'filerId6',
          'industryGroup6',
          'minimumInvestmentAccepted6',
          'totalOfferingAmount6',
          'isIndefinite6',
          'totalAmountOfSecuritiesSold6',
          'totalCustomersSecuritiesSold6',
          'amountOfRevenueEarned6',
          'federalExemptionsExclusionsClaimed6',
          'typesOfSecuritiesOffered6',
          'issuerSponsorAffiliation6',
        ],
        [
          'hasFirmParticipate7',
          'nameOfIssuerOrSponsor7',
          'filerId7',
          'industryGroup7',
          'minimumInvestmentAccepted7',
          'totalOfferingAmount7',
          'isIndefinite7',
          'totalAmountOfSecuritiesSold7',
          'totalCustomersSecuritiesSold7',
          'amountOfRevenueEarned7',
          'federalExemptionsExclusionsClaimed7',
          'typesOfSecuritiesOffered7',
          'issuerSponsorAffiliation7',
        ],
        [
          'hasFirmParticipate8',
          'nameOfIssuerOrSponsor8',
          'filerId8',
          'industryGroup8',
          'minimumInvestmentAccepted8',
          'totalOfferingAmount8',
          'isIndefinite8',
          'totalAmountOfSecuritiesSold8',
          'totalCustomersSecuritiesSold8',
          'amountOfRevenueEarned8',
          'federalExemptionsExclusionsClaimed8',
          'typesOfSecuritiesOffered8',
          'issuerSponsorAffiliation8',
        ],
        [
          'hasFirmParticipate9',
          'nameOfIssuerOrSponsor9',
          'filerId9',
          'industryGroup9',
          'minimumInvestmentAccepted9',
          'totalOfferingAmount9',
          'isIndefinite9',
          'totalAmountOfSecuritiesSold9',
          'totalCustomersSecuritiesSold9',
          'amountOfRevenueEarned9',
          'federalExemptionsExclusionsClaimed9',
          'typesOfSecuritiesOffered9',
          'issuerSponsorAffiliation9',
        ],
        [
          'hasFirmParticipate10',
          'nameOfIssuerOrSponsor10',
          'filerId10',
          'industryGroup10',
          'minimumInvestmentAccepted10',
          'totalOfferingAmount10',
          'isIndefinite10',
          'totalAmountOfSecuritiesSold10',
          'totalCustomersSecuritiesSold10',
          'amountOfRevenueEarned10',
          'federalExemptionsExclusionsClaimed10',
          'typesOfSecuritiesOffered10',
          'issuerSponsorAffiliation10',
        ],
      ],
      titles: [
        'New Report',
        'Other Revenue',
        'Other Expenses',
        'Unregistered offerings other than self or affiliate offerings',
        'Unregistered offerings other than self or affiliate offerings',
        'Unregistered offerings other than self or affiliate offerings',
        'Unregistered offerings other than self or affiliate offerings',
        'Unregistered offerings other than self or affiliate offerings',
        'Unregistered offerings for self or affiliate offerings',
        'Unregistered offerings for self or affiliate offerings',
        'Unregistered offerings for self or affiliate offerings',
        'Unregistered offerings for self or affiliate offerings',
        'Unregistered offerings for self or affiliate offerings',
      ],
      prefillData: [],
      SCHEMA: {
        input: 'b-form-input',
        checkbox: 'b-form-checkbox',
        date: 'b-form-datepicker',
        radio: 'b-form-radio-group',
        select: 'v-select',
        'select-multiple': 'b-form-checkbox-group',
        time_report: 'FormTimeReportInput',
      },
      ARRAY_SCHEMA: [
        {
          parent: 'b-form-group',
          child: 'b-form-input',
          type: 'text',
        },
        {
          parent: 'b-input-group',
          child: 'NumberFormattedInput',
          type: 'text',
        },
      ],
      isLoading: true,
      currentStep: 0,
      formData: {
        hasFirmParticipate1: false,
        hasFirmParticipate2: false,
        hasFirmParticipate3: false,
        hasFirmParticipate4: false,
        hasFirmParticipate5: false,
        hasFirmParticipate6: false,
        hasFirmParticipate7: false,
        hasFirmParticipate8: false,
        hasFirmParticipate9: false,
        hasFirmParticipate10: false,
      },
      revenueExpensesRules: {
        threeLargestComponentsOfOtherExpenses: [
          {
            description: '',
            money: 'noNaN',
          },
          {
            description: '',
            money: 'noNaN',
          },
          {
            description: '',
            money: 'noNaN',
          },
        ],
        threeLargestComponentsOfOtherRevenue: [
          {
            description: '',
            money: 'noNaN',
          },
          {
            description: '',
            money: 'noNaN',
          },
          {
            description: '',
            money: 'noNaN',
          },
        ],
      },
    }
  },
  computed: {
    modalSizeByStep() {
      switch (this.currentStep) {
        case 0:
          return 'md'
        case 1:
          return 'lg'
        case 2:
          return 'lg'
        case 13:
          return 'lg'
        case 14:
          return 'md'
        default:
          return 'xl'
      }
    },
    miniSteps() {
      const miniStepsArr = this.steps.filter((step) => {
        return /hasFirmParticipate(?:[1-9]|10)?/.test(step)
      })
      return miniStepsArr
    },
    modalTitle() {
      return this.titles[this.currentStep]
    },
    filteredForm() {
      const filterEmpty = (obj) => {
        if (Array.isArray(obj)) {
          const filteredArray = _.filter(obj, (el) => {
            return !_.isEmpty(el.description) || !_.isEmpty(el.money)
          })
          // const filteredArray = obj.filter((el) => {
          //   if (typeof el === 'object') {
          //     return Object.values(el).some((i) => i)
          //   }
          //   return true
          // })
          console.log(filteredArray)
          return filteredArray.length > 0 ? filteredArray : undefined
        }
        if (obj !== null && typeof obj === 'object') {
          return Object.fromEntries(
            Object.entries(obj)
              .map(([k, v]) => [k, filterEmpty(v)])
              .filter(([, v]) => {
                if (typeof v === 'boolean') {
                  return true
                }
                return v
              }),
          )
        }
        return obj
      }
      const convertNum = (obj) => {
        if (obj !== null && typeof obj === 'object') {
          return Object.fromEntries(
            Object.entries(obj).map(([k, v]) => {
              if (k.includes('filerId') && typeof v === 'string' && v !== '') {
                return [k, Number(v)]
              }
              return [k, v]
            }),
          )
        }
      }
      const filtered = filterEmpty(this.formData)
      const converted = convertNum(filtered)
      return converted
    },
    prefillValues() {
      return this.$store.getters['company/ssoiPrefill']
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
      clearInput: 'report/ssoi/clearInput',
    }),
    getPrefillData() {
      this.isLoading = true
      this.$api
        .get(`/report/ssoi/prefill-form-data?company_id=${this.company.id}`)
        .then((res) => {
          this.prefillData = res.data.data?.prefill

          this.formData.threeLargestComponentsOfOtherRevenue = [
            {
              description: '',
              money: 0,
            },
            {
              description: '',
              money: 0,
            },
            {
              description: '',
              money: 0,
            },
          ]
          this.formData.threeLargestComponentsOfOtherExpenses = [
            {
              description: '',
              money: 0,
            },
            {
              description: '',
              money: 0,
            },
            {
              description: '',
              money: 0,
            },
          ]

          if (this.prefillValues) this.formData = _.cloneDeep(_.omit(this.prefillValues, 'startDate', 'endDate'))

          const quarterMapping = {
            '03/31': 'Q1',
            '06/30': 'Q2',
            '09/30': 'Q3',
            '12/31': 'Q4',
          }
          const monthMapping = {
            '01': 'JAN',
            '02': 'FEB',
            '03': 'MAR',
            '04': 'APR',
            '05': 'MAY',
            '06': 'JUN',
            '07': 'JUL',
            '08': 'AUG',
            '09': 'SEP',
            10: 'OCT',
            11: 'NOV',
            12: 'DEC',
          }
          const period = this.prefillValues?.endDate?.split('/').slice(0, 2).join('/')
          const periodYear = this.prefillValues?.endDate?.split('/').slice(-1)[0].slice(-2)
          const currentYear = parseInt(new Date().getFullYear().toString().substr(-2))
          const endDate =
            this.company.focus_reporting_frequency === 'Monthly'
              ? `${monthMapping[period?.split('/')[0] || '01']} ${periodYear || currentYear}`
              : `${quarterMapping[period || '03/31']} ${periodYear || currentYear}`

          this.formData.endDate = endDate
        })
        .catch((e) => {
          console.log(e)
          this.$showError('Something went wrong')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    resetModal() {
      this.currentStep = 0
      this.formData = _.cloneDeep(FORM_DATA)
    },
    nextStep() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          if (this.currentStep === this.steps.length - 1) {
            this.onSubmit()
          } else {
            // this.revenueExpensesRules = ''
            const currentMiniStep = this.currentStep - 2
            if (this.formData[`hasFirmParticipate${currentMiniStep}`] === false) {
              currentMiniStep > 5 ? (this.currentStep = this.steps.length - 2) : (this.currentStep = 7)
            }
            this.currentStep++
          }
        } else {
          this.$showError('Please fill all the required fields')
        }
      })
    },
    prevStep() {
      this.currentStep--
    },
    getColSize(fieldname) {
      const namesNotInSteps = [
        'startDate',
        'endDate',
        'isPortionOfItemM',
        'periodFrom',
        'to',
        'threeLargestComponentsOfOtherRevenue',
        'threeLargestComponentsOfOtherExpenses',
        'hasFirmParticipate',
      ]
      if (namesNotInSteps.includes(fieldname)) {
        return '12'
      }
      if (/hasFirmParticipate(?:[1-9]|10)?/.test(fieldname)) {
        return '12'
      }
      if (fieldname.includes('totalOfferingAmount')) {
        return '4'
      }
      if (fieldname.includes('isIndefinite')) {
        return '2'
      }
      if (fieldname.includes('previewOrGenerate')) {
        return '2'
      }
      return '6'
    },
    currentIndex(fieldname) {
      return this.prefillData.findIndex((prefill) => prefill.name === fieldname)
    },
    isOtherRevenueExpense(fieldname) {
      return (
        fieldname === 'threeLargestComponentsOfOtherRevenue' || fieldname === 'threeLargestComponentsOfOtherExpenses'
      )
    },
    otherRevenueExpenseLabel(label) {
      return label.substring(0, 3)
    },
    otherRevenueExpensePlaceholder(label) {
      const prefix = label.substring(0, 3)
      return label.split(prefix)[1]
    },
    bindAttrs(fieldname) {
      const index = this.currentIndex(fieldname)
      const prefill = this.prefillData[index]
      const schema = this.SCHEMA[prefill.type]
      const attrs = {}
      if (schema === 'b-form-checkbox') {
        attrs['checked'] = this.formData[prefill.name]
      } else if (schema === 'b-form-checkbox-group') {
        attrs['options'] = prefill.values
        attrs['stacked'] = true
      } else if (schema === 'b-form-radio-group') {
        attrs['options'] = [
          {
            text: 'Yes',
            value: true,
          },
          {
            text: 'No',
            value: false,
          },
        ]
        attrs['checked'] = this.formData[prefill.name]
        attrs['v-on:change'] = this.onHasFirmParticipateChange(prefill.name)
      } else if (schema === 'v-select') {
        attrs['label'] = prefill.label
        attrs['options'] = prefill.values
      } else if (schema === 'b-input-group' && prefill.type === 'currency') {
        attrs['prepend'] = '$'
      } else if (schema === 'b-form-datepicker' && /startDate|endDate|to|periodFrom/.test(fieldname)) {
        prefill.name === 'startDate' && (attrs['max'] = this.formData.endDate)
        prefill.name === 'endDate' && (attrs['min'] = this.formData.startDate)
        prefill.name === 'periodFrom' && (attrs['max'] = this.formData.to)
        prefill.name === 'to' && (attrs['min'] = this.formData.periodFrom)
        attrs['date-format-options'] = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }
      }
      if (this.prefillData[index].dependentFieldName) {
        attrs['disabled'] = this.formData[this.prefillData[index].dependentFieldName] === false
      }
      if (fieldname.includes('filerId')) {
        attrs['type'] = 'number'
      }
      return attrs
    },
    validationRules(fieldname) {
      const stepsToValidate = ['endDate', 'isPortionOfItemM', 'periodFrom', 'to', 'hasFirmParticipate']
      const index = this.currentIndex(fieldname)
      const prefill = this.prefillData[index]
      const schema = this.SCHEMA[prefill.type]
      const rules = {}
      if (schema === 'b-form-checkbox-group') {
        rules['min'] = 1
      }
      if (stepsToValidate.includes(fieldname)) {
        rules['required'] = true
      } else {
        this.formData[this.prefillData[index].dependentFieldName] === false
          ? (rules['required'] = false)
          : (rules['required'] = true)
      }
      if (
        this.formData[this.prefillData[index].dependentFieldName] === true &&
        fieldname.match(/totalOfferingAmount(?:[1-9]|10)?/)
      ) {
        const lastNum = fieldname.split('totalOfferingAmount')[1]
        this.formData[`isIndefinite${lastNum}`] === true ? (rules['required'] = false) : (rules['required'] = true)
      }
      if (fieldname.match(/isIndefinite(?:[1-9]|10)?/)) {
        rules['required'] = false
      }
      if (fieldname === 'isPortionOfItemM') {
        rules['required'] = false
      }
      if (this.isOtherRevenueExpense(fieldname)) {
        rules['required'] = false
      }
      return rules
    },
    fieldValidationState(errors, valid) {
      return errors[0] ? false : null
    },
    onPreview() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          // let form = {}
          // this.steps.forEach((step, index) => {
          //   if (this.currentStep >= index) {
          //     step.forEach((s) => {
          //       if (this.formData[s]) {
          //         form[s] = this.formData[s]
          //       }
          //     })
          //   }
          // })
          const data = {
            ...this.reportHeader,
            prefill: this.filteredForm,
          }
          this.$bvModal.hide('ssoi-modal')
          this.$emit('preview', data)
        } else {
          this.$bvToast.toast('Please fill all the required fields', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }
      })
    },
    onSubmit() {
      this.setLoading(true)
      this.setDescription('Generating Report...')

      // this.formData.threeLargestComponentsOfOtherExpenses = _.filter(
      //   this.formData.threeLargestComponentsOfOtherExpenses,
      //   (el) => {
      //     return !_.isEmpty(el.description) || !_.isEmpty(el.money)
      //   },
      // )

      // this.formData.threeLargestComponentsOfOtherRevenue = _.filter(
      //   this.formData.threeLargestComponentsOfOtherRevenue,
      //   (el) => {
      //     return !_.isEmpty(el.description) || !_.isEmpty(el.money)
      //   },
      // )

      const data = {
        ...this.header,
        prefill: this.formData,
      }
      this.$api
        .post(`/report/ssoi/generate?company_id=${this.company.id}`, {
          data,
        })
        .then((Res) => {
          this.$showSuccess('Report Generated Successfully.')
          this.clearInput()
          this.$bvModal.hide('ssoi-modal')
          this.$emit('submit')
        })
        .catch((err) => {
          console.log(err)
          const error = err?.response?.data?.error?.message
          this.$showError(error || 'Failed to generate report')
        })
        .finally(() => {
          this.setLoading(false)
          this.setDescription('')
        })
    },
    close() {
      this.$bvModal.hide('ssoi-modal')
      this.clearInput()
    },
    setRules(fieldname, subfieldIndex) {
      const formData = this.formData[fieldname][subfieldIndex]
      const rules = this.revenueExpensesRules[fieldname][subfieldIndex]

      rules.description = formData.money !== '' && formData.money !== 0 && !isNaN(formData.money) ? 'required' : ''
      rules.money = formData.description !== '' ? 'required|noNaN' : 'required'
    },
    onHasFirmParticipateChange(value) {
      const step = this.steps.find((step) => value === step[0])
      if (this.formData[value] === false) {
        step.forEach((field) => {
          if (field !== value && this.formData[field]) {
            this.formData[field] = undefined
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.modal-title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #212529;
}

.steps-container {
  width: 100%;
}

.progressbar {
  counter-reset: step;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.progressbar li {
  list-style: none;
  display: inline-block;
  /* width: 30.33%; */
  position: relative;
  text-align: center;
  cursor: pointer;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: white;
}

.progressbar li.active:before {
  background-color: #1e3d73;
}

.progressbar li.active + li:after {
  background-color: #1e3d73;
}

.ssoi-modal-title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #212529;
}
</style>
