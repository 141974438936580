<template>
  <div>
    <ReportHeader type="ssoi" @submit="setHeaderAndOpenFormModal"></ReportHeader>
    <SSOIFormModal :header="header" @preview="onPreview" @submit="getRecords" />
    <SSOIPreviewModal @success="getRecords" />
    <div class="d-flex justify-content-end pb-3 pt-0">
      <b-button v-if="deleteItems.length > 0" variant="danger" class="mr-2" size="sm" @click="onDelete">
        Delete
      </b-button>
      <b-button variant="primary" size="sm" @click="onSSOINewReport"> New SSOI Report </b-button>
    </div>
    <TheTable
      id="ssoi-table"
      :records="records"
      :fields="fields"
      :loading="fetchStatus === 'loading'"
      selectable
      @row-selected="onRowSelected"
      no-local-sorting
    >
      <template #head(checkbox)="data">
        <b-form-checkbox @change="(e) => toggleSelectAll(e, data)"></b-form-checkbox>
      </template>
      <template #cell(checkbox)="{ item }">
        <b-form-checkbox v-model="deleteItems" :value="item"></b-form-checkbox>
      </template>
      <template #cell(action)="{ item }">
        <TableButtonsWrapper>
          <TableButton
            icon="las la-eye"
            variant="primary"
            title="Preview"
            @click="handleDownloadReports(item, 'pdf', true)"
          />
          <TableButton
            icon="las la-file-pdf"
            variant="primary"
            title="PDF"
            @click="handleDownloadReports(item, 'pdf', false)"
          />
          <TableButton
            icon="las la-file-csv"
            variant="primary"
            title="CSV"
            @click="handleDownloadReports(item, 'csv', false)"
          />
        </TableButtonsWrapper>
      </template>
    </TheTable>
    <TablePagination
      :currentPage="currentPage"
      :rows="rows"
      :perPage="perPage"
      @pagination="handlePagination"
      @entries="handleEntries"
    />
  </div>
</template>

<script>
import SSOIFormModal from '@/components/Reports/SSOIFormModal.vue'
import SSOIPreviewModal from '@/components/Reports/SSOI/PreviewModal.vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import ReportHeader from '../../components/Reports/ReportHeader.vue'

export default {
  components: {
    SSOIFormModal,
    SSOIPreviewModal,
    ReportHeader,
  },
  name: 'SSOI',
  data: () => ({
    fields: [
      {
        key: 'checkbox',
        label: '',
        size: '50px',
      },
      {
        key: 'time_report',
        label: 'Time Report',
        size: '100px',
      },
      {
        key: 'name',
        label: 'Report Name',
        size: '120px',
      },
      {
        key: 'description',
        label: 'Description',
        size: '200px',
      },
      {
        key: 'user',
        label: 'User Email',
        size: '200px',
        formatter: (value, key, item) => {
          return item.user_company_setting?.user_email?.email_address
        },
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        size: '120px',
        formatter: (value) => {
          return value ? moment(value).format('MM/DD/YYYY') : ''
        },
      },
      {
        key: 'updatedAt',
        label: 'Updated Date',
        size: '120px',
        formatter: (value) => {
          return value ? moment(value).format('MM/DD/YYYY') : ''
        },
      },
      {
        key: 'action',
        label: '',
        size: '130px',
      },
    ],
    records: [],
    deleteItems: [],
    currentPage: 1,
    rows: 0,
    perPage: 10,
    fetchStatus: 'idle',
    sort: {
      sortBy: 'description',
      sortDesc: false,
    },
    header: {},
  }),
  created() {
    this.getRecords()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setPdf: 'report/setPdf',
      setPreviewPrefillParams: 'report/ssoi/setPreviewPrefillParams',
    }),
    async getRecords() {
      this.fetchStatus = 'loading'
      try {
        const params = {
          params: {
            pagination: {
              page: this.currentPage,
              pageSize: this.perPage,
            },
            sort: 'createdAt:desc',
            filters: {
              type: 'SSOI',
            },
            populate: ['user_company_setting', 'user_company_setting.user_email'],
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/reports', params)
        if (response.data?.data && response.data?.pagination) {
          this.fetchStatus = 'success'
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.records = response.data?.data
          // set prefill values
          if (this.records.length === 0) return
          const { prefill_focus, prefill_ssoi } = this.records[0].user_company_setting
          this.$store.dispatch('company/setUserCompanyPrefill', {
            FOCUS: prefill_focus,
            SSOI: prefill_ssoi,
          })
        }
      } catch (error) {
        this.fetchStatus = 'error'
        if (error.status !== 401) {
          this.$showError('Unable to fetch records')
        }
      }
    },
    toggleSelectAll(value, data) {
      value ? data.selectAllRows() : data.clearSelected()
    },
    onRowSelected(items) {
      this.deleteItems = items
    },
    onPreview(data) {
      this.setPreviewPrefillParams(data)
      this.$bvModal.show('PreviewModal')
    },
    handleDownloadReports(record, type = 'pdf', preview) {
      this.setLoading(true)
      this.$api
        .get(`/report/download?company_id=${this.company.id}`, {
          params: {
            reportId: record.id,
            type,
            preview,
          },
        })
        .then((res) => {
          if (preview) {
            this.$bvModal.show('PdfModal')
            this.setPdf({
              src: res.data.data.url,
              title: `Preview - ${record.name} - ${moment().format('MMM DD, YYYY')}`,
            })
          } else {
            window.open(res.data.data.url)
          }
        })
        .catch((err) => {
          this.$showError(err.response.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    handlePagination(value) {
      this.currentPage = value
      this.getRecords()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getRecords()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getRecords()
    },
    onDelete() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete the selected records?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.setLoading(true)
            const ids = this.deleteItems.map((item) => item.id).join(',')
            this.$api
              .delete(`/reports?id=${ids}&company_id=${this.company.id}`)
              .then(() => {
                this.deleteItems = []
                this.getRecords()
                this.$showSuccess('Records deleted successfully')
              })
              .catch((err) => {
                this.$showError(err.response.data?.error?.message || 'Unable to delete records')
              })
              .finally(() => {
                this.setLoading(false)
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onSSOINewReport() {
      this.$bvModal.show('report-header')
    },
    setHeaderAndOpenFormModal(data) {
      this.header = data
      this.$bvModal.show('ssoi-modal')
    },
  },
}
</script>
