import { numberFormatter } from '@/utils/helpers'

export const fields = [
  {
    key: 'checkbox',
    label: '',
    listTable: false,
    editTable: true,
    sortable: true,
    size: '50px',
  },
  {
    key: 'id',
    label: '',
    listTable: false,
    editTable: false,
    sortable: true,
  },
  {
    key: 'description',
    label: 'Description',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '120px',
  },
  {
    key: 'coa',
    label: 'Account Number',
    listTable: true,
    editTable: true,
    sortable: true,
    formatter: (value) => {
      return value?.account_number
    },
    size: '100px',
    tdClass: 'text-center',
  },
  {
    key: 'focus_setting',
    label: 'FOCUS ID',
    listTable: true,
    editTable: true,
    sortable: true,
    formatter: (value, index, item) => {
      return item?.coa?.focus_setting?.focus_id ?? ''
    },
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'ssoi_setting',
    label: 'SSOI ID',
    listTable: true,
    editTable: true,
    sortable: true,
    formatter: (value, index, item) => {
      return item?.coa?.ssoi_setting?.ssoi_id ?? ''
    },
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'debit',
    label: 'Debit',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '80px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'credit',
    label: 'Credit',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '80px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'time_report',
    label: 'Time Report',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'action',
    label: '',
    listTable: false,
    editTable: false,
    size: '60px',
  },
]
