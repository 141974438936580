<template>
  <div class="d-flex flex-wrap align-items-center list-user-action" style="gap: 3px">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TableButtonsWrapper',
}
</script>
