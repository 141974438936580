export default {
  namespaced: true,
  state: {
    toggleSidebar: false,
  },
  getters: {},
  actions: {
    toggleSidebar({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
  },
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.toggleSidebar = !state.toggleSidebar
    },
  },
}
