<template>
  <b-modal id="userDetails-form" ref="modal" title="User Invitation" @hidden="resetModal" hide-footer>
    <ValidationObserver ref="sendInviteForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(inviteUser)">
        <ValidationProvider vid="user_title" name="User Title" rules="required" v-slot="{ errors }">
          <b-form-group
            :invalid-feedback="errors[0]"
            label="User Title"
            label-for="user_title"
            label-class="required-asterisk"
          >
            <b-form-input
              name="user_title"
              id="user_title"
              v-model="form.user_title"
              :state="errors[0] ? false : null"
              :disabled="!isAdmin || status === 'loading'"
            >
            </b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider vid="first_name" name="First Name" rules="required" v-slot="{ errors }">
          <b-form-group
            :invalid-feedback="errors[0]"
            label="First Name"
            label-for="first_name"
            label-class="required-asterisk"
          >
            <b-form-input
              name="first_name"
              id="first_name"
              v-model="form.first_name"
              :state="errors[0] ? false : null"
              :disabled="!isAdmin || status === 'loading'"
            >
            </b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider vid="middle_name" name="Middle name">
          <b-form-group label="Middle Name" label-for="middle_name">
            <b-form-input
              name="middle_name"
              id="middle_name"
              v-model="form.middle_name"
              :disabled="!isAdmin || status === 'loading'"
            >
            </b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider vid="last_name" name="Last name" rules="required" v-slot="{ errors }">
          <b-form-group
            :invalid-feedback="errors[0]"
            label="Last Name"
            label-for="last_name"
            label-class="required-asterisk"
          >
            <b-form-input
              name="last_name"
              id="last_name"
              v-model="form.last_name"
              :state="errors[0] ? false : null"
              :disabled="!isAdmin || status === 'loading'"
            >
            </b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors }">
          <b-form-group :invalid-feedback="errors[0]" label="Email" label-for="email" label-class="required-asterisk">
            <b-form-input
              name="email"
              id="email"
              v-model="form.email"
              :state="errors[0] ? false : null"
              :disabled="!isAdmin || status === 'loading'"
            >
            </b-form-input>
          </b-form-group>
        </ValidationProvider>

        <b-form-group label="Role" class="col-sm-12 profile-field px-0">
          <b-form-select
            v-model="form.role"
            :options="[
              { value: 'ADMIN', text: 'Admin' },
              { value: 'MIDDLE', text: 'Middle' },
              { value: 'VIEWER', text: 'Viewer' },
            ]"
            :disabled="!isAdmin || status === 'loading'"
          >
          </b-form-select>
        </b-form-group>

        <b-row class="mt-4">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button type="button" variant="secondary" @click="$bvModal.hide('userDetails-form')"> Cancel </b-button>
            <b-button type="submit" class="ml-2" variant="primary">Send Invite</b-button>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  name: 'CompanyUserInviteModal',
  data() {
    return {
      form: {
        user_title: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        role: 'ADMIN',
      },
      status: 'idle',
    }
  },
  methods: {
    async inviteUser() {
      this.status = 'loading'
      try {
        const response = await this.$api.post(`companies/${this.company.id}/invite`, {
          data: {
            user: {
              email: this.form.email,
              first_name: this.form.first_name,
              last_name: this.form.last_name,
              user_title: this.form.user_title,
            },
            role: this.form.role,
          },
        })
        if (response.status === 200) {
          this.status = 'success'
          this.$showSuccess('User invitation has been sent')
          this.$emit('invite-success')
          this.$bvModal.hide('userDetails-form')
        }
      } catch (error) {
        this.status = 'error'
        this.$showError(error?.response?.data?.error?.message)
      }
    },
    resetModal() {
      this.form = {
        user_title: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        role: 'admin',
      }
    },
  },
}
</script>
