<template>
  <b-container fluid>
    <iq-card body-class="p-0">
      <template v-slot:headerTitle>
        <div class="d-flex align-items-center">
          <i class="las la-file-csv" style="font-size: 25px"></i>
          <h4 class="card-title">
            <span class="text-primary font-size-16 ml-3">Inventory Detail with Haircuts</span>
          </h4>
        </div>
      </template>
      <template v-slot:body>
        <b-tabs class="w-100" pills card @input="onTabChanged">
          <b-tab v-for="tab in tabs" :key="tab.title" :title="tab.name" :active="tab.active" lazy>
            <router-view></router-view>
          </b-tab>
        </b-tabs>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
export default {
  name: 'InventoryListView',
  data: () => ({
    tabIndex: 0,
    tabs: [
      {
        name: 'NON-OPTIONS INVENTORY',
        path: '/inventory/list/non-options',
        active: false,
      },
      {
        name: 'OPTIONS INVENTORY',
        path: '/inventory/list/options',
        active: false,
      },
      {
        name: 'MERGED INVENTORY',
        path: '/inventory/list/merged',
        active: false,
      },
    ],
  }),
  created() {
    this.updateActiveTab()
  },
  methods: {
    onTabChanged(e) {
      const currentPath = this.$route.path
      if (currentPath.includes(this.tabs[e].path)) return
      this.$router.push(this.tabs[e].path)
    },
    updateActiveTab() {
      this.tabs.forEach((tab) => {
        tab.active = false
      })
      const tabIndex = this.tabs.findIndex((tab) => tab.path === this.$route.path)
      if (tabIndex !== -1) {
        this.tabs[tabIndex].active = true
      }
    },
  },
}
</script>
