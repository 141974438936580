export default {
  getAuthUserId: (state) => {
    return state.auth_user_id
  },
  loggedIn: (state) => {
    return state.accessToken !== null
  },
  accessToken: (state) => {
    return state.accessToken
  },
  getUser: (state) => {
    return state.user
  },
  getRole: (state) => {
    return state.selectedCompanySetting?.role
  },
  getAvatar: (state) => {
    return state.user?.avatar?.formats?.thumbnail?.url || state.user?.avatar?.url || ''
  },
  getSession: (state) => {
    return state.session
  },
  getUserCompanies: (state) => {
    return (
      state.user?.emails?.find((email) => email.email_address === state.user?.email?.email_address)
        ?.user_company_settings || []
    )
  },
  getSelectedCompany: (state) => {
    return state.selectedCompany
  },
  getSelectedCompanySetting: (state) => {
    return state.selectedCompanySetting
  },
  getIsAdmin: (state) => {
    return state.selectedCompanySetting?.role === 'ADMIN'
  },
}
