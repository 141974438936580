<template>
  <div class="d-flex flex-column" style="gap: 15px">
    <TableActionsWrapper>
      <template #options>
        <label class="mr-2 mt-1">Report Date</label>
        <b-form-group class="mb-0">
          <b-form-select v-model="reportDate" :options="reportDates" class="mb-0" @change="getList">
            <b-form-select-option v-if="reportDates.length === 0" value=""> No Report Date </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </template>

      <template #pagination>
        <CommonPerPage :perPage="perPage" @change="handleEntries" />
      </template>

      <template #actions>
        <b-button variant="primary" @click="downloadReport" :disabled="records.length === 0">
          Download Report
        </b-button>
      </template>
    </TableActionsWrapper>

    <TheTable
      :records="records"
      :fields="listTableFields"
      :loading="isLoading"
      @sort-changed="handleSortChange"
      no-local-sorting
    >
      <template #cell(status)="{ item }">
        <BadgeStatus :status="item.status">
          {{ item.status }}
        </BadgeStatus>
      </template>
    </TheTable>
    <TablePagination
      :currentPage="currentPage"
      :rows="rows"
      :perPage="perPage"
      @pagination="handlePagination"
      @entries="handleEntries"
    />
  </div>
</template>

<script>
import { numberFormatter, manageReportFiles, dateFormatter } from '@/utils/helpers'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'MergedInventoryView',
  data() {
    return {
      reportDate: '',
      reportDates: [],
      records: [],
      currentPage: 1,
      rows: 0,
      perPage: 50,
      fetchStatus: 'idle',
      sort: {
        sortBy: '',
        sortDesc: false,
      },
      fields: [
        {
          key: 'id',
          label: '',
          listTable: false,
          editTable: false,
          size: '40px',
        },
        {
          key: 'identifier',
          label: 'Identifier',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '150px',
        },
        {
          key: 'cusip_isin',
          label: 'CUSIP/ ISIN',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
          thClass: 'text-wrap',
        },
        {
          key: 'type',
          label: 'Type',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
        },
        {
          key: 'subtype',
          label: 'SubType',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
        },
        {
          key: 'currency',
          label: 'Currency',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
        },
        {
          key: 'qty',
          label: 'Qty',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '150px',
          formatter: (value) => {
            return numberFormatter(value, 4)
          },
          tdClass: 'text-right',
        },
        {
          key: 'purch_price',
          label: 'Purchase Price',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
          formatter: (value) => {
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'long_or_short',
          label: 'Long/ Short',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
        },
        // {
        //   key: 'purch_price',
        //   label: 'Closing Price',
        //   listTable: true,
        //   editTable: true,
        //   sortable: true,
        //   size: '100px',
        // },
        {
          key: 'maturity',
          label: 'Maturity Date',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
          formatter: (value) => {
            return value ? moment(value).format('MM/DD/YYYY') : ''
          },
        },
        {
          key: 'call_or_put',
          label: 'Call/ Put',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
        },
        {
          key: 'covered',
          label: 'Covered',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '100px',
        },
        {
          key: 'underlying_mkt_price',
          label: 'Underlying Market Price',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '140px',
          formatter: (value) => {
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'underlying_mkt_value',
          label: 'Underlying Market Value',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '140px',
          formatter: (value) => {
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'underlying_haircut',
          label: 'Underlying Haircut',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '140px',
          formatter: (value) => {
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'total_position_value',
          label: 'Total Position Value',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '140px',
          formatter: (value) => {
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'haircut',
          label: 'Haircut',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '140px',
          formatter: (value) => {
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'undue_concentration_basis',
          label: 'Undue Concentration Basis',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '180px',
          formatter: (value) => {
            if (value === 'Not applicable') {
              return value
            }
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
        {
          key: 'undue_concentration_charge',
          label: 'Undue Concentration Charge',
          listTable: true,
          editTable: true,
          sortable: true,
          size: '190px',
          formatter: (value) => {
            if (value === 'Not applicable') {
              return value
            }
            return numberFormatter(value, 2)
          },
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    listTableFields() {
      return this.fields.filter((field) => field.listTable)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
  },
  async created() {
    await this.getAvailableReportDates()
    if (this.reportDate) this.getList()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
    }),
    async getList() {
      try {
        this.fetchStatus = 'pending'
        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && { sort: [`${this.sort.sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}`] }),
            reportDate: this.reportDate,
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/inventories', params)
        this.records = response.data.data
        this.fetchStatus = 'success'
        // if (response.status === 200 && response.data) {
        //   this.fetchStatus = 'success'
        //   const { page, total } = response.data.pagination
        //   this.currentPage = page
        //   this.rows = total
        //   this.records = response.data.data
        // }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    async getAvailableReportDates() {
      this.fetchStatus = 'pending'
      try {
        const response = await this.$api.get(
          `/non-options-inventories/available-report-date?company_id=${this.company.id}`,
        )
        if (response.status === 200 && response.data?.data) {
          this.fetchStatus = 'success'
          this.reportDates = response.data.data.map((report) => {
            return {
              value: report.report_date,
              text: dateFormatter(report.report_date),
            }
          })
          this.reportDate = this.reportDates[0]?.value || ''
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
    async downloadReport() {
      this.setLoading(true)
      try {
        const response = await this.$api.get('/inventories', {
          params: {
            reportDate: this.reportDate || null,
            company_id: this.company.id,
            download: true,
            type: 'pdf',
          },
        })
        if (response.status === 200 && response.data?.data) {
          const reportName = this.reportDate ? dateFormatter(this.reportDate) : 'All'
          const createdPDF = manageReportFiles(response.data)
          createdPDF.download(`Merged_Inventory_${reportName}.pdf`)
        }
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>
