<template>
  <b-tr>
    <b-td v-for="(value, key, idx) of record" :key="idx" :class="tableColumnsAlign(key)">
      {{ value }}
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: 'ReportTableDataRow',
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  methods: {
    tableColumnsAlign(key) {
      const columns = ['account_number', 'allowable', 'focus_code', 'ssoi_code']
      return key === 'account_name' ? 'text-left' : columns.includes(key) ? 'text-center' : 'text-right'
    },
  },
}
</script>
