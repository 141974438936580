<template>
  <div>
    <FormModal v-bind="formModalData" @tb-added="onAdded" @tb-updated="onEdited" />
    <ImportModal @uploaded="getList" />
    <CardWrapper>
      <template #icon>
        <i class="las la-wallet" style="font-size: 25px"></i>
      </template>
      <template #title>Trial Balance Edit</template>
      <template #header>
        <TableActionsWrapper>
          <template #options>
            <!-- Time Report -->
            <label class="mr-2 mt-1">Time Report</label>
            <b-form-group class="mb-0">
              <b-form-select
                v-model="timeReport"
                :options="timeReports"
                class="mb-0"
                @change="getList"
                data-cy="tb-time-report-select"
              >
                <template #first>
                  <b-form-select-option value="">All Time Reports</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </template>

          <template #pagination>
            <CommonPerPage :perPage="perPage" @change="handleEntries" />
          </template>

          <template #actions>
            <!-- Delete Mode Button -->
            <div>
              <!-- <b-button variant="danger" @click="isDeleteMode = !isDeleteMode" style="flex: none">
                  <span v-if="isDeleteMode">Exit</span> Delete Mode
                </b-button>
                <div v-if="isDeleteMode && deleteItems.length">Selected {{ deleteItems.length }} items</div> -->
              <template v-if="!isDeleteMode">
                <b-button variant="primary" class="ml-2" @click="downloadSample"> Download TB Template </b-button>
                <b-button variant="primary" class="ml-2" v-b-modal.trial-balance-import-modal data-cy="tb-upload-btn">
                  Import TB CSV File
                </b-button>
                <b-button variant="primary" @click="onAdd" class="ml-2"> Add Trial Balance Item </b-button>
                <b-button class="ml-2" variant="primary" @click="onSave" :disabled="modifiedItems.length === 0">
                  Save
                </b-button>
                <b-button variant="primary" class="ml-2" @click="onMarkDone"> Mark Done </b-button>
              </template>
              <template v-else>
                <b-button variant="primary" class="ml-2" @click="saveDeleteItems" :disabled="deleteItems.length === 0">
                  Confirm Delete
                </b-button>
              </template>
            </div>
          </template>
        </TableActionsWrapper>
      </template>
      <template #body>
        <TheTable
          ref="table"
          :records="records"
          :fields="editTableFields"
          :loading="isLoading"
          :sort-by="sort.sortBy"
          :sort-desc="sort.sortDesc"
          @sort-changed="handleSortChange"
          selectable
          @row-selected="onRowSelected"
          no-local-sorting
          data-cy="tb-table"
        >
          <template #head(checkbox)="data">
            <b-form-checkbox @change="(e) => toggleSelectAll(e, data)"></b-form-checkbox>
          </template>
          <template #cell(checkbox)="{ item }">
            <b-form-checkbox v-model="deleteItems" :value="item"></b-form-checkbox>
          </template>
          <template #cell(credit)="{ value }">
            <div v-if="value" class="d-flex justify-content-between">
              <span>$</span>
              <span>{{ value }}</span>
            </div>
          </template>
          <template #cell(debit)="{ value }">
            <div v-if="value" class="d-flex justify-content-between">
              <span>$</span>
              <span>{{ value }}</span>
            </div>
          </template>
          <template #cell(action)="{ item }">
            <div class="d-flex justify-content-center flex-wrap" style="gap: 5px">
              <b-button v-if="!item.is_deleted" size="sm" variant="primary" @click="onEdit(item)"> Edit </b-button>
              <b-button v-if="canRevert(item)" class="ml-2" size="sm" variant="warning" @click="revertItem(item)">
                Revert
              </b-button>
              <b-button size="sm" class="ml-2" v-if="item.valid_at === null" variant="warning" @click="onRestore(item)">
                Restore
              </b-button>
            </div>
          </template>
        </TheTable>
        <TablePagination
          :currentPage="currentPage"
          :rows="rows"
          :perPage="perPage"
          @pagination="handlePagination"
          @entries="handleEntries"
        />
      </template>
    </CardWrapper>
  </div>
</template>

<script>
import FormModal from '@/components/TrialBalance/FormModal.vue'
import ImportModal from '@/components/TrialBalance/ImportModal.vue'
import { fields } from './fields'
import { mapActions } from 'vuex'

export default {
  name: 'TrialBalanceEditView',
  components: {
    FormModal,
    ImportModal,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSavedItemsNotMarkedDone) {
      this.$bvModal
        .msgBoxConfirm('You haven’t mark as done your changes. Admin will not be able to see your changes. Proceed?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Leave',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  data: () => ({
    isDeleteMode: false,
    records: [],
    deleteItems: [],
    modifiedItems: [],
    originalItems: [],
    balanceData: null,
    mode: 'add',
    currentPage: 1,
    rows: 0,
    perPage: 50,
    fetchStatus: 'idle',
    sort: {
      sortBy: 'coa',
      sortDesc: false,
    },
    timeReport: '',
    timeReports: [],
  }),
  watch: {
    isDeleteMode() {
      this.$refs.table?.$children[0]?.clearSelected()
    },
  },
  computed: {
    editTableFields() {
      return this.isDeleteMode
        ? fields.filter((_) => _.editTable && _.key !== 'action')
        : fields.filter((_) => _.editTable && _.key !== 'checkbox')
    },
    isSavedItemsNotMarkedDone() {
      return this.records.some((item) => item.efinop_user?.id === this.user.id && item.valid_at === null)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
    formModalData() {
      return {
        mode: this.mode,
        id: this.balanceData?.id,
        coa: this.balanceData?.coa,
        debit: this.balanceData?.debit,
        credit: this.balanceData?.credit,
        timeReport: this.balanceData?.timeReport,
        description: this.balanceData?.description,
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
    }),
    async getList() {
      try {
        this.fetchStatus = 'pending'

        let sortBy = ''
        if (this.sort.sortBy === 'coa') {
          sortBy = 'coa.account_number'
        } else if (this.sort.sortBy === 'focus_setting') {
          sortBy = 'coa.focus_setting.id'
        } else if (this.sort.sortBy === 'ssoi_setting') {
          sortBy = 'coa.ssoi_setting.id'
        } else {
          sortBy = this.sort.sortBy
        }

        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && {
              sort: [`${sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}`, 'time_report:asc'],
            }),
            ...(this.timeReport && { filters: { time_report: this.timeReport } }),
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/trial-balances/edit-mode', params)
        if (response.data?.data && response.data?.pagination) {
          this.getAvailableTimeReports()
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.records = this.formatDebitCredit(response.data.data)
          this.records.forEach((item) => {
            if (item.efinop_user?.id === this.user.id && item.valid_at === null) {
              switch (item.is_deleted) {
                case null:
                  item._rowVariant = 'warning'
                  break
                case false:
                  item._rowVariant = 'warning'
                  break
                case true:
                  item._rowVariant = 'danger'
                  break
              }
            }
          })
          if (this.modifiedItems.length > 0) {
            this.modifiedItems.forEach((item) => {
              const index = this.records.findIndex((listItem) => listItem.id === item.id)
              if (index === -1 && item.isNew) {
                this.records.unshift({
                  ...item,
                  _rowVariant: 'warning',
                })
              } else {
                this.records[index] = {
                  ...this.records[index],
                  ...item,
                  _rowVariant: 'warning',
                }
              }
            })
          }
          this.fetchStatus = 'success'
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    async getAvailableTimeReports() {
      this.fetchStatus = 'pending'
      try {
        const response = await this.$api.get(`/trial-balances/available-time-report?company_id=${this.company.id}`)
        if (response.status === 200 && response.data?.data) {
          this.fetchStatus = 'success'
          this.timeReports = response.data.data.map((timeReport) => {
            return {
              value: timeReport.time_report,
              text: timeReport.time_report,
            }
          })
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    formatDebitCredit(records) {
      return records.map((item) => {
        if (item.debit && item.credit === 0) {
          item.credit = null
        } else if (item.credit && item.debit === 0) {
          item.debit = null
        } else if (item.debit === 0 && item.credit === 0) {
          item.credit = null
        }
        return item
      })
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
    onAdd() {
      this.mode = 'add'
      this.balanceData = null
      this.$bvModal.show('trial-balance-form-modal')
    },
    onEdit(item) {
      this.mode = 'edit'
      this.balanceData = {
        id: item.id,
        coa: item.coa,
        debit: item.debit,
        credit: item.credit,
        timeReport: item.time_report,
        description: item.description,
      }
      this.$bvModal.show('trial-balance-form-modal')
    },
    onEdited(editedForm) {
      if (!editedForm) return
      const adjustments = editedForm.adjustments
      delete editedForm.adjustments
      const editedItems = []

      editedItems.push({
        ...editedForm,
      })

      if (adjustments.length > 0) {
        adjustments.forEach((adjustment) => {
          editedItems.push({
            ...adjustment,
          })
        })
      }

      editedItems.forEach((item) => {
        const modifiedItemIdx = this.modifiedItems.findIndex((_) => _.id === item.id)
        if (!item.id) {
          item.isNew = true
          item.updatedAt = new Date().toISOString()
        }
        if (modifiedItemIdx > -1) {
          if (!this.modifiedItems[modifiedItemIdx].valid_at === null) {
            this.modifiedItems[modifiedItemIdx].updatedAt = new Date().toISOString()
          }
          this.modifiedItems[modifiedItemIdx] = {
            ...this.modifiedItems[modifiedItemIdx],
            ...item,
          }
        } else {
          this.modifiedItems.push({
            ...item,
          })
        }

        const recordIdx = this.records.findIndex((_) => _.id === item.id)
        const deletedItem = this.records.splice(recordIdx, 1)
        this.originalItems.push({
          ...deletedItem[0],
        })
        this.records.unshift({
          ...item,
          _rowVariant: 'warning',
        })
      })
      this.records = this.formatDebitCredit(this.records)
    },
    onAdded(form) {
      if (!form) return
      form.id = Math.floor(Math.random() * 1000)
      this.modifiedItems.push({
        ...form,
        isNew: true,
        updatedAt: new Date().toISOString(),
      })

      this.records.unshift({
        ...form,
        time_report: form.timeReport,
        isNew: true,
        _rowVariant: 'warning',
      })
    },
    async onSave() {
      if (this.modifiedItems.length > 0) {
        this.setLoading(true)
        this.setDescription('Saving Trial Balance')
        const data = this.modifiedItems.map((record) => {
          if (record.isNew) {
            return {
              credit: record.credit ?? 0,
              debit: record.debit ?? 0,
              coa: record.coa?.id,
              timeReport: record.timeReport || record.time_report,
              description: record.description,
              updatedAt: new Date(record.updatedAt).toISOString(),
            }
          }
          return {
            id: record.id,
            credit: record.credit ?? 0,
            debit: record.debit ?? 0,
            coa: record.coa?.id,
            description: record.description,
            timeReport: record.timeReport || record.time_report,
          }
        })

        try {
          await this.$api.put(`/trial-balances?company_id=${this.company.id}`, {
            data,
          })
          this.$showSuccess('Records updated successfully.')
          this.modifiedItems = []
          this.getList()
        } catch (error) {
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        } finally {
          this.setLoading(false)
          this.setDescription('')
        }
      }
    },
    async onMarkDone() {
      this.setLoading(true)
      this.setDescription('Marking Trial Balance as Done...')
      try {
        await this.$api.get(`/trial-balances/mark-done?company_id=${this.company.id}`)
        this.$showSuccess('Records updated successfully.')
        this.getList()
        this.modifiedItems = []
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      } finally {
        this.setLoading(false)
        this.setDescription('')
      }
    },
    async downloadSample() {
      this.setLoading(true)
      this.setDescription('Downloading Template...')
      try {
        const response = await this.$api.get('/sample/download', {
          params: {
            type: 'BALANCE',
            company_id: this.company.id,
          },
        })
        if (response.status === 200 && response.data?.data?.url) {
          this.$showSuccess('Downloaded successfully.')
          // window.open(response.data.data.url)
          const link = document.createElement('a')
          link.href = response.data.data.url
          link.setAttribute('download', '')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      } finally {
        this.setLoading(false)
        this.setDescription('')
      }
    },
    onRestore(item) {
      this.$bvModal
        .msgBoxConfirm('This record will be restored. Are you sure?', {
          title: 'Restore',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.setLoading(true)
            this.setDescription('Restoring record...')
            try {
              const response = await this.$api.put(`/trial-balances?company_id=${this.company.id}`, {
                data: [
                  {
                    id: item.id,
                    credit: item.credit ?? 0,
                    debit: item.debit ?? 0,
                    coa: item.coa?.id,
                    description: item.description,
                    timeReport: item.time_report,
                    isUndo: true,
                  },
                ],
              })

              if (response.status === 200) {
                this.modifiedItems = this.modifiedItems.filter((record) => record.id !== item.id)
                this.$showSuccess('Record restored successfully.')
                this.getList()
              }
            } catch (error) {
              this.$showError(error.response?.data?.error?.message || 'Something went wrong')
            } finally {
              this.setLoading(false)
            }
          }
        })
    },
    canRevert(item) {
      return this.modifiedItems.findIndex((record) => record.id === item.id) > -1 && item.valid_at !== null
    },
    revertItem(item) {
      const index = this.modifiedItems.findIndex((record) => record.id === item.id)
      if (index > -1) {
        this.modifiedItems.splice(index, 1)
      }
      if (item.isNew) {
        const index = this.records.findIndex((record) => record.id === item.id)
        if (index > -1) {
          this.records.splice(index, 1)
          return
        }
      }
      const originalItem = this.originalItems.find((record) => record.id === item.id)
      if (originalItem) {
        this.$set(item, 'credit', originalItem.credit)
        this.$set(item, 'debit', originalItem.debit)
        this.$set(item, 'coa', originalItem.coa)
        this.$set(item, 'description', originalItem.description)
        this.$set(item, 'time_report', originalItem.time_report)
        this.$set(item, '_rowVariant', '')
      }
      this.originalItems = this.originalItems.filter((record) => record.id !== item.id)
    },
    toggleSelectAll(value, data) {
      value ? data.selectAllRows() : data.clearSelected()
    },
    onRowSelected(items) {
      this.deleteItems = items
    },
    saveDeleteItems() {
      this.$bvModal
        .msgBoxConfirm('This records will be saved as deleted. Are you sure?', {
          title: 'Confirm Delete',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.setLoading(true)
            this.setDescription('Saving deleted record...')

            try {
              const data = this.deleteItems
                .filter((record) => !record.isNew)
                .map((record) => ({
                  id: record.id,
                  credit: record.credit ?? 0,
                  debit: record.debit ?? 0,
                  coa: record.coa?.id,
                  description: record.description,
                  timeReport: record.time_report,
                  isDeleted: true,
                }))

              if (data?.length > 0) {
                await this.$api.put(`/trial-balances?company_id=${this.company.id}`, {
                  data,
                })
              }
              this.modifiedItems = this.modifiedItems.filter(
                (record) => !this.deleteItems.some((_) => _.id === record.id),
              )
              this.$showSuccess('Record updated successfully.')
              this.deleteItems = []
              this.isDeleteMode = false
              this.getList()
            } catch (error) {
              this.$showError(error.response?.data?.error?.message || 'Something went wrong')
            } finally {
              this.setLoading(false)
            }
          }
        })
    },
  },
}
</script>
