<template>
  <div>
    <b-modal id="add-company-form-modal" title="Add Company" size="lg" @hidden="resetForm" scrollable>
      <div class="text-center" v-if="status === 'loading'">
        <b-spinner></b-spinner>
      </div>

      <ValidationObserver v-else ref="addCompanyForm">
        <b-form>
          <ValidationProvider vid="company_name" name="Company name" rules="required" v-slot="{ errors }">
            <b-form-group
              :invalid-feedback="errors[0]"
              class="col p-0 mb-3"
              label="Company Name"
              label-class="required-asterisk"
            >
              <b-form-input
                type="text"
                v-model="form.company_name"
                :state="errors[0] ? false : null"
                :disabled="status === 'loading'"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="Domain" name="Domain" rules="required" v-slot="{ errors }">
            <b-form-group
              :invalid-feedback="errors[0]"
              class="col p-0 mb-3"
              label="Domain"
              label-class="required-asterisk"
            >
              <b-form-input
                type="text"
                v-model="form.domain"
                :state="errors[0] ? false : null"
                :disabled="status === 'loading'"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="email" name="Email" rules="required" v-slot="{ errors }">
            <b-form-group :invalid-feedback="errors[0]" label="Email" label-class="required-asterisk">
              <b-form-select
                :options="emails"
                v-model="form.user_email"
                :state="errors[0] ? false : null"
                :disabled="status === 'loading'"
              ></b-form-select>
            </b-form-group>
          </ValidationProvider>
        </b-form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()"> Cancel </b-button>
        <b-button variant="primary" @click="onCreate('create')"> Create </b-button>
        <b-button variant="primary" @click="onCreate('create_select')"> Create and Select </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AddCompanyFormModal',
  data() {
    return {
      status: 'idle',
      mode: 'create',
      form: {
        company_name: '',
        domain: '',
        user_email: '',
      },
    }
  },
  computed: {
    emails() {
      return this.user.emails
        .filter((email) => email.is_confirmed)
        .map((email) => ({
          text: email.email_address,
          value: email.id,
        }))
    },
  },
  methods: {
    resetForm() {
      this.form = {
        company_name: '',
        domain: '',
        user_email: '',
      }
    },
    onCreate(mode) {
      this.mode = mode
      this.$refs.addCompanyForm.validate().then(async (valid) => {
        if (!valid) {
          this.$showError('Please fill all the required fields')
          return
        }

        try {
          this.status = 'loading'
          const result = await this.$api.post('companies', {
            data: {
              company_name: this.form.company_name,
              domain: this.form.domain,
              user_email: this.form.user_email,
            },
          })
          const message =
            mode === 'create'
              ? 'Company profile has been created successfully'
              : 'Company profile has been created successfully and selected'
          this.$showSuccess(message)
          this.status = 'success'
          this.$emit('onCreate', { mode: this.mode, id: result?.data?.data?.company_info?.id })
        } catch (error) {
          console.log('submit-error', error)
          this.status = 'error'
          this.$showError(error?.response?.data?.error?.message)
        }
      })
    },
  },
}
</script>
