import { numberFormatter, dateFormatter } from '@/utils/helpers'

export const inventoryFields = [
  {
    key: 'checkbox',
    label: '',
    listTable: false,
    editTable: true,
    size: '50px',
  },
  {
    key: 'id',
    label: '',
    listTable: false,
    editTable: false,
    size: '40px',
  },
  {
    key: 'report_date',
    label: 'Report Date',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return dateFormatter(value)
    },
  },
  {
    key: 'name_description',
    label: 'Description',
    listTable: false,
    editTable: false,
    sortable: true,
    size: '100px',
  },
  {
    key: 'identifier',
    label: 'Identifier',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
  },
  {
    key: 'cusip_isin',
    label: 'CUSIP/ ISIN',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    thClass: 'text-wrap',
  },
  {
    key: 'type',
    label: 'Type',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
  },
  {
    key: 'subtype',
    label: 'SubType',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
  },
  {
    key: 'long_or_short',
    label: 'Long/ Short',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
  },
  {
    key: 'qty',
    label: 'Qty',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'closing_price',
    label: 'Closing Price',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  // {
  //   key: 'bonds_face_val',
  //   label: 'Bonds Face Value',
  //   listTable: true,
  //   editTable: true,
  //   sortable: true,
  //   size: '120px',
  //   formatter: (value) => {
  //     return numberFormatter(value, 0)
  //   },
  //   tdClass: 'text-right',
  // },
  {
    key: 'maturity',
    label: 'Maturity Date',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return dateFormatter(value)
    },
  },
  {
    key: 'tot_pos_val',
    label: 'Total Position Value',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'haircut_amount',
    label: 'Haircut Amount',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_basis',
    label: 'Undue Concentration Basis',
    listTable: true,
    editTable: false,
    sortable: true,
    size: '180px',
    formatter: (value) => {
      if (value === 'Not applicable') {
        return value
      }
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_charge',
    label: 'Undue Concentration Charge',
    listTable: true,
    editTable: false,
    sortable: true,
    size: '190px',
    formatter: (value) => {
      if (value === 'Not applicable') {
        return value
      }
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  {
    key: 'createdAt',
    label: 'Created Date',
    listTable: true,
    editTable: false,
    formatter: (value) => {
      return dateFormatter(value)
    },
    size: '100px',
  },
  {
    key: 'updatedAt',
    label: 'Update Date',
    listTable: true,
    editTable: false,
    formatter: (value) => {
      return dateFormatter(value)
    },
    size: '100px',
  },
  {
    key: 'action',
    label: '',
    listTable: false,
    editTable: true,
    size: '100px',
  },
]

export const haircutFields = [
  {
    key: 'checkbox',
    label: '',
    listTable: false,
    editTable: true,
    size: '50px',
  },
  {
    key: 'id',
    label: '',
    listTable: false,
    editTable: false,
    size: '40px',
  },
  {
    key: 'report_date',
    label: 'Report Date',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return dateFormatter(value)
    },
  },
  {
    key: 'contract_desc',
    label: 'Contract Description',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '170px',
  },
  {
    key: 'cusip_isin',
    label: 'CUSIP/ ISIN',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    tdClass: 'text-center',
  },
  {
    key: 'call_put',
    label: 'Call/ Put',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    tdClass: 'text-center',
  },
  {
    key: 'currency',
    label: 'Currency',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    tdClass: 'text-center',
  },
  {
    key: 'contracts_qty',
    label: 'Contracts Qty',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '110px',
    tdClass: 'text-center',
  },
  {
    key: 'contract_purch_pr',
    label: 'Contract Purchase Price',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'long_short',
    label: 'Long/ Short',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
  },
  {
    key: 'covered',
    label: 'Covered',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
  },
  {
    key: 'underlying_exec_pr',
    label: 'Underlying Execution Price',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'underlying_mkt_pr',
    label: 'Underlying Market Price',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'underlying_mkt_value',
    label: 'Underlying Market Value',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'underlying_haircut',
    label: 'Underlying Haircut',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  {
    key: 'tot_pos_val',
    label: 'Total Position Value',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'haircut_amount',
    label: 'Haircut Amount',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_basis',
    label: 'Undue Concentration Basis',
    listTable: true,
    editTable: false,
    sortable: true,
    size: '180px',
    formatter: (value) => {
      if (value === 'Not applicable') {
        return value
      }
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_charge',
    label: 'Undue Concentration Charge',
    listTable: true,
    editTable: false,
    sortable: true,
    size: '190px',
    formatter: (value) => {
      if (value === 'Not applicable') {
        return value
      }
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  // {
  //   key: 'closing_price',
  //   label: 'Closing Price',
  //   listTable: true,
  //   editTable: true,
  //   sortable: true,
  //   size: '150px',
  // },
  // {
  //   key: 'strike',
  //   label: 'Strike',
  //   listTable: true,
  //   editTable: true,
  //   sortable: true,
  //   size: '100px',
  // },
  // {
  //   key: 'underlying_symbol',
  //   label: 'Underlying Symbol',
  //   listTable: true,
  //   editTable: true,
  //   sortable: true,
  //   size: '200px',
  // },
  {
    key: 'createdAt',
    label: 'Created Date',
    listTable: true,
    editTable: false,
    formatter: (value) => {
      return dateFormatter(value)
    },
    size: '100px',
  },
  {
    key: 'updatedAt',
    label: 'Update Date',
    listTable: true,
    editTable: false,
    formatter: (value) => {
      return dateFormatter(value)
    },
    size: '100px',
  },
  {
    key: 'action',
    label: '',
    listTable: false,
    editTable: true,
    size: '100px',
  },
]
