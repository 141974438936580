<template>
  <div>
    <div :class="data.title ? 'grid' : ''" v-if="!Array.isArray(data)">
      <div class="font-weight-bold" v-if="data.title && (data.contacts || data.contact)">
        {{ data.title }}
      </div>
      <div class="grid-item">
        <div v-if="data.label" class="grid-item-header-name my-auto">{{ data.label }}</div>
        <div v-if="data.title" class="grid-item-header-name my-auto font-weight-bold">{{ data.title }}</div>
        <div class="grid-item-header-value" v-if="data.type === 'input'">
          <b-form-input
            size="md"
            :value="data.item_value ? data.item_value : ''"
            @input="onInput(data.item_number ? data.item_number : data.code, $event)"
            type="text"
          >
          </b-form-input>
        </div>
        <div class="grid-item-header-value" v-if="data.type === 'checkbox'">
          <b-form-radio-group
            :options="[
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ]"
            :checked="data.item_value === 'X' ? true : false"
            @change="onRadioChange(data.item_number ? data.item_number : data.code, $event)"
            value-field="value"
            text-field="text"
          ></b-form-radio-group>
        </div>
        <div class="grid-item-header-value" v-if="data.type === 'select'">
          <b-form-select
            :value="data.item_value"
            :options="
              data.values.map((item) => {
                return { value: item, text: item }
              })
            "
            @change="onInput(data.item_number ? data.item_number : data.code, $event)"
          ></b-form-select>
        </div>
        <div class="grid-item-header-value" v-if="data.type === 'date'">
          <b-form-datepicker
            size="md"
            :value="formatDate(data.item_value)"
            locale="en"
            @input="onDateChange(data.item_number ? data.item_number : data.code, $event)"
          ></b-form-datepicker>
        </div>
        <div class="grid-item-header-value" v-if="data.type === 'currency'">
          <b-input-group prepend="$" size="md">
            <b-form-input
              type="number"
              size="md"
              :value="data.item_value"
              @input="onInput(data.item_number ? data.item_number : data.code, $event)"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="grid-item-header-value text-center" v-if="data.item_number">
          {{ data.item_number }}
        </div>
        <div class="grid-item-header-value text-center" v-else-if="data.code">
          {{ data.code }}
        </div>
      </div>
    </div>
    <div v-if="data.contents || data.content || Array.isArray(data)">
      <SSOIReports v-for="(data, index) in data.contents" :data="data" :key="index" class="pl-2" />
      <SSOIReports v-for="(data, index) in data.content" :data="data" :key="index" class="pl-2" />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'SSOIReports',
  props: ['data'],
  methods: {
    ...mapActions('report/ssoi', ['addInput', 'removeInput']),
    formatDate(date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD')
    },
    onInput(item_number, item_value) {
      this.addInput({ item_number, item_value })
    },
    onDateChange(item_number, item_value) {
      this.addInput({ item_number, item_value: moment(item_value).format('DD/MM/YYYY') })
    },
    onRadioChange(item_number, item_value) {
      if (item_value === true) {
        this.addInput({ item_number, item_value: 'X' })
      } else {
        this.removeInput({ item_number })
      }
    },
  },
  watch: {
    data: {
      handler() {
        if (this.data.item_value) {
          this.addInput({ item_number: this.data.item_number, item_value: this.data.item_value.toString() })
        }
        this.data.contents?.forEach((item) => {
          if (item.item_value) {
            this.addInput({ item_number: item.item_number, item_value: item.item_value.toString() })
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
}

.grid-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.grid-item {
  display: grid;
  grid-template-columns: 5fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
  padding: 0.5rem;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
}

.grid-item-header-name {
  font-weight: 400;
  font-size: 14px;
}

.grid-item-header-value {
  font-weight: 300;
  justify-self: center;
  font-size: 14px;
  color: #545e69;
  width: 100%;
}
</style>
