/**
 *
 * Dynamic Menu
 *
 */
import admin from './admin.json'
import middle from './middle.json'
import viewer from './viewer.json'

const Menu = {
  ADMIN: admin,
  MIDDLE: middle,
  VIEWER: viewer,
}
export default Menu
