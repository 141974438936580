<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <b-form-group class="mb-0">
        <b-form-select v-model="reportDate" :options="reportDates" class="mb-0" @change="getNetCapital">
          <template #first>
            <b-form-select-option :value="null" disabled>Select Report Date</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <div class="d-flex align-items-center">
        <b-button variant="primary" :disabled="!reportDate" size="sm" class="mr-3" @click="downloadReport('view')">
          Preview PDF
        </b-button>
        <b-button variant="primary" :disabled="!reportDate" size="sm" @click="downloadReport('download')">
          Download PDF
        </b-button>
      </div>
    </div>
    <b-table
      responsive
      outlined
      bordered
      sticky-header="100vh"
      :items="records"
      :busy="tableLoading"
      :fields="tableFields"
      show-empty
      :tbody-tr-class="rowClass"
    >
      <template #cell(label)="data">
        <div :class="[isTotalRow(data.item.label) ? 'd-flex justify-content-end' : '']">
          <span>{{ data.item.label }}</span>
        </div>
      </template>
      <template #cell(value)="{ value }">
        <div class="d-flex justify-content-between">
          <div v-if="!value.includes('%')">$</div>
          <div class="w-100 text-right">{{ value }}</div>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { numberFormatter, dateFormatter, manageReportFiles } from '@/utils/helpers'

export default {
  name: 'NetCapital',
  data() {
    return {
      fields: [
        {
          id: 1,
          key: 'id',
          label: 'ID',
          editRecord: false,
          viewRecord: false,
          type: 'text',
        },
        {
          id: 2,
          key: 'label',
          label: 'Description',
          viewRecord: true,
        },
        {
          id: 3,
          key: 'value',
          label: 'Amount',
          viewRecord: true,
        },
      ],
      status: 'idle',
      records: [],
      reportDate: null,
      reportDates: [],
    }
  },
  computed: {
    tableLoading() {
      return this.status === 'loading'
    },
    tableFields() {
      return this.fields.filter((field) => field.viewRecord)
    },
  },
  created() {
    this.getAvailableTimeReports()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setPdf: 'report/setPdf',
    }),
    async getNetCapital() {
      try {
        this.status = 'loading'
        this.records = []
        const response = await this.$api.get('/net-capital', {
          params: {
            reportDate: this.reportDate,
            company_id: this.company.id,
          },
        })
        if (response.status === 200 && response.data.data) {
          this.records = this.setTableData(response.data.data)
          this.status = 'success'
        }
      } catch (error) {
        console.log('error', error)
        if (error.status === 401) return
        this.status = 'error'
        this.$showError('Unable to fetch api records')
      }
    },
    isTotalRow(row) {
      return [
        'Net Worth',
        'Adjusted Net Worth',
        'Current Net Capital Before Haircuts',
        'Tentative Net Capital',
        'Net Capital',
        'Current Capital',
      ].includes(row)
    },
    rowClass(row, index) {
      if (this.isTotalRow(row?.label)) {
        return 'font-weight-bold text-uppercase bg-gradient'
      }
    },
    setTableData(data) {
      return data.map((item) => {
        return {
          ...item,
          value: `${numberFormatter(item.value) ?? '0.00'}${item.postfix ?? ''}`,
        }
      })
    },
    downloadReport(mode) {
      this.setLoading(true)
      const params = {
        params: {
          reportDate: this.reportDate,
          download: true,
          company_id: this.company.id,
        },
      }
      this.$api
        .get('/net-capital', params)
        .then((res) => {
          if (!res.data?.data) {
            this.$showInfo('There is no data to display')
            return
          }
          const createdPDF = manageReportFiles(res.data)
          if (mode === 'download') {
            createdPDF.download(`Net_Capital_${dateFormatter(this.reportDate)}.pdf`)
            return
          }
          createdPDF.getDataUrl((dataUrl) => {
            this.setPdf({
              src: dataUrl,
              title: `Net_Capital_${dateFormatter(this.reportDate)}`,
            })
            this.$bvModal.show('PdfModal')
          })
        })
        .catch((err) => {
          this.status = 'error'
          this.$showError(err.response?.data?.error?.message || 'Unable to fetch api records')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    async getAvailableTimeReports() {
      this.status = 'loading'
      try {
        const response = await this.$api.get(`/net-capital/available-report-date?company_id=${this.company.id}`)
        if (response.status === 200 && response.data?.data?.length > 0) {
          this.reportDates = response.data.data.map((report) => {
            return {
              value: report.report_date,
              text: dateFormatter(report.report_date),
            }
          })
          if (this.reportDates.length > 0) {
            this.reportDate = this.reportDates[0].value
            await this.getNetCapital()
          }
        }
        this.status = 'success'
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
  },
}
</script>
