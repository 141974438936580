<template>
  <div>
    <UserInviteModal @invite-success="getUsersList" />
    <div class="d-flex align-items-center justify-content-end mb-3">
      <b-button variant="danger" v-if="isAdmin && selectedRows.length > 0" @click="deleteUser"> Delete </b-button>
      <b-button variant="primary" v-if="isAdmin" v-b-modal.userDetails-form class="ml-2">
        Send Invite to User
      </b-button>
    </div>

    <TheTable
      :records="records"
      :fields="fields"
      :loading="status === 'loading'"
      selectable
      @row-selected="onRowSelected"
      no-local-sorting
    >
      <template #head(checkbox)="data">
        <b-form-checkbox @change="(e) => toggleSelectAll(e, data)"></b-form-checkbox>
      </template>
      <template #cell(checkbox)="{ item }">
        <b-form-checkbox v-model="selectedRows" :value="item"></b-form-checkbox>
      </template>
      <template #cell(status)="{ item }">
        <BadgeStatus :status="item.status">
          {{ item.status }}
        </BadgeStatus>
      </template>
    </TheTable>
    <TablePagination
      :currentPage="currentPage"
      :rows="rows"
      :perPage="perPage"
      @pagination="handlePagination"
      @entries="handleEntries"
    />
  </div>
</template>

<script>
import moment from 'moment'
import UserInviteModal from '@/components/Setting/Company/UserInviteModal.vue'

export default {
  name: 'CompanyUserList',
  components: {
    UserInviteModal,
  },
  data() {
    return {
      records: [],
      paginationOptions: [10, 25, 50, 100, 250],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        {
          key: 'checkbox',
          label: '',
          size: '50px',
        },
        {
          key: 'name',
          label: 'Name',
          size: '120px',
          formatter: (value, key, item) => {
            return `${item.first_name || ''} ${item.last_name || ''}`
          },
        },
        {
          key: 'phone_number',
          label: 'Phone Number',
          size: '130px',
        },
        {
          key: 'emails',
          label: 'Email',
          formatter: (value) => {
            return value ? value.map((email) => email.email_address).join(', ') : ''
          },
          size: '200px',
        },
        {
          key: 'user_title',
          label: 'Title',
          size: '100px',
        },
        {
          key: 'status',
          label: 'Status',
          size: '100px',
        },
        {
          key: 'createdAt',
          label: 'Created Date',
          size: '100px',
          formatter: (value) => {
            return value ? moment(value).format('MM/DD/YYYY') : ''
          },
        },
        {
          key: 'updatedAt',
          label: 'Updated Date',
          size: '100px',
          formatter: (value) => {
            return value ? moment(value).format('MM/DD/YYYY') : ''
          },
        },
      ],
      status: 'idle',
      selectedRows: [],
    }
  },
  created() {
    this.getUsersList()
  },
  methods: {
    getUsersList() {
      this.status = 'loading'
      const params = {
        params: {
          pagination: {
            page: this.currentPage,
            pageSize: this.perPage,
          },
        },
      }

      this.$api
        .get(`/companies/${this.company.id}/users`, params)
        .then((res) => {
          if (res.data?.data && res.data?.pagination) {
            this.records = res.data.data
            const { page, pageCount, pageSize, total } = res.data.pagination
            this.currentPage = page
            this.rows = total
            this.status = 'success'
          }
        })
        .catch((error) => {
          this.status = 'error'
          this.$showError(error?.response?.data?.error?.message)
        })
    },
    handleEntries(val) {
      this.perPage = val
      this.getUsersList()
    },
    handlePagination(currentPage) {
      this.currentPage = currentPage
      this.getUsersList()
    },
    deleteUser(id) {
      const text = 'Are you sure you want to delete these records?'
      this.$bvModal
        .msgBoxConfirm(text, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.status = 'loading'
            const ids = this.selectedRows.map((row) => row.id)

            this.$api
              .delete(`/companies/${this.company.id}/users?id=${ids}`)
              .then((res) => {
                this.$showSuccess('User has been deleted')
                this.status = 'success'
                this.getUsersList()
              })
              .catch((err) => {
                this.status = 'error'
                this.$showError(err.response?.data?.error?.message || 'Unable to delete. Please try again later.')
              })
          }
        })
    },
    toggleSelectAll(value, data) {
      value ? data.selectAllRows() : data.clearSelected()
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
  },
}
</script>
