<template>
  <b-modal id="trial-balance-import-modal" title="Import Trial Balance" @hidden="resetModal" hide-footer>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
        <ValidationProvider name="File" rules="required|csv" v-slot="{ errors, valid }">
          <b-form-group
            label="Select File"
            description="Maximum file size is 10MB. Only csv is accepted"
            :invalid-feedback="errors[0]"
          >
            <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              :state="errors[0] ? false : valid ? true : null"
              accept=".csv"
              v-model="file"
              data-cy="tb-upload-file-input"
            >
            </b-form-file>
          </b-form-group>
        </ValidationProvider>
        <b-row class="mt-4">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button type="button" variant="secondary" @click="$bvModal.hide('trial-balance-import-modal')">
              Cancel
            </b-button>
            <b-button type="reset" class="ml-2" variant="warning" @click="resetModal">Reset</b-button>
            <b-button type="submit" class="ml-2" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { uploadFile } from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  name: 'UploadModal',
  data() {
    return {
      file: null,
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
    }),
    onSubmit() {
      this.setLoading(true)
      this.setDescription('Importing Trial Balance...')
      const formData = new FormData()
      formData.append('files', this.file)

      uploadFile(formData, 'UPLOAD-TRIAL-BALANCE')
        .then((res) => {
          this.$showSuccess('File uploaded successfully.')
          this.$emit('uploaded')
          this.$bvModal.hide('trial-balance-import-modal')
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error?.message || 'Unable to create record.'
          const errorDetails = error.response?.data?.error?.details
          const errors = Array.isArray(errorDetails) ? errorDetails : [errorDetails]
          const h = this.$createElement
          const vbMessage = h('p', {}, [
            h('span', { style: { fontWeight: 'bold' } }, errorMessage),
            errors.length > 0 &&
              h('ul', {}, [
                errors.map((error) => {
                  if (!error.message) {
                    return
                  }
                  return h('li', { style: { listStyleType: 'circle' } }, [error.message])
                }),
              ]),
          ])
          this.$showError(vbMessage || 'Unable to create record')
        })
        .finally(() => {
          this.setLoading(false)
          this.setDescription('')
        })
    },
    resetModal() {
      this.file = null
    },
  },
}
</script>
