<template>
  <div id="tb-page">
    <transition
      name="router-anim"
      :enter-active-class="`animated ${animated.enter} fastest`"
      mode="out-in"
      :leave-active-class="`animated ${animated.exit} fastest`"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TrialBalance',
  data: () => ({
    animated: { enter: 'fadeIn', exit: 'fadeOut' },
  }),
}
</script>
