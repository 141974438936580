<template>
  <div>
    <AddCompanyModal @onCreate="onCreateSuccess" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="verticalMenu" @toggle="sidebarMini" />
      <!-- TOP Nav Bar -->
      <NavBarStyle1 title="eFINOP" :homeURL="{ name: 'app.dashboard' }" @toggle="sidebarMini">
        <template slot="responsiveRight">
          <div class="ml-2">
            <b-dropdown variant="outline-none" class="m-2" style="z-index: 100">
              <template #button-content>
                <b-avatar icon="people-fill" size="sm" class="mr-2"></b-avatar>
                <span>{{ company.company_name }}</span>
              </template>
              <b-dropdown-item v-b-modal.add-company-form-modal>
                <i class="ri-add-line"></i>
                Add new company
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <template v-if="companies.length > 0">
                <b-dropdown-item
                  href="#"
                  v-for="userComp in companies"
                  :key="userComp.id"
                  @click="onSelectCompany(userComp)"
                >
                  <i class="ri-building-2-line mr-2"></i>
                  {{ userComp.company_info.company_name }}
                  <span v-if="isSelected(userComp.company_info.id)"><i class="ri-check-line"></i></span>
                </b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item disabled>
                  <i class="ri-building-2-line mr-2"></i>
                  No companies
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <ul class="navbar-nav ml-auto navbar-list"></ul>
        </template>
        <template slot="right">
          <ul class="navbar-list profile-dropdown">
            <li>
              <a href="#" class="iq-waves-effect d-flex align-items-center">
                <b-avatar variant="primary" :src="avatar" class="mr-3 mt-1"></b-avatar>
                <div class="caption">
                  <h6 class="mb-0 line-height">
                    {{ (user && `${user.first_name} ${user.last_name}`) || 'Unknown' }}
                  </h6>
                  <span class="font-size-12">
                    {{ role }}
                  </span>
                </div>
              </a>
              <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white line-height">
                        {{ (user && `${user.first_name} ${user.last_name}`) || 'Unknown' }}
                      </h5>
                      <span class="text-white font-size-12">
                        {{ role }}
                      </span>
                    </div>
                    <router-link to="/setting/user-profile" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-file-user-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0">
                            {{ $t('nav.user.profileTitle') }}
                          </h6>
                          <p class="mb-0 font-size-12">
                            {{ $t('nav.user.profileSub') }}
                          </p>
                        </div>
                      </div>
                    </router-link>
                    <router-link to="/setting/company-profile" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-building-2-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0">Company Profile</h6>
                          <p class="mb-0 font-size-12">Modify your company details</p>
                        </div>
                      </div>
                    </router-link>
                    <div class="d-inline-block w-100 text-center p-3">
                      <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="onLogout" role="button">
                        {{ $t('nav.user.signout') }}
                        <i class="ri-login-box-line ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </NavBarStyle1>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page" :class="horizontal ? 'ml-0' : ''">
        <transition
          name="router-anim"
          :enter-active-class="`animated ${animated.enter} fastest`"
          mode="out-in"
          :leave-active-class="`animated ${animated.exit} fastest`"
        >
          <router-view />
        </transition>
      </div>
    </div>
    <FooterStyle1>
      <template v-slot:left>
        <li class="list-inline-item">
          <a href="#">Privacy Policy</a>
        </li>
        <li class="list-inline-item">
          <a href="#">Terms of Use</a>
        </li>
      </template>
      <template v-slot:right>{{ footerDescription }}</template>
    </FooterStyle1>
  </div>
</template>

<script>
import SideBarStyle1 from '../components/findash/sidebars/SideBarStyle1'
import NavBarStyle1 from '../components/findash/navbars/NavBarStyle1'
import profile from '../assets/images/user/user-1.jpeg'
import Menu from '../menu'
import { findash } from '@/plugins/findash'
import { mapGetters, mapActions } from 'vuex'

let body = document.querySelector('body')

export default {
  name: 'Layout1',
  components: {
    SideBarStyle1,
    NavBarStyle1,
  },
  data() {
    return {
      sessionLoader: false,
      sessionUser: {},
      horizontal: false,
      mini: false,
      animated: { enter: 'fadeIn', exit: 'fadeOut' },
      userProfile: profile,
      rtl: false,
    }
  },
  mounted() {
    findash.index()
    body.classList.remove('sidebar-main')
    body.classList.remove('right-column-fixed')
    if (this.$route.meta.dark) {
      body.classList.add('dark')
      body.classList.remove('light')
    } else {
      body.classList.add('light')
      body.classList.remove('dark')
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'auth/getAvatar',
      role: 'auth/getRole',
      companies: 'auth/getUserCompanies',
    }),
    verticalMenu() {
      return Menu[this.role]
    },
    footerDescription() {
      const year = new Date().getFullYear()
      return `Copyright ${year} eFINOP All Rights Reserved.`
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      setLoading: 'loader/setLoading',
    }),
    sidebarMini() {
      findash.triggerSet()
    },
    onLogout() {
      this.logout().then(() => {
        this.$router.go()
      })
    },
    async onCreateSuccess(data) {
      this.$bvModal.hide('add-company-form-modal')
      await this.$store.dispatch('auth/getUser')
      if (data.mode === 'create_select') {
        const companySetting = this.companies.find((company) => company.company_info.id === data.id)
        this.onSelectCompany(companySetting)
      }
    },
    async onSelectCompany(companySetting) {
      this.$store.dispatch('auth/setCurrentCompanyWithSetting', companySetting)
      this.$router.go()
      this.$showInfo('Company has been switched successfully')
    },
    isSelected(id) {
      return this.company.id === id
    },
  },
  beforeDestroy() {
    document.removeEventListener('storage', this.storageListener)
  },
}
</script>
