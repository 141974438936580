<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

// https://dev.efinop.com/setting/user-profile/confirm-register-email?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbElkIjozLCJ1c2VySWQiOjEsInR5cGUiOiJSRUdJU1RFUl9NT1JFX0VNQUlMIiwiaWF0IjoxNjg2NzQ2NDIyLCJleHAiOjE2ODkzMzg0MjJ9.IFg638iEzMYZRLi-3zq2huOQMOEeRno8XNaMYpMMMls
export default {
  name: 'ConfirmRegisterEmail',
  created() {
    const token = this.$route.query.token
    if (!token) {
      this.$router.push('/auth/sign-in')
    }
    this.confirmRegisterEmail(token)
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
    }),
    confirmRegisterEmail(token) {
      this.setLoading(true)
      this.setDescription('Confirming email...')
      this.$api
        .post('/me/confirm-register-email', {
          data: {
            token,
          },
        })
        .then(async (res) => {
          this.$showSuccess(res.data.message || 'Email registered successfully!')
          await this.$store.dispatch('auth/getUser')
          this.$router.push('/setting/user-profile')
        })
        .catch((error) => {
          const Errors = (Array.isArray(error.response?.data?.error?.details) &&
            error.response?.data?.error?.details) || [{ message: error.response?.data?.error?.message }] || [
              { message: 'Unable to update user profile data.' },
            ]
          Errors.forEach((error) => {
            this.$showError(error.message)
          })
        })
        .finally(() => {
          this.setLoading(false)
          this.setDescription('')
        })
    },
  },
}
</script>
