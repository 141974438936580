import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    userCompany: null,
    userPrimaryContact: null,
    status: null,
    statusMessage: null,
    prefillValues: {
      FOCUS: null,
      SSOI: null,
    },
  },
  getters: {
    company: (state) => state.company,
    createCompanyData: (state) => state.createCompanyData,
    static_fields: (state) => state.static_fields,
    loading: (state) => state.loading,
    status: (state) => state.status,
    focusPrefill: (state) => state.prefillValues.FOCUS,
    ssoiPrefill: (state) => state.prefillValues.SSOI,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_COMPANY(state, payload) {
      state.userCompany = { ...payload }
    },
    SET_STATUS(state, { status, message }) {
      state.status = status
      state.statusMessage = message
    },
    SET_USER_PRIMARY_CONTACT(state, payload) {
      state.userPrimaryContact = payload
    },
    SET_COMPANY_PREFILL(state, payload) {
      state.prefillValues = payload
    },
  },
  actions: {
    setUserCompany(context, payload) {
      context.commit('SET_COMPANY', payload)
    },
    setStatus(context, payload) {
      context.commit('SET_STATUS', payload)
    },
    setPrimaryContact(context, payload) {
      context.commit('SET_USER_PRIMARY_CONTACT', payload)
    },
    setUserCompanyPrefill(context, payload) {
      context.commit('SET_COMPANY_PREFILL', payload)
    },
  },
}
