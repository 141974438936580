<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveGeneralInfo)">
        <b-row>
          <b-col sm="12" lg="6">
            <h1 class="tab-title">COMPANY PROFILE</h1>

            <ValidationProvider vid="Company name" name="Company name" rules="required" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" label="Company Name" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  v-model="company_name"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Company Address 1" name="Company Address 1" rules="required" v-slot="{ errors }">
              <b-form-group label="Company Address 1" label-class="required-asterisk" :invalid-feedback="errors[0]">
                <b-form-input
                  type="text"
                  v-model="address.address_1"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Company Address 2" name="Company Address 2">
              <b-form-group label="Company Address 2">
                <b-form-input type="text" v-model="address.address_2" :disabled="disableForm" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Company Address 3" name="Company Address 3" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" label="Company Address 3">
                <b-form-input
                  type="text"
                  :state="errors[0] ? false : null"
                  v-model="address.address_3"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Company Country" name="Company Country" rules="required" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" label="Company Country" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  id="Company Country"
                  v-model="address.country"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Company Main Telephone"
              name="Company Main Telephone"
              rules="required|length:14"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                label="Company Main Telephone"
                label-class="required-asterisk"
              >
                <b-form-input
                  type="text"
                  id="Company Main Telephone"
                  v-model="addressBillingPhone"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Company State/Provence"
              name="Company State/Provence"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                label="Company State/Provence"
                label-class="required-asterisk"
              >
                <b-form-input
                  type="text"
                  :state="errors[0] ? false : null"
                  v-model="address.state"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Company City" name="Company City" rules="required" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" label="Company City" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  id="Company City"
                  v-model="address.city"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Company Postal Code"
              name="Company Postal Code"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group :invalid-feedback="errors[0]" label="Company Postal Code" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  id="Company City"
                  :state="errors[0] ? false : null"
                  v-model="address.postal_code"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col sm="12" lg="6">
            <h1 class="tab-title">PRIMARY CONTACT</h1>
            <div class="d-lg-flex w-100" style="gap: 4px">
              <ValidationProvider
                vid="Primary First Name"
                name="First Name"
                class="w-100"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group :invalid-feedback="errors[0]" label="First Name" label-class="required-asterisk">
                  <b-form-input
                    type="text"
                    id="First Name"
                    v-model="primary_contact.first_name"
                    :state="errors[0] ? false : null"
                    :disabled="disableForm"
                  />
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider vid="Primary Middle Name" name="Middle Name" class="w-100">
                <b-form-group label="Middle Name">
                  <b-form-input type="text" v-model="primary_contact.middle_name" id="Middle Name" />
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                vid="Primary Last Name"
                name="Last Name"
                class="w-100"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group :invalid-feedback="errors[0]" label="Last Name" label-class="required-asterisk">
                  <b-form-input
                    type="text"
                    v-model="primary_contact.last_name"
                    id="Middle Name"
                    :state="errors[0] ? false : null"
                    :disabled="disableForm"
                  />
                </b-form-group>
              </ValidationProvider>
            </div>

            <ValidationProvider vid="primary_title" name="Title" rules="required" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" label="Title" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  id="Title"
                  v-model="primary_contact.title"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Primary Office Telephone"
              name="Primary Office Telephone"
              rules="required|length:14"
              v-slot="{ errors }"
            >
              <b-form-group :invalid-feedback="errors[0]" label="Office Telephone" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  id="Primary Office Telephone"
                  v-model="officePhone"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Primary Direct Telephone"
              name="Direct Telephone"
              rules="required|length:14"
              v-slot="{ errors }"
            >
              <b-form-group :invalid-feedback="errors[0]" label="Direct Telephone" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  id="Direct Telephone"
                  v-model="directPhone"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Primary Mobile Telephone"
              name="Mobile Telephone"
              rules="required|length:14"
              v-slot="{ errors }"
            >
              <b-form-group :invalid-feedback="errors[0]" label="Mobile Telephone" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  v-model="mobilePhone"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Primary Email" name="Email" rules="required|email" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" label="Email" label-class="required-asterisk">
                <b-form-input
                  type="text"
                  v-model="primary_contact.email"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                />
              </b-form-group>
            </ValidationProvider>

            <b-form-group label="Domain">
              <b-form-input type="text" id="domain" :value="userCompany.domain" readonly />
            </b-form-group>

            <ValidationProvider
              vid="Principal of the company"
              name="Principal of the company"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                label="Are you a Principal of the company?"
                label-class="required-asterisk"
              >
                <b-form-radio-group
                  v-model="primary_contact.is_principal_firm"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                  style="padding: 10px 0"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="CRD Number"
              name="Primary Contact CRD Number"
              :rules="primary_contact.is_principal_firm ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                label="If yes, please enter your CRD number"
                label-class="required-asterisk"
              >
                <b-form-input
                  type="text"
                  id="CRD Number"
                  v-model="primary_contact.crd_number"
                  :state="errors[0] ? false : null"
                  :disabled="primary_contact.is_principal_firm === false || disableForm"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- save button -->
        <b-row class="form-footer">
          <b-col>
            <b-button type="submit" variant="primary" class="float-right" :disabled="!isAdmin"> Save </b-button>
            <b-button variant="primary" class="float-right mr-2" to="billing-information"> Next </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { pickBy, identity } from 'lodash'

export default {
  name: 'GeneralInformationView',
  data: () => ({
    company_name: '',
    primary_contact: {
      email: '',
      title: '',
      last_name: '',
      crd_number: null,
      first_name: '',
      is_cfo_firm: null,
      middle_name: '',
      direct_phone: '',
      mobile_phone: '',
      is_principal_firm: false,
      main_office_phone: '',
    },
    address: {
      city: '',
      state: '',
      country: '',
      address_1: '',
      address_2: '',
      address_3: '',
      postal_code: '',
      billing_phone: '',
    },
  }),
  computed: {
    status() {
      return this.$store.state.company.status
    },
    userCompany() {
      return this.$store.state.company.userCompany || {}
    },
    disableForm() {
      return this.status === 'loading'
    },
    addressBillingPhone: {
      get() {
        return this.formatPhoneNumber(this.address.billing_phone)
      },
      set(value) {
        this.address.billing_phone = value.replace(/[^\d.]/g, '')
      },
    },
    officePhone: {
      get() {
        return this.formatPhoneNumber(this.primary_contact.main_office_phone)
      },
      set(value) {
        this.primary_contact.main_office_phone = value.replace(/[^\d.]/g, '')
      },
    },
    directPhone: {
      get() {
        return this.formatPhoneNumber(this.primary_contact.direct_phone)
      },
      set(value) {
        this.primary_contact.direct_phone = value.replace(/[^\d.]/g, '')
      },
    },
    mobilePhone: {
      get() {
        return this.formatPhoneNumber(this.primary_contact.mobile_phone)
      },
      set(value) {
        this.primary_contact.mobile_phone = value.replace(/[^\d.]/g, '')
      },
    },
  },
  watch: {
    userCompany: {
      handler() {
        this.company_name = this.userCompany.company_name
        this.primary_contact = { ...this.primary_contact, ...pickBy(this.userCompany.primary_contact, identity) }
        this.address = { ...this.address, ...pickBy(this.userCompany.address, identity) }
      },
      deep: true,
    },
    'primary_contact.is_principal_firm': function (val) {
      if (val === false) {
        this.primary_contact.crd_number = null
      }
    },
    primary_contact: {
      handler() {
        this.$store.dispatch('company/setPrimaryContact', {
          company_name: this.company_name,
          ...this.primary_contact,
          address: {
            ...this.address,
          },
        })
      },
      deep: true,
    },
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return phoneNumber
      }
      return '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
    },
    async saveGeneralInfo() {
      this.$store.dispatch('company/setStatus', { status: 'loading' })
      try {
        const response = await this.$api.put(`/companies/${this.company.id}`, {
          data: {
            company_name: this.company_name,
            primary_contact: pickBy(this.primary_contact, identity),
            address: pickBy(this.address, identity),
          },
        })
        if (response.status === 200) {
          this.$store.dispatch('company/setStatus', { status: 'updateSuccess' })
        }
      } catch (error) {
        console.log('submit-error', error)
        this.$store.dispatch('company/setStatus', {
          status: 'error',
          message: error?.response?.data?.error?.message || 'Something went wrong',
        })
      }
    },
  },
}
</script>

<style scoped>
.form-footer {
  margin-top: 10px;
  border-top: 1px solid #e9ecef;
  padding-top: 1.25rem;
}
</style>
