<template>
  <div class="h-100">
    <vue-country-code
      class="h-100"
      @onSelect="onSelect"
      defaultCountry="us"
      :onlyCountries="countries"
      enabledCountryCode
    >
    </vue-country-code>
  </div>
</template>

<script>
export default {
  name: 'CountrySelect',
  props: {
    countries: {
      type: Array,
      default: () => ['in', 'us', 'mm', 'vn'],
    },
  },
  methods: {
    onSelect({ _name, _iso2, dialCode }) {
      this.$emit('onSelectCountry', dialCode)
    },
  },
}
</script>

<style>
.vue-country-select .country-code {
  margin-left: 5px;
}
</style>
