<template>
  <a :class="classes" v-b-tooltip.hover :title="title" v-on="$listeners" style="cursor: pointer">
    <i :class="icon"></i>
  </a>
</template>

<script>
export default {
  name: 'TableButton',
  props: {
    title: {
      type: String,
      default: 'Delete',
    },
    icon: {
      type: String,
      default: 'ri-delete-bin-line',
    },
    variant: {
      type: String,
      default: 'danger',
    },
  },
  computed: {
    classes() {
      return `iq-bg-${this.variant}`
    },
  },
}
</script>
