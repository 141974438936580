export default {
  namespaced: true,
  state: {
    prefillParams: {},
    previewFormInput: [],
    selectedRows: [],
    triggerFetch: false,
  },
  getters: {
    getPreviewPrefillParams: (state) => {
      return state.prefillParams
    },
    getInput: (state) => {
      return state.previewFormInput
    },
    getSelectedRows: (state) => {
      return state.selectedRows
    },
  },
  mutations: {
    SET_PREVIEW_PREFILL_PARAMS: (state, payload) => {
      state.prefillParams = payload
    },
    ADD_INPUT: (state, payload) => {
      const index = state.previewFormInput.findIndex((item) => item.item_number === payload.item_number)
      if (index === -1) {
        state.previewFormInput.push(payload)
      } else {
        payload.item_value === '' ? state.previewFormInput.splice(index, 1) : (state.previewFormInput[index] = payload)
      }
    },
    REMOVE_INPUT: (state, payload) => {
      const index = state.previewFormInput.findIndex((item) => item.item_number === payload.item_number)
      if (index !== -1) {
        state.previewFormInput.splice(index, 1)
      } else {
        state.previewFormInput.push({
          item_number: `${payload.item_number}-false`,
          item_value: 'X',
        })
      }
    },
    CLEAR_INPUT: (state) => {
      state.previewFormInput = []
    },
    SET_SELECTED_ROWS: (state, payload) => {
      state.selectedRows = payload
    },
    SET_TRIGGER_FETCH: (state, payload) => {
      state.triggerFetch = payload
    },
  },
  actions: {
    setPreviewPrefillParams: (context, payload) => {
      context.commit('SET_PREVIEW_PREFILL_PARAMS', payload)
    },
    addInput: (context, payload) => {
      context.commit('ADD_INPUT', payload)
    },
    removeInput: (context, payload) => {
      context.commit('REMOVE_INPUT', payload)
    },
    clearInput: (context) => {
      context.commit('CLEAR_INPUT')
    },
    setSelectedRows: (context, payload) => {
      context.commit('SET_SELECTED_ROWS', payload)
    },
    setTriggerFetch: (context, payload) => {
      context.commit('SET_TRIGGER_FETCH', payload)
    },
  },
}
