export const fields = [
  {
    key: 'checkbox',
    label: '',
    listTable: false,
    editTable: true,
    sortable: true,
    size: '50px',
  },
  {
    key: 'id',
    label: '',
    listTable: false,
    editTable: false,
    sortable: true,
    size: '50px',
  },
  {
    key: 'account_name',
    label: 'Account Name',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '150px',
  },
  {
    key: 'account_number',
    label: 'Account Number',
    listTable: true,
    editTable: true,
    sortable: true,
    size: '100px',
    tdClass: 'text-center',
  },
  // {
  //   key: 'status',
  //   label: 'Status',
  //   listTable: true,
  //   editTable: true,
  //   sortable: true,
  //   size: '70px',
  // },
  {
    key: 'allowable',
    label: 'Allowable/ Non-Allowable',
    listTable: true,
    editTable: true,
    sortable: true,
    formatter: (value) => {
      return value ? 'Allowable' : 'Non-Allowable'
    },
    size: '150px',
    tdClass: 'text-center',
  },
  {
    key: 'focus_setting',
    label: 'FOCUS ID',
    listTable: true,
    editTable: true,
    sortable: true,
    formatter: (value) => {
      return value?.focus_id ?? ''
    },
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'ssoi_setting',
    label: 'SSOI ID',
    listTable: true,
    editTable: true,
    sortable: true,
    formatter: (value) => {
      return value?.ssoi_id ?? ''
    },
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'action',
    label: '',
    listTable: false,
    editTable: true,
    sortable: false,
    size: '70px',
  },
]
