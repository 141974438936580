<template>
  <div>
    <b-modal id="coa-review-modal" title="Chart of Accounts" size="xl" scrollable @hidden="resetModal">
      <b-tabs content-class="mt-3">
        <b-tab title="Edited" active>
          <TheTable
            small
            fixed
            caption="Edited Records"
            caption-top
            :records="editedRecords"
            :fields="columns"
            :loading="isLoading"
          >
          </TheTable>
          <TheTable
            small
            fixed
            caption="Original Records"
            caption-top
            :records="oldRecords"
            :fields="columns"
            :loading="isLoading"
          >
          </TheTable>
        </b-tab>
        <b-tab title="New">
          <TheTable small fixed :records="createdRecords" :fields="columns" :loading="isLoading"> </TheTable>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <div class="d-flex justify-content-end align-items-center">
          <b-button type="button" variant="secondary" @click="$bvModal.hide('coa-review-modal')"> Close </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'COAReviewModal',
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    createdRecords: [],
    oldRecords: [],
    editedRecords: [],
    coa_reviews_columns: [
      {
        id: 1,
        key: 'id',
        label: 'ID',
        viewRecord: false,
      },
      {
        id: 2,
        key: 'account_number',
        label: 'ACCOUNT NUMBER',
        viewRecord: true,
        sortable: true,
      },
      {
        id: 3,
        key: 'account_name',
        label: 'ACCOUNT NAME',
        viewRecord: true,
        sortable: true,
      },
      {
        id: 4,
        key: 'account_type',
        label: 'ACCOUNT TYPE',
        viewRecord: true,
        sortable: true,
      },
      {
        id: 5,
        key: 'allowable',
        label: 'ALLOWABLE',
        viewRecord: true,
        sortable: true,
        formatter: (value) => {
          return value ? 'Allowable' : 'Not Allowable'
        },
      },
      {
        id: 6,
        key: 'created_at',
        label: 'CREATED DATE',
        viewRecord: false,
      },
      {
        id: 7,
        key: 'updated_at',
        label: 'UPDATED DATE',
        viewRecord: false,
      },
      {
        id: 8,
        key: 'published_at',
        label: 'PUBLISHED DATE',
        viewRecord: false,
      },
      {
        id: 9,
        key: 'focus_setting',
        label: 'FOCUS ID',
        viewRecord: true,
        formatter: (value, key, item) => {
          return item.focus_setting ? item.focus_setting.focus_id : null
        },
      },
      {
        id: 10,
        key: 'ssoi_setting',
        label: 'SSOI ID',
        viewRecord: true,
        formatter: (value, key, item) => {
          return item.ssoi_setting ? item.ssoi_setting.ssoi_id : null
        },
      },
    ],
    currentPage: 1,
    rows: 0,
    perPage: 50,
    fetchStatus: 'idle',
    sort: {
      sortBy: 'name',
      sortDesc: false,
    },
  }),
  computed: {
    columns() {
      return this.coa_reviews_columns.filter((column) => column.viewRecord)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModalList(this.id)
      }
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setLoadingDesc: 'loader/setDescription',
    }),
    async getModalList(id) {
      try {
        const response = await this.$api.get(`/review-requests/contents/${id}?company_id=${this.company.id}`)
        console.log(response)
        this.createdRecords = response.data.add
        this.editedRecords = response.data.edit.map((record) => record.new)
        this.oldRecords = response.data.edit.map((record) => record.old)
      } catch (error) {
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    resetModal() {
      this.createdRecords = []
      this.oldRecords = []
      this.editedRecords = []
    },
  },
}
</script>
