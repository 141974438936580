<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-3">
        <div class="iq-menu-bt d-flex align-items-center">
          <div class="wrapper-menu open" @click="miniSidebar">
            <div class="main-circle">
              <i class="ri-menu-line"> </i>
            </div>
            <div class="hover-circle">
              <i class="ri-menu-line"> </i>
            </div>
          </div>
        </div>

        <router-link :to="homeURL" class="header-logo">
          <span style="font-size: 20px; text-transform: none">{{ title }}</span>
        </router-link>
        <slot name="left" />
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight" />
        </b-collapse>
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>

<script>
import List from '../menus/ListStyle1'

export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'layout1.dashboard' }) },
    title: { type: String, default: 'Dashboard' },
    items: { type: Array },
  },
  mounted() {
    document.addEventListener('click', this.closeSearch, true)
  },
  components: {
    List,
  },
  methods: {
    miniSidebar() {
      this.$emit('toggle')
    },
  },
}
</script>
