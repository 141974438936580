<template>
  <section class="sign-in">
    <b-row class="no-gutters sign-in-wrapper">
      <div class="sign-in-efinop-logo">
        <img :src="logo" alt="background" width="60" height="60" />
        <span>eFINOP</span>
      </div>
      <div class="sign-in-view">
        <router-view></router-view>
      </div>
    </b-row>
  </section>
</template>

<script>
import logo from '../assets/images/logo.png'
import { findash } from '@/plugins/findash'
import bgImage from '../assets/images/login/login-2.jpg'

export default {
  name: 'PublicLayout',
  mounted() {
    findash.index()
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
      },
      logo: logo,
      bgImageURL: bgImage,
    }
  },
}
</script>

<style scoped>
.sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-in-efinop-logo {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.5rem;
}

.sign-in-efinop-logo span {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1d3765;
}

.sign-in-wrapper {
  max-width: 24rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.sign-in-view {
  width: 24rem;
}
</style>
