<template>
  <ValidationProvider name="main_coa" v-slot="{ errors }">
    <b-form-group
      label="Chart Of Account (Account Number)"
      label-class="required-asterisk"
      label-for="coa"
      invalid-feedback="Chart Of Account is required"
    >
      <div class="d-flex" style="gap: 3px">
        <div class="autocomplete-container">
          <autocomplete
            :search="fetchAPI"
            :get-result-value="getResultValue"
            :default-value="defaultValue"
            :debounce-time="500"
            @submit="onSelect"
            placeholder="Search Chart of Account"
            ref="autocomplete"
          >
          </autocomplete>
        </div>
        <!-- clear button -->
        <b-button class="clear-button" @click="clearCOA">Clear</b-button>
      </div>
      <b-form-invalid-feedback :state="errors[0] ? false : null">
        Chart Of Account is required
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'FormModalCoaListAutoComplete',
  props: {
    coa: {
      type: Object,
      default: null,
    },
  },
  computed: {
    defaultValue() {
      return this.coa ? `${this.coa.account_number} - ${this.coa.account_name}` : ''
    },
  },
  methods: {
    fetchAPI(query = '') {
      return this.$api
        .get(`/coas?company_id=${this.company.id}`, {
          params: {
            populate: ['focus_setting', 'ssoi_setting'],
            filters: {
              account_number: {
                $contains: query,
              },
            },
            pagination: { pageSize: 10 },
          },
        })
        .then((response) => {
          return response.data.data.map((item) => item)
        })
        .catch((error) => {
          console.log(error)
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
    },
    getCOAs(query) {
      return this.$api
        .get(`/trial-balances?company_id=${this.company.id}`, {
          params: {
            populate: ['coa', 'coa.focus_setting', 'coa.ssoi_setting'],
            filters: {
              coa: {
                account_number: {
                  $contains: query,
                },
              },
            },
            pagination: { pageSize: 10 },
          },
        })
        .then((response) => {
          return response.data.data?.map((item) => {
            return {
              id: item?.id,
              time_report: item?.time_report,
              description: item?.description,
              credit: item?.credit,
              debit: item?.debit,
              coa: item?.coa,
            }
          })
        })
        .catch((error) => {
          console.log(error)
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
    },
    onSelect(coa) {
      this.$api
        .get(`/trial-balances?company_id=${this.company.id}`, {
          params: {
            populate: ['coa', 'coa.focus_setting', 'coa.ssoi_setting'],
            filters: {
              coa: {
                account_number: {
                  $contains: coa.account_number,
                },
              },
            },
            pagination: { pageSize: 10 },
          },
        })
        .then((response) => {
          // console.log(response)
          if (response.data.data.length === 0) {
            this.$emit('onSelect', {
              id: '',
              time_report: '',
              description: '',
              credit: 0,
              debit: 0,
              coa: coa,
            })
            return
          }
          const item = response.data.data?.map((item) => {
            return {
              id: item?.id,
              time_report: item?.time_report,
              description: item?.description,
              credit: item?.credit,
              debit: item?.debit,
              coa: item?.coa,
            }
          })[0]
          this.$emit('onSelect', item)
        })
        .catch((error) => {
          console.log(error)
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
    },
    clearCOA() {
      this.$emit('onClear')
      this.$refs.autocomplete.value = ''
    },
    getResultValue(result) {
      return `${result.account_number} - ${result.account_name}`
    },
  },
}
</script>
