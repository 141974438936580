import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('confirmed', {
  validate(value, { confirmed }) {
    return value == confirmed
  },
  params: ['confirmed'],
  message: 'Repeat password should be match with password',
})

// extend('required', {
//   ...rules.required,
//   message: 'This field is required',
// })

extend('csv', {
  validate(value) {
    return value.type === 'text/csv' && value.name.endsWith('.csv')
  },
  message: 'File should be a csv',
})

extend('monthpicker', {
  validate(value) {
    return value.length === 7
  },
})

extend('custom-password', {
  validate(value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/.test(value)
  },
  message: 'Password must have at least 6 characters, 1 number, 1 special character.',
})

extend('min1', {
  validate(value) {
    return value >= 1
  },
  message: 'This field is required',
})

extend('validCurrencyFormat', {
  validate(value) {
    return /^(\()?\d{1,3}(,\d{3})*(\.\d{2})?(\))?$/.test(value)
  },
  message: 'This field must be a valid currency format',
})

extend('isCoaSelected', {
  validate(value) {
    return value && value !== 'No Chart Of Account Selected'
  },
  message: 'This field is required',
})

extend('noNaN', {
  validate(value) {
    return !isNaN(value)
  },
  message: 'This field must be numeric',
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
