<template>
  <b-container fluid>
    <UserProfileAddEmailModal />
    <iq-card>
      <template v-slot:headerTitle>
        <div class="d-flex align-items-center">
          <img :src="require('../../assets/images/user/user.svg')" alt="icon" />
          <h4 class="card-title">
            <span class="text-primary font-size-16 ml-3">User Profile Information</span>
          </h4>
        </div>
      </template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit, reset }">
          <form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
            <b-row>
              <b-col lg="3" class="text-md-center">
                <label class="mt-lg-4">Profile Image</label><br />
                <b-avatar :src="unChangedFields.avatar" size="15rem" rounded class="mb-3"></b-avatar>
                <br v-if="!!unChangedFields.avatar" />
                <b-form-file
                  id="my-file-selector"
                  ref="avatar"
                  type="file"
                  accept="image/*"
                  @change="uploadAvatar"
                  v-model="avatar"
                  class="d-none mt-3"
                />
                <div>
                  <b-overlay
                    class="d-inline-block ml-0 ml-sm-2 mt-2 mt-sm-0"
                    :show="isSubmitting"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                  >
                    <b-button variant="primary" for="my-file-selector" @click="showImageUpload">
                      <i class="ri-camera-line"></i>
                      <span class="font-weight-bold"> Upload</span>
                    </b-button>
                  </b-overlay>
                </div>
              </b-col>

              <b-col lg="9">
                <ValidationProvider class="col-sm-12" name="Username" rules="required" v-slot="{ errors }">
                  <b-form-group
                    class="profile-field mb-0"
                    label="Username"
                    label-class="required-asterisk"
                    invalid-feedback="Username is required"
                    :disabled="isSubmitting"
                  >
                    <b-form-input
                      type="text"
                      v-model="userForm.username"
                      :state="errors[0] ? false : null"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>

                <b-row>
                  <b-form-group class="col-sm-12 col-md-4 profile-field" :disabled="isSubmitting">
                    <label for="first_name">First Name</label>
                    <b-form-input type="text" v-model="userForm.first_name" id="first_name"></b-form-input>
                  </b-form-group>

                  <b-form-group class="col-sm-12 col-md-4 profile-field" :disabled="isSubmitting">
                    <label for="middle_name">Middle Name or Initial</label>
                    <b-form-input type="text" v-model="userForm.middle_name" id="middle_name"></b-form-input>
                  </b-form-group>

                  <b-form-group class="col-sm-12 col-md-4 profile-field" :disabled="isSubmitting">
                    <label for="last_name">Last Name</label>
                    <b-form-input type="text" v-model="userForm.last_name" id="last_name"></b-form-input>
                  </b-form-group>
                </b-row>

                <b-form-group class="col-sm-12 profile-field px-0">
                  <label for="email">Email List</label>
                  <b-list-group>
                    <b-list-group-item
                      v-for="email in userForm.emails"
                      :key="email.id"
                      v-b-toggle="[email.email_address]"
                    >
                      {{ email.email_address }} <b-icon icon="chevron-down"></b-icon>
                      <b-collapse :id="email.email_address" accordion="my-accordion" role="tabpanel">
                        <b-card no-body header="Company List" class="mt-3 border">
                          <b-list-group flush>
                            <b-list-group-item v-for="company in email.user_company_settings" :key="company.id">
                              {{ company.company_info.company_name }}
                            </b-list-group-item>
                          </b-list-group>
                        </b-card>
                      </b-collapse>
                    </b-list-group-item>
                  </b-list-group>

                  <b-button variant="primary" class="mt-3" v-b-modal.user-email-form-modal>
                    <i class="ri-add-line"></i>
                    <span class="font-weight-bold"> Register More Email </span>
                  </b-button>
                </b-form-group>

                <b-form-group class="col-sm-12 profile-field px-0" :disabled="isSubmitting">
                  <label for="office_phone_number">Office Telephone</label>
                  <b-form-input
                    type="text"
                    :value="formatPhoneNumber(userForm.office_phone_number)"
                    @blur="(e) => (userForm.office_phone_number = e.target.value)"
                    id="office_phone_number"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 profile-field px-0" :disabled="isSubmitting">
                  <label for="direct_phone_number">Direct Telephone</label>
                  <b-form-input
                    type="text"
                    :value="formatPhoneNumber(userForm.direct_phone_number)"
                    @blur="(e) => (userForm.direct_phone_number = e.target.value)"
                    id="direct_phone_number"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 profile-field px-0" :disabled="isSubmitting">
                  <label for="mobile_telephone">Mobile Telephone </label>
                  <b-form-input
                    type="text"
                    v-model="unChangedFields.phone_number"
                    id="mobile_telephone"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-group
                    label="Are you an outsourced FINOP?"
                    label-class="required-asterisk"
                    invalid-feedback="This field is required"
                    :disabled="isSubmitting"
                  >
                    <b-form-radio-group
                      v-model="user.outsourced_finop"
                      :options="[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      :state="errors[0] ? false : null"
                    >
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors[0] ? false : null">
                      Please select one
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>

                <b-form-group class="col-sm-12 profile-field px-0" :disabled="isSubmitting">
                  <label for="crd_number">If yes, please enter your CRD number</label>
                  <b-form-input type="text" v-model="user.crd_number" id="crd_number"></b-form-input>
                </b-form-group>

                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-group
                    label="Do you manage or review the accounting and FOCUS
                              preparation for other more than one
                              company?"
                    label-class="required-asterisk"
                    :disabled="isSubmitting"
                  >
                    <b-form-radio-group
                      v-model="user.manage_review_accounting_focus"
                      :options="[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      :state="errors[0] ? false : null"
                    >
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors[0] ? false : null">
                      Please select one
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row class="form-footer">
              <div class="ml-auto mr-3">
                <b-button type="submit" variant="primary"> Save </b-button>
              </div>
            </b-row>
          </form>
        </ValidationObserver>
      </template>
    </iq-card>
    <router-view></router-view>
  </b-container>
</template>

<script>
import { uploadFile } from '@/utils/api'
import { mapActions } from 'vuex'
import UserProfileAddEmailModal from '../../components/Setting/UserProfileAddEmailModal.vue'

export default {
  name: 'UserProfile',
  data() {
    return {
      userForm: {
        username: '',
        first_name: '',
        office_phone_number: '',
        middle_name: '',
        direct_phone_number: '',
        last_name: '',
        outsourced_finop: '',
        crd_number: '',
        manage_review_accounting_focus: '',
        emails: [],
      },
      unChangedFields: {
        id: '',
        avatar: '',
        email: '',
        phone_number: '',
      },
      avatar: null,
      isSubmitting: false,
    }
  },
  components: { UserProfileAddEmailModal },
  created() {
    this.getUserProfileData()
  },
  methods: {
    ...mapActions('auth', ['setAvatar']),
    showImageUpload() {
      this.$refs.avatar.$el.children[0].click()
    },
    uploadAvatar(event) {
      this.isSubmitting = true
      this.unChangedFields.avatar = URL.createObjectURL(event.target.files[0])
      const form = new FormData()
      form.append('files', event.target.files[0])
      uploadFile(form, 'UPLOAD_USER_AVATAR')
        .then((res) => {
          this.$showSuccess('User Profile Image has been updated successfully.')
          this.setAvatar(res.data?.user?.avatar)
        })
        .catch((error) => {
          const Errors = (Array.isArray(error.response?.data?.error?.details) &&
            error.response?.data?.error?.details) || [{ message: error.response?.data?.error?.message }] || [
              { message: 'Unable to update user profile image.' },
            ]
          Errors.forEach((error) => {
            this.$showError(error.message)
          })
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    onSubmit() {
      this.isSubmitting = true
      this.$api
        .post('/me', {
          data: {
            ...this.userForm,
          },
        })
        .then((res) => {
          this.$showSuccess('User Profile has been updated successfully.')
        })
        .catch((error) => {
          const Errors = (Array.isArray(error.response?.data?.error?.details) &&
            error.response?.data?.error?.details) || [{ message: error.response?.data?.error?.message }] || [
              { message: 'Unable to update user profile data.' },
            ]
          Errors.forEach((error) => {
            this.$showError(error.message)
          })
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    getUserProfileData() {
      if (!this.user) return
      const {
        id,
        avatar,
        username,
        first_name,
        emails,
        office_phone_number,
        middle_name,
        direct_phone_number,
        last_name,
        phone_number,
        outsourced_finop,
        crd_number,
        manage_review_accounting_focus,
      } = this.user
      this.userForm = {
        username,
        first_name,
        emails,
        office_phone_number,
        middle_name,
        direct_phone_number,
        last_name,
        outsourced_finop,
        crd_number,
        manage_review_accounting_focus,
      }
      this.unChangedFields = {
        id,
        avatar: avatar && avatar.url ? avatar.url : '',
        phone_number,
      }
    },
    resetForm() {
      this.userForm = {
        username: '',
        first_name: '',
        office_phone_number: '',
        middle_name: '',
        direct_phone_number: '',
        last_name: '',
        outsourced_finop: '',
        crd_number: '',
        manage_review_accounting_focus: '',
      }
    },
    reloadForm() {
      this.getUserProfileData()
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return phoneNumber
      }
      return '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6, 4)
    },
    addEmail() {},
  },
}
</script>

<style>
.form-footer {
  margin-top: 10px;
  border-top: 1px solid #e9ecef;
  padding-top: 1.25rem;
}
</style>
