import moment from 'moment'
// PDF
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const numberFormatter = (value, decimalPlace = 2) => {
  if (typeof value === 'string') {
    value = value.replace(/,/g, '')
  }
  if (value === null) return null
  const isNegative = value < 0
  const absoluteNum = Math.abs(value)
  const formattedNum = Number(absoluteNum)
    .toFixed(decimalPlace)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return isNegative ? `(${formattedNum.toString()})` : formattedNum
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function resolveColumns(columns, record = {}, metaData = {}, locationState) {
  const schema = typeof columns === 'function' ? columns(record, metaData) : columns
  const section = (schema && typeof schema === 'object' && schema.section) || false
  const sectionColumns =
    (section &&
      locationState &&
      locationState.schemaId &&
      section.find((_) => _.schemaId === locationState.schemaId)) ||
    false

  return (sectionColumns && sectionColumns.columns) || (schema && schema.columns) || schema
}

export function dateFormatter(value) {
  return value ? moment.utc(value).format('MM/DD/YYYY') : ''
}

export function manageReportFiles(reportData) {
  const {
    data: { docDefinition },
  } = reportData

  const table = docDefinition.content.find((ob) => ob.table)
  let layout
  if (table) {
    try {
      eval(`layout = ${table.layout}`)
    } catch (error) {
      eval(`layout = "${table.layout}"`)
    }
    table.layout = layout
  }

  return pdfMake.createPdf(docDefinition)
}

export function downloadFile(url) {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', '')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
