<template>
  <div class="card">
    <div class="row g-0">
      <div class="col-12">
        <div v-if="isLoading">
          <div class="text-center my-2">
            <b-spinner variant="primary"></b-spinner>
            <div class="mt-2">Loading conversation...</div>
          </div>
        </div>
        <div v-if="!isLoading && records.length > 0" class="position-relative mb-4" style="background-color: #f0f2f5">
          <div class="chat-messages p-4">
            <div
              v-for="item in records"
              :key="item.id"
              :class="[
                user.id === item.user_company_setting.user_email.efinop_user.id
                  ? 'chat-message-right'
                  : 'chat-message-left',
                'pb-4',
              ]"
            >
              <b-avatar
                class="mt-1"
                variant="primary"
                :src="getAvatar(item.user_company_setting.user_email.efinop_user.avatar)"
              ></b-avatar>
              <div
                :class="[
                  user.id === item.user_company_setting.user_email.efinop_user.id
                    ? 'mr-3 text-right'
                    : 'text-left ml-3',
                ]"
              >
                <div
                  class="flex-shrink-1 rounded py-2 px-3"
                  :class="[user.id === item.user_company_setting.user_email.efinop_user.id ? 'text-white' : 'bg-light']"
                  :style="{
                    backgroundColor:
                      user.id === item.user_company_setting.user_email.efinop_user.id ? '#3071b6' : '#f0f2f5',
                  }"
                >
                  <div class="font-weight-bold mb-1">
                    {{
                      fullNameFormat(
                        item.user_company_setting.user_email.efinop_user.first_name,
                        item.user_company_setting.user_email.efinop_user.last_name,
                      )
                    }}
                  </div>
                  {{ item.comment }}
                </div>
                <span>{{ dateFormat(item.createdAt, 'DD/MM/YY hh:mm:ss') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b-input-group>
            <template #append>
              <b-button variant="primary" type="button" @click="handleComments('create')" :disabled="isSending">
                <i class="fas fa-paper-plane"></i>
              </b-button>
            </template>
            <b-form-input
              v-model="form.comment"
              @keyup.enter.stop="handleComments('create')"
              :disabled="isSending"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateNameFormat from '@/mixins/date-name-format-mixins'

export default {
  mixins: [dateNameFormat],
  name: 'RequestsReviewConversation',
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      coaReviewId: null,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      records: [],
      form: {
        comment: '',
        editedComment: '',
        commentId: null,
      },
      comments_columns: [
        {
          id: 1,
          key: 'id',
          label: '#',
          editRecord: false,
          viewRecord: false,
          type: 'text',
        },
        {
          id: 2,
          key: 'comment',
          label: 'COMMENT',
          editRecord: true,
          viewRecord: true,
          type: 'textarea',
          rows: 3,
          maxRows: 4,
          placeholder: 'Enter the comment...',
        },
        {
          id: 3,
          key: 'created_at',
          label: 'CREATED DATE',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 4,
          key: 'updated_at',
          label: 'UPDATED DATE',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 5,
          key: 'published_at',
          label: 'PUBLISHED DATE',
          editRecord: false,
          viewRecord: false,
          type: 'text',
        },
      ],
      commentEdit: false,
      fetchStatus: 'idle',
    }
  },
  computed: {
    columns() {
      return this.comments_columns.filter((item) => item.editRecord)
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    isLoading() {
      return this.fetchStatus === 'loading'
    },
    isSending() {
      return this.fetchStatus === 'sending'
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getCommentsList(this.id)
      }
    },
  },
  methods: {
    getCommentsList(id) {
      this.fetchStatus = 'loading'
      if (id) this.coaReviewId = id

      const params = {
        params: {
          pagination: {
            pageSize: this.perPage,
            page: this.currentPage,
          },
          populate: [
            'user_company_setting',
            'user_company_setting.user_email',
            'user_company_setting.user_email.efinop_user',
          ],
          sort: ['id:asc'],
          filters: {
            review_request: {
              id,
            },
          },
          company_id: this.company.id,
        },
      }

      this.$api
        .get('/review-comments', params)
        .then((res) => {
          this.records = res.data.data || []
          const { page, pageCount, pageSize, total } = res.data.pagination
          this.currentPage = page
          this.rows = total
          this.fetchStatus = 'success'
        })
        .catch((err) => {
          this.fetchStatus = 'error'
          this.$showError(err.response.data.error.message || 'Unable to fetch api records')
        })
    },
    handleComments(type) {
      this.editComment = false
      this.fetchStatus = 'sending'
      const method = type === 'create' ? 'post' : 'put'
      const payload =
        type === 'create'
          ? {
              comment: this.form.comment,
              reviewRequestId: this.coaReviewId,
            }
          : {
              commentId: this.form.commentId,
              comment: this.form.editedComment,
            }
      this.$api[method](`review-comments?company_id=${this.company.id}`, {
        data: payload,
      })
        .then((res) => {
          this.form = {
            comment: '',
            commentId: null,
          }
          this.getCommentsList(this.id)
          this.$showSuccess('Successfully added the comment')
          this.fetchStatus = 'success'
        })
        .catch((err) => {
          this.fetchStatus = 'error'
          this.$showError(err.response.data.error.message || 'Unable to perform the action')
        })
    },
    deleteComment(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this comment?', {
          title: 'Delete Comment',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$api
              .delete(`review-comments?id=${id}&company_id=${this.company.id}`)
              .then(() => {
                this.getCommentsList()
                this.$showSuccess('Successfully deleted the comment')
              })
              .catch((err) => {
                this.$showError(err.response.data.error.message || 'Unable to perform the action')
              })
          }
        })
    },
    resetModal() {
      this.records = []
      this.perPage = 10
      this.currentPage = 1
    },
    handlePagination(page) {
      this.currentPage = page
      this.getCommentsList(this.coaReviewId)
    },
    getAvatar(avatar) {
      return avatar?.formats?.thumbnail?.url || ''
    },
    setForm(data) {
      this.commentEdit = true
      this.form = {
        editedComment: data.comment,
        commentId: data.id,
      }
    },
  },
}
</script>

<style scoped>
.commentBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 25px;
  padding-left: 0px;
}

.commentBox img {
  width: 6 0px;
  height: 60px;
  margin-right: 25px;
}

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}
</style>
