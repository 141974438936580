<template>
  <div>
    <FormModal v-bind="formModalData" @coa-updated="onEdited" @coa-added="onAdded" />
    <ImportModal :user="user" @uploaded="getList" />
    <CardWrapper>
      <template #icon>
        <i class="las la-file-invoice-dollar" style="font-size: 25px"></i>
      </template>
      <template #title>Chart of Accounts Edit</template>
      <template #header>
        <TableActionsWrapper>
          <template #pagination>
            <CommonPerPage :perPage="perPage" @change="handleEntries" />
          </template>

          <template #actions>
            <div>
              <b-button variant="danger" class="ml-1" @click="isDeleteMode = !isDeleteMode">
                <span v-if="isDeleteMode">Exit</span> Delete Mode
              </b-button>
              <template v-if="!isDeleteMode">
                <b-button variant="primary" class="ml-1" @click="downloadSample"> Download COA Template</b-button>
                <b-button variant="primary" class="ml-1" v-b-modal.coa-import-modal data-cy="coa-upload-btn">
                  Import Chart of Accounts</b-button
                >
                <b-button variant="primary" class="ml-1" @click="onAdd"> Add Account</b-button>
                <b-button class="ml-1" variant="primary" @click="onSave" :disabled="modifiedItems.length === 0">
                  Save
                </b-button>
                <b-button variant="primary" class="ml-1" @click="onMarkDone"> Mark Done</b-button>
              </template>
              <template v-else>
                <b-button variant="primary" class="ml-1" @click="saveDeleteItems" :disabled="deleteItems.length === 0">
                  Confirm Delete
                </b-button>
              </template>
            </div>
          </template>
        </TableActionsWrapper>
      </template>
      <template #body>
        <TheTable
          ref="table"
          :records="listItems"
          :fields="editTableFields"
          :loading="isLoading"
          :sort-by="sort.sortBy"
          :sort-desc="sort.sortDesc"
          @sort-changed="handleSortChange"
          selectable
          @row-selected="onRowSelected"
          no-local-sorting
          data-cy="coa-table"
        >
          <template #head(checkbox)="data">
            <b-form-checkbox @change="(e) => toggleSelectAll(e, data)"></b-form-checkbox>
          </template>
          <template #cell(checkbox)="{ item }">
            <b-form-checkbox v-model="deleteItems" :value="item"></b-form-checkbox>
          </template>
          <template #cell(action)="{ item }">
            <div class="d-flex flex-wrap justify-content-center" style="gap: 5px">
              <b-button v-if="!item.is_deleted" size="sm" variant="primary" @click="onEdit(item)"> Edit</b-button>
              <b-button v-if="canRevert(item)" class="ml-2" size="sm" variant="warning" @click="revertItem(item)">
                Revert
              </b-button>
              <b-button size="sm" class="ml-2" v-if="item.valid_at === null" variant="warning" @click="onRestore(item)">
                Restore
              </b-button>
            </div>
          </template>
        </TheTable>
        <TablePagination
          :currentPage="currentPage"
          :rows="rows"
          :perPage="perPage"
          @pagination="handlePagination"
          @entries="handleEntries"
        />
      </template>
    </CardWrapper>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormModal from '@/components/ChartOfAccounts/FormModal.vue'
import ImportModal from '@/components/ChartOfAccounts/ImportModal.vue'
import { fields } from './fields'
import { downloadFile } from '@/utils/helpers'

export default {
  name: 'COAEdit',
  components: {
    FormModal,
    ImportModal,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSavedItemsNotMarkedDone) {
      this.$bvModal
        .msgBoxConfirm('You haven’t mark as done your changes. Admin will not be able to see your changes. Proceed?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Leave',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  data() {
    return {
      isDeleteMode: false,
      listItems: [],
      deleteItems: [],
      modifiedItems: [],
      originalItems: [],
      editForm: null,
      mode: 'add',
      currentPage: 1,
      rows: 0,
      perPage: 50,
      fetchStatus: 'idle',
      sort: {
        sortBy: 'account_number',
        sortDesc: false,
      },
    }
  },
  computed: {
    editTableFields() {
      return this.isDeleteMode
        ? fields.filter((_) => _.editTable && _.key !== 'action')
        : fields.filter((_) => _.editTable && _.key !== 'checkbox')
    },
    isSavedItemsNotMarkedDone() {
      return this.listItems.some((item) => item.efinop_user?.id === this.user.id && item.valid_at === null)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
    formModalData() {
      return {
        mode: this.mode,
        id: this.editForm?.id,
        accountName: this.editForm?.account_name,
        accountNumber: this.editForm?.account_number,
        accountType: this.editForm?.account_type,
        focusSetting: this.editForm?.focus_setting,
        ssoiSetting: this.editForm?.ssoi_setting,
      }
    },
  },
  watch: {
    isDeleteMode() {
      this.$refs.table?.$children[0]?.clearSelected()
    },
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setLoadingDesc: 'loader/setDescription',
    }),
    async getList() {
      try {
        this.fetchStatus = 'pending'
        let sortBy
        if (this.sort.sortBy === 'focus_setting') {
          sortBy = 'focus_setting.focus_id'
        } else if (this.sort.sortBy === 'ssoi_setting') {
          sortBy = 'ssoi_setting.ssoi_id'
        } else {
          sortBy = this.sort.sortBy
        }
        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && { 'sort[]': `${sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}` }),
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/coas/edit-mode', params)
        if (response.data?.data && response.data?.pagination) {
          this.fetchStatus = 'success'
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.listItems = response.data?.data
          this.listItems.forEach((item) => {
            if (item.efinop_user?.id === this.user.id && item.valid_at === null) {
              switch (item.is_deleted) {
                case null:
                  item._rowVariant = 'warning'
                  break
                case false:
                  item._rowVariant = 'warning'
                  break
                case true:
                  item._rowVariant = 'danger'
                  break
              }
            }
          })
          if (this.modifiedItems.length > 0) {
            this.modifiedItems.forEach((item) => {
              const index = this.listItems.findIndex((listItem) => listItem.id === item.id)
              if (index === -1) {
                this.listItems.unshift({
                  ...item,
                  _rowVariant: 'warning',
                })
              } else {
                this.listItems[index] = {
                  ...this.listItems[index],
                  ...item,
                  _rowVariant: 'warning',
                }
              }
            })
          }
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    onEdit(item) {
      // this.mode = buttonType === 'Clone' ? 'add' : 'edit'
      this.mode = 'edit'
      this.editForm = {
        id: item.id,
        account_name: item.account_name,
        account_number: item.account_number,
        account_type: item.account_type,
        focus_setting: item.focus_setting,
        ssoi_setting: item.ssoi_setting,
      }
      this.$bvModal.show('coa-form-modal')
    },
    onEdited(editedForm) {
      if (!editedForm) return

      const modifiedItemIdx = this.modifiedItems.findIndex((_) => _.id === editedForm.id)
      if (modifiedItemIdx > -1) {
        if (!this.modifiedItems[modifiedItemIdx].valid_at === null) {
          this.modifiedItems[modifiedItemIdx].updatedAt = new Date().toISOString()
        }
        this.modifiedItems[modifiedItemIdx] = {
          ...this.modifiedItems[modifiedItemIdx],
          ...editedForm,
        }
      } else {
        this.modifiedItems.push({
          ...editedForm,
        })
      }

      this.listItems = this.listItems.map((_) => {
        if (_.id === editedForm.id) {
          this.originalItems.push({
            ..._,
          })
          return {
            ..._,
            ...editedForm,
            _rowVariant: 'warning',
          }
        }
        return _
      })
    },
    onAdd() {
      this.mode = 'add'
      this.editForm = null
      this.$bvModal.show('coa-form-modal')
    },
    onAdded(form) {
      if (!form) return
      // this new item might be edited later so need to track it for onEdited function
      form.id = Math.floor(Math.random() * 1000)
      this.modifiedItems.push({
        ...form,
        isNew: true,
        updatedAt: new Date().toISOString(),
      })

      this.listItems.unshift({
        ...form,
        isNew: true,
        _rowVariant: 'warning',
      })
    },
    async onSave() {
      if (this.modifiedItems.length > 0) {
        this.setLoading(true)
        this.setLoadingDesc('Saving Chart of Accounts')
        const data = this.modifiedItems.map((record) => {
          if (record.isNew) {
            return {
              accountName: record.account_name,
              accountNumber: record.account_number,
              accountType: record.account_type,
              focusSetting: record.focus_setting?.id,
              ssoiSetting: record.ssoi_setting?.id,
              updatedAt: new Date(record.updatedAt).toISOString(),
            }
          }
          return {
            id: record.id,
            accountName: record.account_name,
            accountNumber: record.account_number,
            accountType: record.account_type,
            focusSetting: record.focus_setting?.id,
            ssoiSetting: record.ssoi_setting?.id,
          }
        })

        try {
          const response = await this.$api.put(`/coas?company_id=${this.company.id}`, {
            data,
          })

          if (response.status === 200) {
            this.$showSuccess('Records updated successfully.')
            this.modifiedItems = []
            this.getList()
          }
        } catch (error) {
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        } finally {
          this.setLoading(false)
        }
      }
    },
    async onMarkDone() {
      this.setLoading(true)
      this.setLoadingDesc('Marking Chart of Accounts as Done')
      try {
        await this.$api.get(`/coas/mark-done?company_id=${this.company.id}`)
        this.$showSuccess('Records updated successfully.')
        this.getList()
        this.modifiedItems = []
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      } finally {
        this.setLoading(false)
      }
    },
    async downloadSample() {
      this.setLoading(true)
      this.setLoadingDesc('Downloading Template...')
      try {
        const response = await this.$api.get('/sample/download', {
          params: {
            type: 'COA',
            company_id: this.company.id,
          },
        })
        if (response.status === 200 && response.data?.data?.url) {
          this.$showSuccess('Downloaded successfully.')
          downloadFile(response.data.data.url)
        }
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      } finally {
        this.setLoading(false)
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
    onRestore(item) {
      this.$bvModal
        .msgBoxConfirm('This record will be restored. Are you sure?', {
          title: 'Restore',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.setLoading(true)
            this.setLoadingDesc('Restoring record...')
            try {
              const response = await this.$api.put(`/coas?company_id=${this.company.id}`, {
                data: [
                  {
                    id: item.id,
                    accountName: item.account_name,
                    accountNumber: item.account_number,
                    accountType: item.account_type,
                    focusSetting: item.focus_setting?.id,
                    ssoiSetting: item.ssoi_setting?.id,
                    isUndo: true,
                  },
                ],
              })

              if (response.status === 200) {
                this.modifiedItems = this.modifiedItems.filter((record) => record.id !== item.id)
                this.$showSuccess('Record restored successfully.')
                this.getList()
              }
            } catch (error) {
              this.$showError(error.response?.data?.error?.message || 'Something went wrong')
            } finally {
              this.setLoading(false)
            }
          }
        })
    },
    canRevert(item) {
      return this.modifiedItems.findIndex((record) => record.id === item.id) > -1 && item.valid_at !== null
    },
    revertItem(item) {
      const index = this.modifiedItems.findIndex((record) => record.id === item.id)
      if (index > -1) {
        this.modifiedItems.splice(index, 1)
      }
      if (item.isNew) {
        const index = this.listItems.findIndex((record) => record.id === item.id)
        if (index > -1) {
          this.listItems.splice(index, 1)
          return
        }
      }
      const originalItem = this.originalItems.find((record) => record.id === item.id)
      if (originalItem) {
        this.$set(item, 'account_name', originalItem.account_name)
        this.$set(item, 'account_number', originalItem.account_number)
        this.$set(item, 'account_type', originalItem.account_type)
        this.$set(item, 'focus_setting', originalItem.focus_setting)
        this.$set(item, 'ssoi_setting', originalItem.ssoi_setting)
        this.$set(item, '_rowVariant', '')
      }
      this.originalItems = this.originalItems.filter((record) => record.id !== item.id)
    },
    // Delete Mode Functions
    toggleSelectAll(value, data) {
      value ? data.selectAllRows() : data.clearSelected()
    },
    onRowSelected(items) {
      this.deleteItems = items
    },
    saveDeleteItems() {
      this.$bvModal
        .msgBoxConfirm('This records will be saved as deleted. Are you sure?', {
          title: 'Confirm Delete',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.setLoading(true)
            this.setLoadingDesc('Saving deleted record...')

            try {
              const data = this.deleteItems
                .filter((record) => !record.isNew)
                .map((record) => ({
                  id: record.id,
                  accountName: record.account_name,
                  accountNumber: record.account_number,
                  accountType: record.account_type,
                  focusSetting: record.focus_setting?.id,
                  ssoiSetting: record.ssoi_setting?.id,
                  isDeleted: true,
                }))

              if (data?.length > 0) {
                await this.$api.put(`/coas?company_id=${this.company.id}`, {
                  data,
                })
              }
              this.modifiedItems = this.modifiedItems.filter(
                (record) => !this.deleteItems.some((_) => _.id === record.id),
              )
              this.$showSuccess('Record updated successfully.')
              this.deleteItems = []
              this.isDeleteMode = false
              this.getList()
            } catch (error) {
              this.$showError(error.response?.data?.error?.message || 'Something went wrong')
            } finally {
              this.setLoading(false)
            }
          }
        })
    },
  },
}
</script>
