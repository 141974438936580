var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-12"},[(_vm.isLoading)?_c('div',[_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{attrs:{"variant":"primary"}}),_c('div',{staticClass:"mt-2"},[_vm._v("Loading conversation...")])],1)]):_vm._e(),(!_vm.isLoading && _vm.records.length > 0)?_c('div',{staticClass:"position-relative mb-4",staticStyle:{"background-color":"#f0f2f5"}},[_c('div',{staticClass:"chat-messages p-4"},_vm._l((_vm.records),function(item){return _c('div',{key:item.id,class:[
              _vm.user.id === item.user_company_setting.user_email.efinop_user.id
                ? 'chat-message-right'
                : 'chat-message-left',
              'pb-4',
            ]},[_c('b-avatar',{staticClass:"mt-1",attrs:{"variant":"primary","src":_vm.getAvatar(item.user_company_setting.user_email.efinop_user.avatar)}}),_c('div',{class:[
                _vm.user.id === item.user_company_setting.user_email.efinop_user.id
                  ? 'mr-3 text-right'
                  : 'text-left ml-3',
              ]},[_c('div',{staticClass:"flex-shrink-1 rounded py-2 px-3",class:[_vm.user.id === item.user_company_setting.user_email.efinop_user.id ? 'text-white' : 'bg-light'],style:({
                  backgroundColor:
                    _vm.user.id === item.user_company_setting.user_email.efinop_user.id ? '#3071b6' : '#f0f2f5',
                })},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.fullNameFormat( item.user_company_setting.user_email.efinop_user.first_name, item.user_company_setting.user_email.efinop_user.last_name, ))+" ")]),_vm._v(" "+_vm._s(item.comment)+" ")]),_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt, 'DD/MM/YY hh:mm:ss')))])])],1)}),0)]):_vm._e(),_c('div',[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","type":"button","disabled":_vm.isSending},on:{"click":function($event){return _vm.handleComments('create')}}},[_c('i',{staticClass:"fas fa-paper-plane"})])]},proxy:true}])},[_c('b-form-input',{attrs:{"disabled":_vm.isSending},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.handleComments('create')}},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }