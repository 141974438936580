export default {
  setTableLoading(state, payload) {
    state.commit('SET_TABLE_LOADING', payload)
  },
  setPdfPreviewLoading(state, payload) {
    state.commit('SET_PDF_PREVIEW_LOADING', payload)
  },
  setPdf(state, payload) {
    state.commit('SET_PDF', payload)
  },
}
