import { clearAuthToken } from '@/utils/api'

export default {
  SET_AUTH_USER_ID: (state, payload) => {
    state.auth_user_id = payload
  },
  LOGIN_FAILURE: (state) => {
    state.user = null
    state.session = null
    state.avatar = null
    state.accessToken = null
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('username')
    localStorage.removeItem('remember_me')
    localStorage.removeItem('selected_company_id')
    clearAuthToken()
  },
  LOGIN_SUCCESS: (state, user) => {
    state.user = user
    localStorage.setItem('username', user.email?.email_address)
  },
  SET_TOKENS: (state, tokens) => {
    localStorage.setItem('access_token', tokens.access_token || false)
    localStorage.setItem('refresh_token', tokens.refresh_token || false)
    state.accessToken = tokens.access_token || false
  },
  SET_ACCESS_TOKEN: (state, token) => {
    state.accessToken = token
  },
  SET_SESSION: (state, { session, username }) => {
    state.session = {
      session,
      username,
    }
  },
  SET_AVATAR: (state, avatar) => {
    state.user.avatar = avatar
  },
  SET_SELECTED_COMPANY_WITH_SETTING: (state, companySetting) => {
    if (!companySetting) {
      localStorage.removeItem('selected_company_id')
      state.selectedCompany = null
      state.selectedCompanySetting = null
      // location.reload()
      return
    }
    localStorage.setItem('selected_company_id', companySetting?.company_info?.id)
    state.selectedCompany = companySetting.company_info
    state.selectedCompanySetting = companySetting
  },
  SET_SELECTED_COMPANY_SETTING: (state, companySetting) => {
    state.selectedCompanySetting = companySetting
  },
  SET_SELECTED_COMPANY: (state, company) => {
    state.selectedCompany = company
  },
}
