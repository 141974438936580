<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <b-form-group class="mb-0">
        <b-form-select v-model="reportDate" :options="reportDates" class="mb-0" @change="getReport">
          <template #first>
            <b-form-select-option :value="null" disabled>Select Report Date</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <div class="d-flex align-items-center">
        <b-button variant="primary" size="sm" :disabled="!reportDate" class="mr-3" @click="downloadReport('view')">
          Preview PDF
        </b-button>
        <b-button variant="primary" size="sm" :disabled="!reportDate" @click="downloadReport('download')">
          Download PDF
        </b-button>
      </div>
    </div>
    <b-table-simple responsive sticky-header="90vh" outlined bordered>
      <template v-if="!tableLoading">
        <b-thead>
          <b-tr>
            <b-th
              v-for="field in tableFields"
              :key="field.id"
              style="width: 10rem; margin: auto; text-align: center; vertical-align: top"
            >
              {{ field.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(record, key) in records">
            <b-tr class="row-header">
              <b-td class="text-uppercase font-weight-bold bg-gradient" colspan="9">
                {{ getTitle(record.title) }}
              </b-td>
            </b-tr>
            <b-tr v-for="(value, valueIdx) in record.records" :key="key + '_' + valueIdx">
              <template v-if="Object.keys(value).length === 1">
                <b-td class="font-weight-bold text-decoration-underline font-italic" colspan="9">
                  {{ value.security_type }}
                </b-td>
              </template>
              <template v-else>
                <b-td class="text-left" style="width: 40%">
                  {{ value.security_type }}
                </b-td>
                <b-td class="text-right">
                  {{ value.haircut ? value.haircut : '' }}
                </b-td>
                <b-td class="text-right">
                  {{ value.long_market_value }}
                </b-td>
                <b-td class="text-right">
                  {{ value.short_market_value }}
                </b-td>
                <b-td class="text-right">
                  {{ value.tentative_haircut ? value.tentative_haircut : '' }}
                </b-td>
                <b-td class="text-right">
                  {{ value.haircut_amount }}
                </b-td>
                <b-td class="text-right">
                  {{ value.undue_concentration_charge }}
                </b-td>
                <b-td class="text-right">
                  {{ value.total }}
                </b-td>
              </template>
            </b-tr>
            <b-tr class="row-header">
              <b-td class="text-uppercase text-right font-weight-bold bg-gradient" colspan="2">
                {{ record.title }}
              </b-td>
              <b-td class="font-weight-bold bg-gradient text-right">
                {{ record.totals.long_market_value }}
              </b-td>
              <b-td class="font-weight-bold bg-gradient text-right">
                {{ record.totals.short_market_value }}
              </b-td>
              <b-td class="font-weight-bold bg-gradient text-right">
                {{ record.totals.tentative_haircut }}
              </b-td>
              <b-td class="font-weight-bold bg-gradient text-right">
                {{ record.totals.haircut_amount }}
              </b-td>
              <b-td class="font-weight-bold bg-gradient text-right">
                {{ record.totals.undue_concentration_charge }}
              </b-td>
              <b-td class="font-weight-bold bg-gradient text-right">
                {{ record.totals.total }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="7"></b-td>
            </b-tr>
          </template>
          <b-tr v-if="records.length > 0">
            <b-td class="text-uppercase text-right font-weight-bold bg-gradient" colspan="2"> Total Haircut</b-td>
            <b-td class="font-weight-bold bg-gradient text-right">
              {{ this.haircutTotals.long_market_value }}
            </b-td>
            <b-td class="font-weight-bold bg-gradient text-right">
              {{ this.haircutTotals.short_market_value }}
            </b-td>
            <b-td class="font-weight-bold bg-gradient text-right">
              {{ this.haircutTotals.tentative_haircut }}
            </b-td>
            <b-td class="font-weight-bold bg-gradient text-right">
              {{ this.haircutTotals.haircut_amount }}
            </b-td>
            <b-td class="font-weight-bold bg-gradient text-right">
              {{ this.haircutTotals.undue_concentration_charge }}
            </b-td>
            <b-td class="font-weight-bold bg-gradient text-right">
              {{ this.haircutTotals.total }}
            </b-td>
          </b-tr>
          <b-tr v-else>
            <b-td colspan="8" class="text-center"> There are no records to show</b-td>
          </b-tr>
        </b-tbody>
      </template>
      <template v-if="tableLoading">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
    </b-table-simple>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { numberFormatter, dateFormatter, manageReportFiles } from '@/utils/helpers'

export default {
  name: 'HaircutBySecurityView',
  data() {
    return {
      fields: [
        {
          id: 1,
          key: 'id',
          label: 'ID',
          editRecord: false,
          viewRecord: false,
          type: 'text',
        },
        {
          id: 2,
          key: 'security_type',
          label: 'Security Type',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 3,
          key: 'haircut',
          label: 'Haircut %',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 4,
          key: 'long_market_value',
          label: 'Long Market Value',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 5,
          key: 'short_market_value',
          label: 'Short Market Value',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 6,
          key: 'long_position_haircut',
          label: 'Long Position Haircut',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 7,
          key: 'short_position_haircut',
          label: 'Short Position Haircut',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 8,
          key: 'haircut_amount',
          label: 'Haircut Amount',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
      ],
      haircutTotals: {},
      status: '',
      records: [],
      reportDate: null,
      reportDates: [],
    }
  },
  created() {
    this.getAvailableTimeReports()
  },
  computed: {
    tableFields() {
      return this.fields.filter((field) => field.viewRecord)
    },
    tableLoading() {
      return this.status === 'loading'
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setPdf: 'report/setPdf',
    }),
    async getReport() {
      this.status = 'loading'
      this.records = []
      try {
        const response = await this.$api.get('/haircut-securities', {
          params: {
            reportDate: this.reportDate,
            company_id: this.company.id,
          },
        })
        if (!response.data?.data) {
          return []
        }
        this.fields = response.data?.data?.table?.map((field) => {
          return {
            title: field.name,
            label: field.label,
            sortable: true,
            viewRecord: true,
          }
        })

        response.data?.data?.data.forEach((record) => {
          if (record[0] === 'Total Haircuts') {
            Object.keys(record[1]).forEach((key) => {
              this.haircutTotals[key] = numberFormatter(record[1][key])
            })
            return
          }
          this.records.push({
            title: record[0],
            records: this.filterSortRecords(record[1]),
            totals: this.calculateTotals(record[1], true),
          })
        })
        this.status = 'success'
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
    filterSortRecords(records) {
      const columns = [
        'security_type',
        'haircut',
        'long_market_value',
        'short_market_value',
        'tentative_haircut',
        'haircut_amount',
        'total',
        'undue_concentration_charge',
      ]

      return records?.map((obj) => {
        return columns.reduce((acc, key) => {
          if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== null) {
            if (key === 'security_type') {
              acc[key] = obj[key]
              return acc
            }
            acc[key] =
              key === 'haircut' ? this.formatPercentage(obj[key]) : obj[key] ? numberFormatter(obj[key]) : obj[key]
          } else {
            if (key === 'tentative_haircut' && obj.haircut !== null) {
              acc[key] = 0
            }
          }
          return acc
        }, {})
      })
    },
    calculateTotals(records, format) {
      let totals = {}
      const totalFields = [
        'long_market_value',
        'short_market_value',
        'tentative_haircut',
        'haircut_amount',
        'total',
        'undue_concentration_charge',
      ]
      if (records.length > 0) {
        records.forEach((record) => {
          Object.keys(record).forEach((key) => {
            if (totalFields.includes(key)) {
              if (totals[key]) {
                totals[key] += parseFloat(record[key])
              } else {
                totals[key] = parseFloat(record[key])
              }
            }
          })
        })
      }

      if (format) {
        totals = Object.keys(totals).reduce((obj, key) => {
          obj[key] = numberFormatter(totals[key])
          return obj
        }, {})
      } else {
        totals = Object.keys(totals).reduce((obj, key) => {
          obj[key] = totals[key]
          return obj
        }, {})
      }

      return totals
    },
    formatPercentage(value) {
      if (!value) return '0.000%'
      return value !== 'Varies' ? `${(value * 100).toFixed(3)}%` : value
    },
    getTitle(value) {
      return value.replace('Total', '')
    },
    downloadReport(mode) {
      this.setLoading(true)
      const params = {
        params: {
          reportDate: this.reportDate,
          download: true,
        },
      }
      this.$api
        .get(`/haircut-securities?company_id=${this.company.id}`, params)
        .then((res) => {
          if (!res.data?.data) {
            this.$showInfo('There is no report.')
            return
          }
          const createdPDF = manageReportFiles(res.data)
          if (mode === 'download') {
            createdPDF.download(`Haircut_Securities_${dateFormatter(this.reportDate)}.pdf`)
            return
          }
          createdPDF.getDataUrl((dataUrl) => {
            this.setPdf({
              src: dataUrl,
              title: `Haircut_Securities_${dateFormatter(this.reportDate)}`,
            })
            this.$bvModal.show('PdfModal')
          })
        })
        .catch((err) => {
          this.$showError(err.response?.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    async getAvailableTimeReports() {
      this.status = 'loading'
      try {
        const response = await this.$api.get(`/inventories/available-report-date?company_id=${this.company.id}`)
        this.status = 'success'
        if (response.status === 200 && response.data?.data?.length > 0) {
          this.reportDates = response.data.data.map((report) => {
            return {
              value: report.report_date,
              text: dateFormatter(report.report_date),
            }
          })
          if (this.reportDates.length > 0) {
            this.reportDate = this.reportDates[0].value
            this.getReport()
          }
        }
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
  },
}
</script>

<style scoped>
.cell-width {
  width: 30vw;
}

.bg-gradient {
  background: linear-gradient(90deg, #f7f7f7 0%, #f7f7f7 100%);
}

.text-decoration-underline {
  text-decoration: underline;
}
</style>
