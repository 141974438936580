<template>
  <b-container fluid class="main">
    <b-row>
      <div style="font-size: 116px">404</div>
    </b-row>
    <b-row>
      <h2>Sorry, we can't seem to find the page you're looking for.</h2>
    </b-row>
    <b-row>
      <b-button style="margin-top: 35px" @click="handleRedirect">Go Back to Home</b-button>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'NotFoundPage',
  methods: {
    handleRedirect() {
      this.$router.replace('/')
    },
  },
}
</script>
<style scoped>
.main {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
</style>
