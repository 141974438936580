<template>
  <div>
    <b-modal
      id="haircut-option-form-modal"
      :title="modalTitle"
      size="lg"
      @show="getPrefillData"
      @ok="onSubmit"
      @hidden="resetForm"
      scrollable
      ok-title="Submit"
    >
      <div class="text-center" v-if="isLoading">
        <b-spinner></b-spinner>
      </div>
      <ValidationObserver v-else ref="haircutOptionFormModal">
        <b-form>
          <b-row>
            <b-col md="12">
              <template v-for="comp in formComponents">
                <ValidationProvider
                  v-if="comp.type === 'input'"
                  :name="comp.label"
                  :rules="comp.validation"
                  v-slot="{ errors }"
                >
                  <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                    <b-form-input
                      :id="comp.name"
                      type="text"
                      :state="errors[0] ? false : null"
                      v-model="form[comp.name]"
                      :disabled="!comp.isEditable"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="comp.type === 'number'"
                  :name="comp.label"
                  :rules="comp.validation"
                  v-slot="{ errors }"
                >
                  <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                    <b-form-input
                      :id="comp.name"
                      type="number"
                      :state="errors[0] ? false : null"
                      v-model.number="form[comp.name]"
                      :disabled="!comp.isEditable"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="comp.type === 'select'"
                  :name="comp.label"
                  :rules="comp.validation"
                  v-slot="{ errors }"
                >
                  <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                    <b-form-select
                      :id="comp.name"
                      :options="comp.options"
                      v-model="form[comp.name]"
                      :state="errors[0] ? false : null"
                      :disabled="!comp.isEditable"
                    ></b-form-select>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="comp.type === 'currency'"
                  :name="comp.label"
                  :rules="comp.validation"
                  v-slot="{ errors }"
                >
                  <b-form-group :label="comp.label" :label-for="comp.name" :state="errors[0] ? false : null">
                    <NumberFormattedInput
                      :name="comp.name"
                      :state="errors[0] ? false : null"
                      v-model="form.haircut_amount"
                      :disabled="!comp.isEditable"
                      icon
                      :errors="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </template>

              <ValidationProvider v-if="mode === 'add'" rules="required" name="Report Date" v-slot="{ errors }">
                <b-form-group label="Report Date" :invalid-feedback="errors[0]">
                  <b-datepicker
                    :state="errors[0] ? false : null"
                    v-model="form.report_date"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  ></b-datepicker>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import NumberFormattedInput from '../NumberFormattedInput.vue'

export default {
  name: 'HaircutFormModal',
  components: { NumberFormattedInput },
  props: {
    haircutData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        id: null,
        identifier: '',
        cusip_isin: '',
        call_put: '',
        currency: '',
        contracts_qty: '',
        contract_purch_pr: '',
        underlying_symbol: '',
        underlying_exec_pr: '',
        underlying_mkt_pr: '',
        covered: '',
        long_short: '',
        strike: '',
        tot_pos_val: '',
        haircut_amount: '',
        report_date: '',
        // closing_price: '',
        // contract_desc: '',
      },
      clonedForm: {},
      formComponents: [],
      bvComponents: {
        input: 'b-form-input',
        currency: 'b-form-input',
        select: 'b-form-select',
        number: 'b-form-input',
        date: 'b-form-datepicker',
      },
      haircut_amount: '',
    }
  },
  computed: {
    modalTitle() {
      return this.mode === 'add' ? 'Add Options Inventory' : 'Edit Options Inventory'
    },
  },
  methods: {
    onSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.haircutOptionFormModal.validate().then((valid) => {
        if (!valid) {
          this.$showError('Please fill all the required fields')
          return
        }
        if (Object.keys(this.form).every((key) => this.form[key] === this.clonedForm[key])) {
          this.$emit('hc-updated')
          this.$bvModal.hide('haircut-option-form-modal')
          return
        }
        this.$emit(this.mode === 'add' ? 'hc-added' : 'hc-updated', this.form)
        this.$bvModal.hide('haircut-option-form-modal')
      })
    },
    getPrefillData() {
      this.isLoading = true
      this.$api
        .get('options-inventories/prefill')
        .then((response) => {
          this.formComponents = response.data?.data.map((item) => {
            const unRequiredFields = [
              'cusip_isin',
              'undue_concentration_basis',
              'undue_concentration_charge',
              'haircut_amount',
            ]
            return {
              label: item.label,
              name: item.name,
              type: item.values ? 'select' : item.type,
              validation: !unRequiredFields.includes(item.name) ? 'required' : null,
              options: item.value?.map((v) => {
                return {
                  value: v,
                  text: v,
                }
              }),
              dependentFieldName: item.dependentFieldName,
              dependentFieldValue: item.dependentFieldValue,
              isEditable: item.isEditable,
            }
          })

          if (this.mode === 'edit') this.bindForm()
        })
        .catch((error) => {
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    bindForm() {
      this.form.report_date = this.haircutData.report_date
      this.form.id = this.haircutData.id
      this.formComponents.forEach((item) => {
        this.form[item.name] = this.haircutData[item.name]
        if (item.name === 'contract_desc') {
          item.isEditable = false
          item.validation = ''
        }
      })
    },
    resetForm() {
      this.form = {
        id: null,
        identifier: '',
        cusip_isin: '',
        call_put: '',
        currency: '',
        contracts_qty: '',
        contract_purch_pr: '',
        underlying_symbol: '',
        underlying_exec_pr: '',
        underlying_mkt_pr: '',
        covered: '',
        long_short: '',
        strike: '',
        tot_pos_val: '',
        haircut_amount: '',
        report_date: '',
      }
    },
  },
}
</script>
