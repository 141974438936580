<template>
  <div>
    <b-modal id="review-detail-modal" title="Review Requests" size="xl" scrollable @hidden="resetModal">
      <b-tabs v-model="tabIndex">
        <b-tab title="Records" active v-if="isAdmin">
          <TheTable
            :records="createdRecords"
            :fields="columns"
            :loading="isLoading"
            :sort-by="sort.sortBy"
            :sort-desc="sort.sortDesc"
            @sort-changed="handleSortChange"
          >
            <template #cell(credit)="{ value }">
              <div v-if="value" class="d-flex justify-content-between">
                <span>$</span>
                <span>{{ value }}</span>
              </div>
            </template>
            <template #cell(debit)="{ value }">
              <div v-if="value" class="d-flex justify-content-between">
                <span>$</span>
                <span>{{ value }}</span>
              </div>
            </template>
          </TheTable>
          <TablePagination
            :currentPage="currentPage"
            :rows="rows"
            :perPage="perPage"
            @pagination="handlePagination"
            @entries="handleEntries"
          />
        </b-tab>
        <b-tab title="Conversations">
          <DetailModalConversation :id="id" />
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <div class="d-flex justify-content-end align-items-center" style="gap: 5px">
          <b-button
            v-if="!showOtherButtons"
            type="button"
            variant="secondary"
            @click="$bvModal.hide('review-detail-modal')"
          >
            Cancel
          </b-button>
          <b-button v-if="showReopen" type="button" variant="primary" @click="handleStatus(id, 'reopen')">
            <b-icon icon="arrow-counterclockwise"></b-icon>
            Reopen
          </b-button>
          <b-button v-if="showOtherButtons" type="button" variant="danger" @click="handleStatus(id, 'close')">
            <b-icon icon="x"></b-icon>
            Close
          </b-button>
          <b-button v-if="showOtherButtons" type="button" variant="warning" @click="handleStatus(id, 'reject')">
            <b-icon icon="clipboard-x"></b-icon>
            Reject
          </b-button>
          <b-button
            v-if="showOtherButtons"
            type="button"
            variant="success"
            style="background-color: green"
            @click="handleStatus(id, 'approve')"
          >
            <b-icon icon="check"></b-icon>
            Approve
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DetailModalConversation from './DetailModalConversation.vue'
import { COA, BALANCE, HAIRCUT_DETAIL, HAIRCUT_OPTION } from './detailTableFields'

export default {
  name: 'ReviewDetailModal',
  props: {
    id: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  components: {
    DetailModalConversation,
  },
  data() {
    return {
      createdRecords: [],
      currentPage: 1,
      rows: 0,
      perPage: 50,
      sort: {
        sortBy: '',
        sortDesc: false,
      },
      tabIndex: 0,
      fetchStatus: 'idle',
      COA,
      BALANCE,
      HAIRCUT_DETAIL,
      HAIRCUT_OPTION,
    }
  },
  watch: {
    id() {
      if (this.id && this.isAdmin) {
        this.getModalList(this.id)
      }
    },
  },
  computed: {
    columns() {
      return this[this.type ?? 'COA']
    },
    showReopen() {
      return this.status === 'CLOSED' && this.isAdmin
    },
    showOtherButtons() {
      return this.status === 'OPEN' && this.isAdmin
    },
    isLoading() {
      return this.fetchStatus === 'loading'
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setLoadingDesc: 'loader/setDescription',
    }),
    async getModalList() {
      this.fetchStatus = 'loading'
      try {
        const params = {
          params: {
            pagination: {
              page: this.currentPage,
              pageSize: this.perPage,
            },
            ...(this.sort.sortBy && { sort: [`${this.sort.sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}`] }),
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get(`/review-requests/${this.id}/contents`, params)
        const data = response.data?.data
        console.log(data)
        this.createdRecords = data?.data || []
        this.fetchStatus = 'success'
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    resetModal() {
      this.createdRecords = []
      this.sort = {
        sortBy: '',
        sortDesc: false,
      }
    },
    handleStatus(id, status) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to ' + status + ' this request?', {
          title: 'Confirmation',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.setLoading(true)
            this.setLoadingDesc('Processing...')
            this.$api
              .get(`/review-requests/${id}?company_id=${this.company.id}&type=${status}`)
              .then(() => {
                this.$showSuccess('Request Updated Successfully')
                this.$emit('update')
                this.$bvModal.hide('review-detail-modal')
              })
              .catch((err) => {
                this.$showError(err.response?.data?.error?.message || 'Unable to update the request')
              })
              .finally(() => {
                this.setLoading(false)
                this.setLoadingDesc('')
              })
          }
        })
    },
    handlePagination(value) {
      this.currentPage = value
      this.getModalList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getModalList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getModalList()
    },
  },
}
</script>
