import _ from 'lodash'
import Vue from 'vue'

const components = require.context('../components/findash')
_.forEach(components.keys(), (fileName) => {
  const componentConfig = components(fileName)
  const componentName = fileName.split('/').pop().split('.')[0]
  if (componentName) Vue.component(componentName, componentConfig.default || componentConfig)
})

// const commonComponents = require.context('../components/common')
// _.forEach(commonComponents.keys(), (fileName) => {
//   const componentConfig = commonComponents(fileName)
//   const componentName = fileName.split('/').pop().split('.')[0]
//   if (componentName) Vue.component(componentName, componentConfig.default || componentConfig)
// })

// const baseComponents = require.context('../components/Base')
// _.forEach(baseComponents.keys(), (fileName) => {
//   const componentConfig = baseComponents(fileName)
//   const componentName = fileName.split('/').pop().split('.')[0]
//   if (componentName) Vue.component(componentName, componentConfig.default || componentConfig)
// })

const inputComponents = require.context('../components/')
_.forEach(inputComponents.keys(), (fileName) => {
  const componentConfig = inputComponents(fileName)
  const componentName = fileName.split('/').pop().split('.')[0]
  if (componentName) Vue.component(componentName, componentConfig.default || componentConfig)
})
