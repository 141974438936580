export default {
  SET_TABLE_LOADING(state, payload) {
    state.tableLoading = payload
  },
  SET_PDF_PREVIEW_LOADING(state, payload) {
    state.pdfPreviewLoading = payload
  },
  SET_PDF(state, payload) {
    state.pdf = payload
  },
}
