<template>
  <div v-if="loading" class="screen-loader">
    <b-spinner
      class="align-middle"
      style="width: 5rem; height: 5rem; color: white"
      :variant="spinnerVariant"
      :small="spinnerSmall"
    ></b-spinner>
    <div v-if="description" class="mx-2 mt-3" style="color: white">{{ description }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ScreenLoader',
  computed: {
    ...mapGetters({
      loading: 'loader/getLoading',
      spinnerVariant: 'loader/getSpinnerVariant',
      spinnerSmall: 'loader/getSpinnerSmall',
      description: 'loader/getDescription',
    }),
  },
}
</script>

<style lang="scss" scoped>
.screen-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
