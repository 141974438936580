<template>
  <div>
    <b-modal
      id="trial-balance-form-modal"
      size="lg"
      :title="title"
      :ok-title="okButtonTitle"
      @hidden="onCloseModal"
      @ok="onSubmit"
      @shown="bindForm"
    >
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between">
          <h5>{{ title }}</h5>
          <div class="ml-3 mt-1" v-if="mode === 'edit'">
            <b-form-checkbox name="check-button" v-model="isContraMode" switch> Contra-Account Mode </b-form-checkbox>
          </div>
        </div>
      </template>

      <div v-if="loading" class="text-center my-2" style="color: #1f3d73">
        <b-spinner class="align-middle" style="margin-right: 10px"></b-spinner>
      </div>

      <ValidationObserver v-else ref="tbFormModal">
        <b-form>
          <ValidationProvider>
            <b-form-group label="Description" label-for="desc">
              <b-form-input id="desc" type="text" v-model="form.description" />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required" name="Debit" v-slot="{ errors }">
            <b-form-group label="New Debit Balance" label-for="debit" invalid-feedback="This field is required">
              <NumberFormattedInput
                name="debit"
                :state="errors[0] ? false : null"
                placeholder="0.00"
                v-model="form.debit"
                icon
                :error="errors[0]"
                @change="onDebitChange"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required" name="Credit" v-slot="{ errors }">
            <b-form-group label="New Credit Balance" label-for="credit">
              <NumberFormattedInput
                name="credit"
                :state="errors[0] ? false : null"
                placeholder="0.00"
                v-model="form.credit"
                icon
                :error="errors[0]"
                @change="onCreditChange"
              />
            </b-form-group>
          </ValidationProvider>

          <MonthPicker
            v-if="mode === 'add'"
            name="Time Report"
            rules="required"
            label="Time Report"
            v-model="form.time_report"
          />

          <FormModalCoaListAutoComplete
            :coa="form.coa"
            @onSelect="onSelectCOA"
            @onClear="onClearCOA"
          ></FormModalCoaListAutoComplete>

          <!-- <FormModalCoaListDropdown :coa="form.coa" @onSelect="onSelectCOA"></FormModalCoaListDropdown> -->

          <FormModalContraAdjustment
            v-for="(adjustmentForm, index) in adjustmentForms"
            :key="index"
            :index="index"
            :adjustment-form="adjustmentForm"
            :adjustmentAmountError="adjustmentAmountError"
            @updateAmount="updateAmount"
            @updateNumber="updateNumber"
          ></FormModalContraAdjustment>
        </b-form>
      </ValidationObserver>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('trial-balance-form-modal')"> Cancel </b-button>
        <b-button v-if="isContraMode" variant="primary" @click="onAddAdjustments()">
          Add Another Adjustment Amount
        </b-button>
        <b-button variant="primary" @click="onSubmit"> Save </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import FormModalCOAModal from './FormModalCOAModal.vue'
import FormModalContraAdjustment from './FormModalContraAdjustment.vue'
import { numberFormatter } from '@/utils/helpers'
import NumberFormattedInput from '../NumberFormattedInput.vue'
// import FormModalCoaListDropdown from './FormModalCoaListDropdown.vue'
import FormModalCoaListAutoComplete from './FormModalCoaListAutoComplete.vue'
// import Multiselect from 'vue-multiselect'

export default {
  name: 'TrialBalanceFormModal',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    FormModalContraAdjustment,
    // FormModalCOAModal,
    NumberFormattedInput,
    // FormModalCoaListDropdown,
    FormModalCoaListAutoComplete,
  },
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    id: {
      type: Number,
      default: null,
    },
    coa: {
      type: Object,
      default: null,
    },
    debit: {
      type: Number,
      default: 0,
    },
    credit: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: '',
    },
    timeReport: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        id: null,
        credit: 0,
        debit: 0,
        coa: null,
        time_report: '',
        description: '',
      },
      clonedForm: {},
      loading: true,
      isSubmitting: false,
      options: [],
      isSearching: false,
      adjustmentForms: [],
      originalAmount: 0,
      adjustmentAmountError: '',
      isAdjustingDebit: false,
      isContraMode: false,
    }
  },
  watch: {
    isContraMode: {
      handler(newVal) {
        if (!newVal) {
          this.adjustmentForms = []
          this.form = { ...this.clonedForm }
        }
      },
    },
  },
  computed: {
    title() {
      return this.mode === 'add' ? 'Add Trial Balance Item' : 'Edit Trial Balance Item'
    },
    okButtonTitle() {
      return this.mode === 'add' ? 'Add' : 'Update'
    },
  },
  methods: {
    bindForm() {
      this.form = {
        id: this.id,
        coa: this.coa?.account_number ? this.coa : null,
        debit: this.debit ?? 0,
        credit: this.credit ?? 0,
        time_report: this.timeReport,
        description: this.description,
        adjustments: [],
      }
      this.originalAmount = parseFloat(this.debit || this.credit)
      this.clonedForm = { ...this.form }
      this.adjustmentForms = []
      this.isContraMode = this.mode === 'edit'
      this.loading = false
    },
    onSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()

      this.$refs.tbFormModal.validate().then((valid) => {
        if (!valid || !this.form.coa) {
          this.$refs.tbFormModal.refs['main_coa'].setErrors(['Chart Of Account is required'])
          this.$showError('Please fill all the required fields')
          return
        } else {
          this.$refs.tbFormModal.refs['main_coa'].setErrors([])
        }

        if (this.adjustmentAmountError) {
          return
        }

        if (Object.keys(this.form).every((key) => this.form[key] === this.clonedForm[key])) {
          this.$emit('tb-updated')
          this.$bvModal.hide('trial-balance-form-modal')
          return
        }
        this.$emit(this.mode === 'add' ? 'tb-added' : 'tb-updated', this.form)
        this.$bvModal.hide('trial-balance-form-modal')
      })
    },
    onCloseModal() {
      this.form = {
        credit: 0,
        debit: 0,
        coa: null,
        time_report: '',
        description: '',
      }
      this.loading = true
    },
    onDebitChange(value) {
      if (!this.isContraMode) return
      this.adjustmentForms = []
      this.$nextTick(() => {
        const debit = Number(value)
        if (this.originalAmount !== debit) {
          this.isAdjustingDebit = true
          const amount = debit - this.originalAmount
          this.onAddAdjustments(Number(amount.toFixed(2)))
        }
      })
    },
    onCreditChange(value) {
      if (!this.isContraMode) return
      this.adjustmentForms = []
      this.$nextTick(() => {
        const credit = Number(value)
        if (this.originalAmount !== credit) {
          this.isAdjustingDebit = false
          const amount = credit - this.originalAmount
          this.onAddAdjustments(Number(amount.toFixed(2)))
        }
      })
    },
    onAddAdjustments(adjustmentAmount = 0, contraAccountObj = null) {
      this.adjustmentForms.push(
        {
          label: 'Contra-Account Adjustment Amount',
          name: 'adjustment_amount',
          rules: 'required',
          value: adjustmentAmount,
        },
        {
          label: 'Contra-Account Adjustment Account Number',
          name: 'adjustment_number',
          rules: 'required',
          options: this.options,
          adjustmentAmount: adjustmentAmount,
          value: contraAccountObj,
        },
      )
    },
    calculatedAdjustmentAmount(adjustmentAmount, contraAccountObj) {
      let amount = contraAccountObj?.debit || contraAccountObj?.credit || 0
      // console.log(contraAccountObj.coa.account_type)
      if (adjustmentAmount < 0) {
        switch (contraAccountObj.coa.account_type) {
          case 'Assets': {
            if (this.form.coa.account_type === 'Assets' || this.form.coa.account_type === 'Expenses') {
              return amount + adjustmentAmount
            }
            return amount - adjustmentAmount
          }
          case 'Liabilities': {
            if (this.form.coa.account_type === 'Liabilities' || this.form.coa.account_type === 'Income') {
              return amount + adjustmentAmount
            }
            return amount - adjustmentAmount
          }
          case 'Income': {
            if (this.form.coa.account_type === 'Liabilities' || this.form.coa.account_type === 'Income') {
              return amount + adjustmentAmount
            }
            return amount - adjustmentAmount
          }
          case 'Expenses': {
            if (this.form.coa.account_type === 'Assets' || this.form.coa.account_type === 'Expenses') {
              return amount + adjustmentAmount
            }
            return amount - adjustmentAmount
          }
        }
        return
      }

      switch (contraAccountObj.coa.account_type) {
        case 'Assets': {
          if (this.form.coa.account_type === 'Assets' || this.form.coa.account_type === 'Expenses') {
            return amount - adjustmentAmount
          }
          return amount + adjustmentAmount
        }
        case 'Liabilities': {
          if (this.form.coa.account_type === 'Liabilities' || this.form.coa.account_type === 'Income') {
            return amount - adjustmentAmount
          }
          return amount + adjustmentAmount
        }
        case 'Income': {
          if (this.form.coa.account_type === 'Liabilities' || this.form.coa.account_type === 'Income') {
            return amount - adjustmentAmount
          }
          return amount + adjustmentAmount
        }
        case 'Expenses': {
          if (this.form.coa.account_type === 'Assets' || this.form.coa.account_type === 'Expenses') {
            return amount - adjustmentAmount
          }
          return amount + adjustmentAmount
        }
      }
    },
    updateAmount({ value, index }) {
      const adjustedAmount = Number(
        (this.form[this.isAdjustingDebit ? 'debit' : 'credit'] - this.originalAmount).toFixed(2),
      )
      // console.log('adjustment', adjustedAmount, value, this.totalAdjustmentAmount())
      this.adjustmentAmountError = ''
      if (
        (value > 0 && value > adjustedAmount) ||
        (value < 0 && value < adjustedAmount) ||
        this.totalAdjustmentAmount() > adjustedAmount
      ) {
        this.adjustmentAmountError = 'Adjustment amount must be less than or equal to debit amount'
        return
      }

      this.adjustmentForms[index].value = value
      const amounts = []
      for (let i = 0; i < this.adjustmentForms.length; i += 2) {
        amounts.push({
          adjustment_amount: this.adjustmentForms[i].value,
          adjustment_number: this.adjustmentForms[i + 1].value,
        })
      }
      this.adjustmentForms = []
      this.form.adjustments = []

      if (value < 0) {
        amounts.forEach((amount) => {
          if (
            amount.adjustment_amount >= adjustedAmount &&
            this.totalAdjustmentAmount(amount.adjustment_amount) >= adjustedAmount
          ) {
            this.onAddAdjustments(amount.adjustment_amount, amount.adjustment_number)
          }
        })
        const amountToAdd = Number((adjustedAmount - this.totalAdjustmentAmount()).toFixed(2))
        if (this.totalAdjustmentAmount() > adjustedAmount && amountToAdd > 0) {
          this.onAddAdjustments(amountToAdd)
        }
      } else {
        amounts.forEach((amount) => {
          if (
            amount.adjustment_amount <= adjustedAmount &&
            this.totalAdjustmentAmount(amount.adjustment_amount) <= adjustedAmount
          ) {
            this.onAddAdjustments(amount.adjustment_amount, amount.adjustment_number)
          }
        })
        const amountToAdd = Number((adjustedAmount - this.totalAdjustmentAmount()).toFixed(2))
        if (this.totalAdjustmentAmount() < adjustedAmount && amountToAdd > 0) {
          this.onAddAdjustments(amountToAdd)
        }
      }
    },
    updateNumber({ value, index }) {
      this.adjustmentForms[index].value = value
      const calculated = this.calculatedAdjustmentAmount(
        this.adjustmentForms[index - 1].value,
        this.adjustmentForms[index].value,
      )

      const adjustment = {
        id: this.adjustmentForms[index].value.id,
        coa: this.adjustmentForms[index].value.coa,
        debit:
          this.adjustmentForms[index].value.coa.account_type === 'Assets' ||
          this.adjustmentForms[index].value.coa.account_type === 'Expenses'
            ? calculated
            : null,
        credit:
          this.adjustmentForms[index].value.coa.account_type === 'Liabilities' ||
          this.adjustmentForms[index].value.coa.account_type === 'Income'
            ? calculated
            : null,
        time_report: this.adjustmentForms[index].value.time_report,
        description: this.adjustmentForms[index].value.description,
      }

      if (!this.form.adjustments[index - 1]) {
        this.form.adjustments.push(adjustment)
      } else {
        this.form.adjustments[index - 1] = adjustment
      }

      if (!this.form.adjustments[index - 1].id) {
        this.form.adjustments[index - 1].time_report = this.form.time_report
        this.form.adjustments[index - 1].description = this.form.description
      }
    },
    totalAdjustmentAmount(amount = 0) {
      let total = 0
      this.adjustmentForms.forEach((form) => {
        if (form.name === 'adjustment_amount') {
          total += form.value
        }
      })
      return total + amount
    },
    onSelectCOA(coa) {
      this.form.coa = coa.coa
      this.$refs.tbFormModal.refs['main_coa'].setErrors([])
    },
    onClearCOA() {
      this.form.coa = null
      this.$refs.tbFormModal.refs['main_coa'].setErrors([])
    },
  },
}
</script>

<style>
.autocomplete-container {
  width: 100%;
}

.autocomplete-input {
  font-size: 14px !important;
  width: 100%;
}

.coa-result {
  border-top: 1px solid #eee;
  padding: 16px;
  background: #fff;
  background-image: none !important;
  padding: 12px !important;
}
</style>
