<template>
  <Multiselect
    :disabled="disabled"
    :value="value"
    @input="onChange"
    :options="options"
    :hide-selected="true"
    label="text"
    track-by="value"
    multiple
    class="bg-white"
    style="line-height: 0.3"
    :preserve-search="false"
    :searchable="false"
  >
  </Multiselect>
</template>

<script>
// multiselect
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'MultiselectInput',
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '45px',
    },
  },
  computed: {
    selectHeight() {
      return this.height || '45px'
    },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style>
.multiselect__tags {
  display: flex;
  align-items: center;
  min-height: 45px !important;
  border: 1px solid #ced4da !important;
}

.multiselect__tag {
  background-color: #1e3d73 !important;
  color: #fff !important;
}

.multiselect__select {
  height: 42px !important;
}

.multiselect__spinner {
  height: 40px;
  top: 2px;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__tag-icon:hover {
  background-color: #1e3d73 !important;
}

.multiselect__option--highlight {
  background-color: #1e3d73 !important;
  color: #fff !important;
}

.multiselect__option--highlight::after {
  background-color: #1e3d73 !important;
  color: #fff !important;
}
</style>
