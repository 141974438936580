import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import ssoi from './ssoi'

export default {
  namespaced: true,
  modules: { ssoi },
  state: {
    pdf: {},
  },
  getters,
  actions,
  mutations,
}
