<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Sign in</h1>
    <p class="auth-description">Enter your email address and password to access admin panel.</p>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider vid="username" name="username" rules="required" v-slot="{ errors }">
          <div class="d-flex justify-content-start">
            <div v-if="inputValidation.enableCountryCode" class="col-md-2 p-0" style="height: 45px">
              <country-select @onSelectCountry="onSelect"></country-select>
            </div>
            <b-form-group
              :class="inputValidation.enableCountryCode ? 'col-md-10 pl-4 pr-0 mb-3' : 'col-md-12 p-0 mb-3'"
              invalid-feedback="Email/Mobile is required"
            >
              <b-form-input
                type="text"
                :value="formData.username"
                @input="handleChange"
                id="username"
                placeholder="Enter Email/Mobile Number"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </div>
        </ValidationProvider>

        <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
          <b-form-group invalid-feedback="Password is required" class="col p-0 mb-3">
            <b-form-input
              type="password"
              autocomplete="new-password"
              id="password"
              v-model="formData.password"
              placeholder="Password"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </ValidationProvider>

        <div class="d-flex w-100">
          <!-- <b-form-checkbox name="remember-me" v-model="formData.rememberMe" style="z-index: 0">
            Remember Me
          </b-form-checkbox> -->
          <router-link to="forgot-password" class="ml-auto">Forgot password?</router-link>
        </div>

        <b-overlay
          class="d-inline-block mt-3 w-100"
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button type="submit" :disabled="isLoading" variant="primary" class="w-100"> SIGN IN</b-button>
        </b-overlay>

        <div class="d-inline-block w-100 mt-3 text-center">
          <span class="text-dark">Don't have an account?</span>
          <router-link to="register/send-otp" class="iq-waves-effect pl-1 text-underline"> Sign up</router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'SignInView',
  data: () => ({
    isLoading: false,
    inputValidation: {
      enableCountryCode: false,
      rules: 'required|email',
      errors: 'Email/Mobile Number is not valid',
    },
    formData: {
      password: '',
      username: '',
      rememberMe: false,
    },
    code: '',
  }),
  methods: {
    onSelect(dialCode) {
      this.code = dialCode
    },
    handleChange(val) {
      const isNumbers = new RegExp('^[0-9]+$')
      this.inputValidation.enableCountryCode = val && isNumbers.test(val)
      this.formData.username = val
    },
    onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch('auth/login', {
          username: this.inputValidation.enableCountryCode
            ? `+${this.code}${this.formData.username}`
            : this.formData.username,
          password: this.formData.password,
          rememberMe: this.formData.rememberMe,
        })
        .then((_) => {
          if (this.$store.state.auth.session) {
            this.$showSuccess('OTP is send to your mobile number')
            this.$router.push('sign-in-otp')
          } else {
            this.$route.query.redirect
              ? this.$router.push({ path: this.$route.query.redirect })
              : this.$router.push({ name: 'app.dashboard' })
          }
        })
        .catch((err) => {
          console.log('🚀 ~ file: SignInForm.vue:248 ~ onSubmit ~ error', err)
          const error = err.response?.data?.error?.message || err
          const user_id = err.response?.data?.error?.details?.userId
          switch (error) {
            case 'Your email is not submitted Please complete your registration':
              this.$showInfo(error)
              this.$store.dispatch('auth/setAuthUserId', user_id)
              this.$router.push('register/user-form')
              break
            case 'Your email is not verified':
              this.$showInfo(error)
              this.$store
                .dispatch('auth/resendEmailOTP', { user_id, password: this.formData.password })
                .then((res) => {
                  this.$showSuccess(res?.data?.message)
                  this.$store.dispatch('auth/setAuthUserId', user_id)
                  this.$router.push('register/verify-otp?is_phone_number=false')
                })
                .catch((err) => {
                  this.$showError('Unable to send resend email otp. Please try again later.')
                })
              break
            case 'Your phone number was not confirmed':
              this.$showInfo(error)
              this.$store
                .dispatch('auth/resendRegisterOTP', { userId: user_id })
                .then((res) => {
                  this.$showSuccess(res?.data?.message)
                  this.$store.dispatch('auth/setAuthUserId', user_id)
                  this.$router.push('register/verify-otp?isInvite=true')
                })
                .catch((err) => {
                  this.$showError('Unable to send resend otp. Please try again later.')
                })
              break
            default:
              this.$showError(error)
              break
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 3000)
        })
    },
    getUserInfo() {
      this.$store.dispatch('auth/getUser')
    },
  },
}
</script>
