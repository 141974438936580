import moment from 'moment/moment'

export default {
  methods: {
    dateFormat(date, format = 'MM/DD/YYYY') {
      return moment(date).format(format)
    },
    timeReportFormat(time) {
      return moment(time, 'MMM YY').format('MMM YYYY')
    },
    fullNameFormat(firstName, lastName) {
      return `${firstName || ''} ${lastName || ''}`
    },
    fileNameFormat(fileName, split = '_') {
      return fileName.split(split).join(' ')
    },
  },
}
