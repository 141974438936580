export default {
  namespaced: true,
  state: {
    loading: false,
    spinnerSmall: false,
    spinnerVariant: 'primary',
    description: '',
  },
  getters: {
    getLoading: (state) => state.loading,
    getSpinnerVariant: (state) => state.spinnerVariant,
    getSpinnerSmall: (state) => state.spinnerSmall,
    getDescription: (state) => state.description,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    setSpinnerSmall(state, payload) {
      state.spinnerSmall = payload
    },
    setSpinnerVariant(state, payload) {
      state.spinnerVariant = payload
    },
    setDescription(state, payload) {
      state.description = payload
    },
  },
  actions: {
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },
    setSpinnerSmall({ commit }, payload) {
      commit('setSpinnerSmall', payload)
    },
    setSpinnerVariant({ commit }, payload) {
      commit('setSpinnerVariant', payload)
    },
    setDescription({ commit }, payload) {
      commit('setDescription', payload)
    },
  },
}
