import { numberFormatter, dateFormatter } from '@/utils/helpers'

export const COA = [
  {
    key: 'account_number',
    label: 'Account Number',
    sortable: true,
    size: '100px',
    tdClass: 'text-center',
  },
  {
    key: 'account_name',
    label: 'Account Name',
    sortable: true,
    size: '180px',
    tdClass: 'text-center',
  },
  {
    key: 'account_type',
    label: 'Account Type',
    size: '100px',
    sortable: true,
    tdClass: 'text-center',
  },
  {
    key: 'allowable',
    label: 'Allowable/ Non-Allowable',
    sortable: true,
    formatter: (value) => {
      return value ? 'Allowable' : 'Non-Allowable'
    },
    size: '150px',
    tdClass: 'text-center',
  },
  {
    key: 'focus_setting',
    label: 'FOCUS ID',
    formatter: (value) => {
      return value?.focus_id ?? ''
    },
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'ssoi_setting',
    label: 'SSOI ID',
    formatter: (value) => {
      return value?.ssoi_id ?? ''
    },
    size: '80px',
    tdClass: 'text-center',
  },
]

export const BALANCE = [
  {
    key: 'description',
    label: 'Description',
    sortable: true,
    size: '120px',
  },
  {
    key: 'coa',
    label: 'Account Number',
    sortable: true,
    formatter: (value) => {
      return value?.account_number
    },
    size: '80px',
    tdClass: 'text-center',
  },
  {
    key: 'debit',
    label: 'Debit',
    sortable: true,
    size: '80px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'credit',
    label: 'Credit',
    sortable: true,
    size: '80px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'time_report',
    label: 'Time Report',
    sortable: true,
    size: '80px',
    tdClass: 'text-center',
  },
]

export const HAIRCUT_DETAIL = [
  {
    key: 'report_date',
    label: 'Report Date',
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return dateFormatter(value)
    },
  },
  {
    key: 'name_description',
    label: 'Description',
    sortable: true,
    size: '120px',
  },
  {
    key: 'identifier',
    label: 'Identifier',
    sortable: true,
    size: '100px',
  },
  {
    key: 'cusip_isin',
    label: 'CUSIP/ ISIN',
    sortable: true,
    size: '100px',
    thClass: 'text-wrap',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    size: '100px',
  },
  {
    key: 'subtype',
    label: 'SubType',
    sortable: true,
    size: '100px',
  },
  {
    key: 'long_or_short',
    label: 'Long/ Short',
    sortable: true,
    size: '100px',
  },
  {
    key: 'qty',
    label: 'Qty',
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'closing_price',
    label: 'Closing Price',
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'maturity',
    label: 'Maturity Date',
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return dateFormatter(value)
    },
  },
  {
    key: 'tot_pos_val',
    label: 'Total Position Value',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'haircut_amount',
    label: 'Haircut Amount',
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_basis',
    label: 'Undue Concentration Basis',
    sortable: true,
    size: '180px',
    formatter: (value) => {
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_charge',
    label: 'Undue Concentration Charge',
    sortable: true,
    size: '190px',
    formatter: (value) => {
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
]

export const HAIRCUT_OPTION = [
  {
    key: 'report_date',
    label: 'Report Date',
    sortable: true,
    size: '100px',
    formatter: (value) => {
      return dateFormatter(value)
    },
  },
  {
    key: 'contract_desc',
    label: 'Contract Description',
    sortable: true,
    size: '170px',
  },
  {
    key: 'cusip_isin',
    label: 'CUSIP/ ISIN',
    sortable: true,
    size: '140px',
    tdClass: 'text-center',
  },
  {
    key: 'call_put',
    label: 'Call/ Put',
    sortable: true,
    size: '100px',
    tdClass: 'text-center',
  },
  {
    key: 'currency',
    label: 'Currency',
    sortable: true,
    size: '100px',
    tdClass: 'text-center',
  },
  {
    key: 'contracts_qty',
    label: 'Contracts Qty',
    sortable: true,
    size: '110px',
    tdClass: 'text-center',
  },
  {
    key: 'contract_purch_pr',
    label: 'Contract Purchase Price',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'long_short',
    label: 'Long/ Short',
    sortable: true,
    size: '100px',
  },
  {
    key: 'covered',
    label: 'Covered',
    sortable: true,
    size: '100px',
  },
  {
    key: 'underlying_exec_pr',
    label: 'Underlying Execution Price',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value, 4)
    },
    tdClass: 'text-right',
  },
  {
    key: 'underlying_mkt_pr',
    label: 'Underlying Market Price',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'underlying_mkt_val',
    label: 'Underlying Market Value',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'tot_pos_val',
    label: 'Total Position Value',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'haircut_amount',
    label: 'Haircut Amount',
    sortable: true,
    size: '140px',
    formatter: (value) => {
      return numberFormatter(value)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_basis',
    label: 'Undue Concentration Basis',
    sortable: true,
    size: '180px',
    formatter: (value) => {
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
  {
    key: 'undue_concentration_charge',
    label: 'Undue Concentration Charge',
    sortable: true,
    size: '190px',
    formatter: (value) => {
      return numberFormatter(value, 2)
    },
    tdClass: 'text-right',
  },
]
