<template>
  <b-table
    responsive
    sticky-header="90vh"
    outlined
    bordered
    :id="id"
    :ref="id"
    :items="records"
    :busy="loading"
    :fields="fields"
    show-empty
    v-bind="$attrs"
    v-on="$listeners"
    custom-prop="any"
    tbody-tr-class="vertical-align"
    thead-tr-class="th-vertical-align-top"
  >
    <template #table-colgroup="scope">
      <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.size ? field.size : 'auto' }" />
    </template>
    <template #table-busy>
      <TableSpinner />
    </template>
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TheTable',
  props: {
    id: {
      type: String,
      default: '',
    },
    records: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.vertical-align td {
  vertical-align: middle !important;
}

.th-vertical-align-top th {
  vertical-align: top !important;
}

.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.75rem / 2) top 1rem !important;
  padding-right: calc(0.75rem + 0.65em);
}
</style>
