<template>
  <!-- loader Start -->
  <div id="loading" :style="style">
    <div id="loading-center"></div>
  </div>
  <!-- loader END -->
</template>
<script>
import lightLogo from '../../../assets/images/loader.gif'
import darkLogo from '../../../assets/images/darkMode/dark-loader.gif'
export default {
  name: 'Loader',
  props: {
    dark: { type: Boolean, default: false },
  },
  mounted() {
    if (this.$route.meta.dark) {
      this.style = `background: #1d203f url(${this.darkLogo}) no-repeat scroll center center;`
    } else {
      this.style = `background: #fff url(${this.logo}) no-repeat scroll center center;height:100vh`
    }
  },
  data() {
    return {
      logo: lightLogo,
      darkLogo: darkLogo,
      style: `background: #fff url(${lightLogo}) no-repeat scroll center center;`,
    }
  },
}
</script>

<style>
#loading {
  height: 100%;
  width: 100%;
  background-size: 15%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}
</style>
