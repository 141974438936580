<template>
  <div class="d-flex" data-cy="table-pagination">
    <div class="d-flex align-items-center ml-2">
      <label class="mr-2 mt-1">Entries</label>
      <b-form-select :value="perPage" :options="paginationOptions || []" @change="handleEntries" style="width: auto">
      </b-form-select>
    </div>
    <b-pagination
      v-if="perPage !== 'All'"
      :value="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      @change="handlePagination"
      class="mb-0"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: 'TablePagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: [Number, String],
      default: 10,
    },
    paginationOptions: {
      type: Array,
      default: () => ['50', '100', '250', 'All'],
    },
  },
  methods: {
    handlePagination(page) {
      this.$emit('pagination', page)
    },
    handleEntries(e) {
      this.$emit('entries', e)
    },
  },
}
</script>
