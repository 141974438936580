let inactivityTimer = null

function resetInactivityTimer(callback, timeout) {
  clearTimeout(inactivityTimer)
  inactivityTimer = setTimeout(callback, timeout)
}

function clearInactivityTimer() {
  inactivityTimer = null
  window.removeEventListener('mousemove', resetInactivityTimer)
  window.removeEventListener('keydown', resetInactivityTimer)
  window.removeEventListener('touchstart', resetInactivityTimer)
}

function setupInactivityDetection(callback, timeout) {
  window.addEventListener('mousemove', () => resetInactivityTimer(callback, timeout))
  window.addEventListener('keydown', () => resetInactivityTimer(callback, timeout))
  window.addEventListener('touchstart', () => resetInactivityTimer(callback, timeout))
}

export default {
  setupInactivityDetection,
  clearInactivityTimer,
}
