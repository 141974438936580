import api, { noAuthApi } from '@/utils/api'

export default {
  setAuthUserId: (context, payload) => {
    context.commit('SET_AUTH_USER_ID', payload)
  },
  login: (context, { username, password, rememberMe }) => {
    return api
      .post('/login', { data: { username, password } })
      .then((response) => {
        if (!response) return Promise.reject('Something went wrong')
        if (response.data?.data?.session) {
          context.commit('SET_SESSION', {
            session: response.data.data?.session,
            username: response.data?.username,
          })
        }
        if (response.data?.data?.tokens) {
          context.commit('LOGIN_SUCCESS', response.data?.data?.user)
          context.commit('SET_TOKENS', response.data?.data?.tokens)
        }

        if (rememberMe === true) {
          context.dispatch('setRememberMe')
        }

        const hasNoCompanySetting = setCompanySetting(context, response.data?.data?.user)
        if (hasNoCompanySetting) {
          context.commit('LOGIN_FAILURE')
          return Promise.reject('Please contact your administrator to setup your company')
        }
        return response.data?.user
      })
      .catch((error) => {
        context.commit('LOGIN_FAILURE')
        return Promise.reject(error)
      })
  },
  logout: (context) => {
    context.commit('LOGIN_FAILURE')
  },
  sendRegisterOTP: (context, payload) => {
    return api
      .post('/sent-otp', { data: payload })
      .then((response) => {
        context.commit('SET_AUTH_USER_ID', response.data?.data?.user_id || response.data?.created_user?.id)
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  resendRegisterOTP: (context, payload) => {
    return api
      .post('/resend-mobile-otp?action=sign_up', { data: payload })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  verifyRegisterOTP: (context, payload) => {
    return api
      .post(`/verify-otp?action=${payload.type}`, {
        data: {
          userId: payload.userId,
          otp: payload.otp,
          isPhoneNumber: payload.isPhoneNumber,
        },
      })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  registerUser: (context, payload) => {
    return api
      .post('register-user', { data: payload })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  verifyLoginOTP: (context, payload) => {
    return api
      .post('/response-mfa-challenge', { data: payload })
      .then((response) => {
        if (response.data?.data?.tokens) {
          context.commit('LOGIN_SUCCESS', response.data?.data?.user)
          context.commit('SET_TOKENS', response.data?.data?.tokens)
        }
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  resendLoginOTP: (context, payload) => {
    return api
      .post('/resend-mobile-otp?action=login', { data: payload })
      .then((response) => {
        context.commit('SET_SESSION', {
          session: response.data?.data?.session,
          username: response.data?.username,
        })
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  resendEmailOTP: (context, payload) => {
    return api
      .post('resend-email-otp', { data: payload })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  setAvatar: (context, payload) => {
    context.commit('SET_AVATAR', payload)
  },
  setSession: (context, payload) => {
    context.commit('SET_SESSION', payload)
  },
  getUser: (context) => {
    return api.get('/me').then((response) => {
      context.commit('LOGIN_SUCCESS', response.data?.data?.user)
      context.commit('SET_ACCESS_TOKEN', localStorage.getItem('access_token'))
      const hasNoCompanySetting = setCompanySetting(context, response.data?.data?.user)
      if (hasNoCompanySetting) {
        context.commit('LOGIN_FAILURE')
        return Promise.reject('no_company_setting')
      }
      return Promise.resolve()
    })
  },
  registerInvite: (context, payload) => {
    return api
      .post('register-invite', { data: payload })
      .then((response) => {
        context.commit('SET_AUTH_USER_ID', response.data?.data?.user_id)
        return Promise.resolve(response)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  updateAccessToken: (context, payload) => {
    return noAuthApi
      .post('refresh-token', {
        data: {
          username: payload.username,
          refreshToken: payload.token,
        },
      })
      .then((response) => {
        if (response.data?.data?.tokens) {
          context.commit('SET_TOKENS', response.data?.data?.tokens)
          return Promise.resolve(response.data?.data?.tokens.access_token)
        }
      })
  },
  setRememberMe: (context, payload) => {
    localStorage.setItem('remember_me', true)
    return api.post('remember-device').then((response) => {
      return Promise.resolve(response)
    })
  },
  forgotPassword: (context, payload) => {
    return api
      .post('forget-password', { data: payload })
      .then((response) => {
        console.log(response)
        if (response.data?.data?.user_id) {
          const user_id = response.data.data.user_id
          context.commit('SET_AUTH_USER_ID', user_id)
        }
        return Promise.resolve(response)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  resetPassword: (context, payload) => {
    return api
      .post('reset-password', { data: payload })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  setCurrentCompanyWithSetting: (context, payload) => {
    context.commit('SET_SELECTED_COMPANY_WITH_SETTING', payload)
  },
  setCurrentCompany: (context, payload) => {
    context.commit('SET_SELECTED_COMPANY', payload)
  },
}

function setCompanySetting(context, user) {
  let selectedCompanySetting
  let userCompanySetting
  if (user.emails) {
    userCompanySetting = user.emails?.find(
      (email) => email.email_address === user.email?.email_address,
    ).user_company_settings
  } else {
    userCompanySetting = user.email.user_company_settings
  }
  if (localStorage.getItem('selected_company_id')) {
    const companyId = parseInt(localStorage.getItem('selected_company_id'))
    if (!companyId) return true
    selectedCompanySetting = userCompanySetting.find((userCompany) => userCompany.company_info?.id === companyId)
  } else {
    selectedCompanySetting = userCompanySetting[0]
  }

  if (!selectedCompanySetting) {
    return true
  }

  context.commit('SET_SELECTED_COMPANY_WITH_SETTING', selectedCompanySetting)
}
