import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/stores'
/* Layouts */
import Layout1 from '../layouts/Layout'
import PublicLayout from '../layouts/PublicLayout'

/* App View */
import Home from '../views/Home'
import Dashboard from '../views/Dashboard'
import RequestsReview from '../views/RequestsReview'
import RequestsReviewDetail from '../views/RequestsReview/DetailView.vue'
import Export from '../views/Export'
import Tutorials from '../views/Tutorials'
import Introduction from '../views/Introduction'

// Components
import TrialBalance from '../views/TrialBalance/index.vue'
import TrialBalanceList from '../views/TrialBalance/ListView.vue'
import TrialBalanceEdit from '../views/TrialBalance/EditView.vue'

// COA
import ChartOfAccounts from '../views/ChartOfAccounts/index.vue'
import ChartOfAccountsListView from '../views/ChartOfAccounts/ListView.vue'
import ChartOfAccountsEditView from '../views/ChartOfAccounts/EditView.vue'

// Inventory
import InventoryView from '../views/Inventory/index.vue'
// List
import InventoryListView from '../views/Inventory/List/index.vue'
import InventoryListNonOptionsView from '../views/Inventory/List/NonOptionsView.vue'
import InventoryListOptionsView from '../views/Inventory/List/OptionsView.vue'
import MergedInventoryView from '../views/Inventory/List/MergedInventoryView.vue'
// Edit
import InventoryEditView from '../views/Inventory/Edit/index.vue'
import InventoryEditNonOptionsView from '../views/Inventory/Edit/NonOptionsView.vue'
import InventoryEditOptionsView from '../views/Inventory/Edit/OptionsView.vue'
// Merged
// import InventoryMergedView from '../views/Inventory/MergedInventoriesView.vue'

// Setting
import Setting from '../views/Setting/index.vue'
import UserProfile from '../views/Setting/UserProfile'
import ConfirmRegisterEmail from '../views/Setting/ConfirmRegisterEmail'
// Company Profile
import CompanyProfileView from '../views/Setting/Company/index.vue'
import GeneralInformationView from '../views/Setting/Company/GeneralInformationView.vue'
import BillingInformationView from '../views/Setting/Company/BillingInformationView.vue'
import OtherInformationView from '../views/Setting/Company/OtherInformationView.vue'
import UserListView from '../views/Setting/Company/UserListView.vue'

// Report
import Report from '../views/Reports'
import FocusView from '../views/Reports/FOCUSView.vue'
import SSOIView from '../views/Reports/SSOIView.vue'
import BalanceSheetView from '../views/Reports/BalanceSheetView.vue'
import IncomeStatementView from '../views/Reports/IncomeStatementView.vue'
import NetCapitalView from '../views/Reports/NetCapitalView.vue'
import NonAllowableAssetsView from '../views/Reports/NonAllowableAssetsView.vue'
import AggregateIndebtednessView from '../views/Reports/AggregateIndebtednessView.vue'
import HaircutSummaryView from '../views/Reports/HaircutSummaryView.vue'
import HaircutBySecurityView from '../views/Reports/HaircutBySecurityView.vue'

// Auth
import SignIn from '../views/Auth/SignInView.vue'
import SignInMFA from '../views/Auth/SignInMFAView.vue'
import ForgotPassword from '../views/Auth/ForgotPasswordView.vue'
import ResetPassword from '../views/Auth/ResetPasswordView.vue'
// Auth/Register
import Register from '../views/Auth/Register/index.vue'
import SendOtp from '../views/Auth/Register/SendOtpView.vue'
import VerifyOtp from '../views/Auth/Register/VerifyOtpView.vue'
import UserForm from '../views/Auth/Register/UserFormView.vue'
import Invite from '../views/Auth/Register/InviteView.vue'

import NotFoundPage from '../views/NotFoundPage'
import NetworkErrorPage from '../views/NetworkErrorPage'
import MaintenanceView from '../views/MaintenanceView.vue'

import LandingPage from '../views/Landing/index.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout1,
      meta: { auth: true },
      children: mainRoutes('app'),
    },
    {
      path: '/landing',
      component: LandingPage,
    },
    {
      path: '/auth',
      name: 'auth-layout',
      component: PublicLayout,
      children: authChildRoutes('auth'),
    },
    {
      path: '*',
      component: NotFoundPage,
    },
    {
      path: '/maintenance',
      component: MaintenanceView,
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    if (!store.getters['auth/loggedIn'] && !localStorage.getItem('access_token')) {
      next({
        path: '/auth/sign-in',
      })
    } else if (!store.getters['auth/loggedIn']) {
      const result = await store.dispatch('auth/getUser')
      if (result === 'no_company_setting') {
        next({
          path: '/auth/sign-in',
        })
      }
      next()
    }
    next()
  } else {
    next()
  }
})

function mainRoutes(prop, mode) {
  return [
    {
      path: '/',
      name: prop + '.home',
      meta: { auth: true, name: 'Home' },
      component: Home,
    },
    {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true },
      component: Dashboard,
    },
    {
      path: 'requests-review',
      name: prop + '.requests-review',
      meta: { auth: true },
      component: RequestsReview,
      beforeEnter: (to, from, next) => {
        if (!to.params.filter) {
          to.params.filter = 'COA'
        }
        next()
      },
    },
    {
      path: 'requests-review-detail/:id/:status',
      name: prop + '.requests-review-detail',
      meta: { auth: true },
      component: RequestsReviewDetail,
    },
    {
      path: '/intro',
      name: prop + '.intro',
      meta: { auth: true, name: 'Introduction' },
      component: Introduction,
    },
    {
      path: 'chart-of-accounts',
      meta: { auth: true },
      component: ChartOfAccounts,
      redirect: { name: 'chart-of-accounts-list' },
      children: [
        {
          path: 'list',
          name: 'chart-of-accounts-list',
          meta: { auth: true },
          component: ChartOfAccountsListView,
        },
        {
          path: 'edit',
          name: 'chart-of-accounts-edit',
          meta: { auth: true },
          component: ChartOfAccountsEditView,
        },
      ],
    },
    {
      path: 'trial-balance',
      meta: { auth: true },
      component: TrialBalance,
      redirect: { name: 'trial-balance-list' },
      children: [
        {
          path: 'list',
          name: 'trial-balance-list',
          meta: { auth: true },
          component: TrialBalanceList,
        },
        {
          path: 'edit',
          name: 'trial-balance-edit',
          meta: { auth: true },
          component: TrialBalanceEdit,
        },
      ],
    },
    {
      path: 'tutorial',
      name: prop + '.tutorial',
      meta: { auth: true },
      component: Tutorials,
    },
    {
      path: 'inventory',
      meta: { auth: true },
      name: 'inventory',
      component: InventoryView,
      children: [
        {
          path: 'list',
          name: 'inventory-list',
          meta: { auth: true },
          component: InventoryListView,
          redirect: { name: 'inventory-list-non-options' },
          children: [
            {
              path: 'non-options',
              name: 'inventory-list-non-options',
              meta: { auth: true },
              component: InventoryListNonOptionsView,
            },
            {
              path: 'options',
              name: 'inventory-list-options',
              meta: { auth: true },
              component: InventoryListOptionsView,
            },
            {
              path: 'merged',
              name: 'inventory-list-merged',
              meta: { auth: true },
              component: MergedInventoryView,
            },
          ],
        },
        {
          path: 'edit',
          name: 'inventory-edit',
          meta: { auth: true },
          component: InventoryEditView,
          redirect: { name: 'inventory-edit-non-options' },
          children: [
            {
              path: 'non-options',
              name: 'inventory-edit-non-options',
              meta: { auth: true },
              component: InventoryEditNonOptionsView,
            },
            {
              path: 'options',
              name: 'inventory-edit-options',
              meta: { auth: true },
              component: InventoryEditOptionsView,
            },
          ],
        },
      ],
    },
    {
      path: 'reports',
      meta: { auth: true },
      name: 'reports',
      component: Report,
      children: [
        {
          path: 'focus',
          name: 'focus',
          meta: { auth: true },
          component: FocusView,
        },
        {
          path: 'ssoi',
          name: 'ssoi',
          meta: { auth: true },
          component: SSOIView,
        },
        {
          path: 'balance-sheet',
          name: 'balance-sheet',
          meta: { auth: true },
          component: BalanceSheetView,
        },
        {
          path: 'income-statement',
          name: 'income-statement',
          meta: { auth: true },
          component: IncomeStatementView,
        },
        {
          path: 'net-capital',
          name: 'net-capital',
          meta: { auth: true },
          component: NetCapitalView,
        },
        {
          path: 'non-allowable-assets',
          name: 'non-allowable-assets',
          meta: { auth: true },
          component: NonAllowableAssetsView,
        },
        {
          path: 'aggregate-indebtedness',
          name: 'aggregate-indebtedness',
          meta: { auth: true },
          component: AggregateIndebtednessView,
        },
        {
          path: 'haircut-summary',
          name: 'haircut-summary',
          meta: { auth: true },
          component: HaircutSummaryView,
        },
        {
          path: 'haircut-by-security',
          name: 'haircut-by-security',
          meta: { auth: true },
          component: HaircutBySecurityView,
        },
      ],
    },
    {
      path: 'export',
      name: prop + '.export',
      meta: { auth: true },
      component: Export,
    },
    {
      path: 'setting',
      name: prop + '.setting',
      meta: { auth: true },
      component: Setting,
      children: [
        {
          path: 'company-profile',
          meta: { auth: true },
          component: CompanyProfileView,
          children: [
            {
              path: 'general-information',
              name: 'general-information',
              meta: { auth: true },
              component: {
                default: GeneralInformationView,
              },
            },
            {
              path: 'billing-information',
              name: 'billing-information',
              meta: { auth: true },
              component: {
                default: BillingInformationView,
              },
            },
            {
              path: 'other-information',
              name: 'other-information',
              meta: { auth: true },
              component: {
                default: OtherInformationView,
              },
            },
            {
              path: 'user-list',
              name: 'user-list',
              meta: { auth: true },
              component: {
                default: UserListView,
              },
            },
          ],
        },
        {
          path: 'user-profile',
          name: prop + '.user-profile',
          meta: { auth: true, name: 'user-profile' },
          component: UserProfile,
          children: [
            {
              path: 'confirm-register-email',
              name: 'confirm-register-email',
              meta: { auth: true, name: 'confirm-register-email' },
              component: ConfirmRegisterEmail,
            },
          ],
        },
      ],
    },
  ]
}

function authChildRoutes(prop, mode = false) {
  return [
    {
      path: 'sign-in',
      name: prop + '.sign-in',
      meta: { dark: false },
      component: SignIn,
    },
    {
      path: 'sign-in-mfa',
      name: prop + '.mfa',
      meta: { dark: mode },
      component: SignInMFA,
    },
    {
      path: 'forgot-password',
      name: prop + '.forgot-password',
      meta: { dark: mode },
      component: ForgotPassword,
    },
    {
      path: 'reset-password',
      name: prop + '.reset-password',
      meta: { dark: mode },
      component: ResetPassword,
    },
    {
      path: '/confirm-invite',
      redirect: (to) => {
        return { path: '/auth/register/invite' }
      },
    },
    {
      path: 'register',
      component: Register,
      children: [
        {
          path: 'send-otp',
          name: 'send-otp',
          component: SendOtp,
        },
        {
          path: 'verify-otp',
          name: 'verify-otp',
          component: VerifyOtp,
        },
        {
          path: 'user-form',
          name: 'user-form',
          component: UserForm,
        },
        {
          path: 'invite',
          name: 'user-invite',
          component: Invite,
        },
      ],
    },
  ]
}

function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@/components/findash/loader/Loader.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 200,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // error: require('@/components/findash/loader/loader.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

export default router
