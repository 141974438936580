<template>
  <b-modal id="user-email-form-modal" ref="modal" title="Register More Email" hide-footer @hidden="resetModal">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Email" invalid-feedback="Email is required">
            <b-form-input v-model="form.email" :state="errors[0] ? false : null"></b-form-input>
          </b-form-group>
        </ValidationProvider>
        <b-row class="mt-4">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button type="button" variant="secondary" @click="$bvModal.hide('user-email-form-modal')">
              Cancel
            </b-button>
            <b-button type="submit" class="ml-2" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserProfileAddEmailModal',
  data() {
    return {
      form: {
        email: '',
      },
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
    }),
    resetModal() {
      this.form.email = ''
    },
    onSubmit() {
      this.setLoading(true)
      this.setDescription('Registering email...')
      this.$api
        .post('/me/register-more-email', {
          data: {
            email: this.form.email,
          },
        })
        .then((res) => {
          this.$showSuccess(res.data.message || 'Successfully updated user profile data.')
          this.$bvModal.hide('user-email-form-modal')
        })
        .catch((error) => {
          const Errors = (Array.isArray(error.response?.data?.error?.details) &&
            error.response?.data?.error?.details) || [{ message: error.response?.data?.error?.message }] || [
              { message: 'Unable to update user profile data.' },
            ]
          Errors.forEach((error) => {
            this.$showError(error.message)
          })
        })
        .finally(() => {
          this.setLoading(false)
          this.setDescription('')
        })
    },
  },
}
</script>
