import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import report from './report'
import loader from './loader'
import company from './company'
import findash from './findash'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    report,
    loader,
    company,
    findash,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug,
})
