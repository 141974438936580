<template>
  <ValidationProvider name="main_coa" v-slot="{ errors }">
    <b-form-group
      label="Chart Of Account (Account Number)"
      label-class="required-asterisk"
      label-for="coa"
      invalid-feedback="Chart Of Account is required"
    >
      <div class="d-flex coa-lists-container" style="gap: 3px">
        <b-form-input
          :value="coa ? coa.account_number : ''"
          :state="errors[0] ? false : null"
          @click="getCOAs()"
          placeholder="Search Chart of Account"
          class="coa-lists-input"
        ></b-form-input>
        <template v-if="coaLists.length">
          <ul class="coa-lists" @scroll="onScroll" ref="coaLists" v-click-outside="closeCoaLists">
            <li v-for="coa in coaLists" :key="coa.coa.account_number" @click="onSelectCOA(coa)">
              {{ coa.coa.account_number }} - {{ coa.coa.account_name }}
            </li>
          </ul>
        </template>
        <!-- clear button -->
        <b-button class="clear-button" @click="clearCOA">Clear</b-button>
      </div>
      <b-form-invalid-feedback :state="errors[0] ? false : null">
        Chart Of Account is required
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'FormModalCoaListDropdown',
  props: {
    coa: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      coaLists: [],
      coaListsPagination: {
        currentPage: 1,
        perPage: 10,
      },
      isCoaLoading: false,
    }
  },
  methods: {
    getCOAs(query = '', page = 1, size = 100) {
      this.isCoaLoading = true
      this.$api
        .get(`/coas?company_id=${this.company.id}`, {
          params: {
            populate: ['balances', 'focus_setting', 'ssoi_setting'],
            filters: {
              coa: {
                account_number: {
                  $contains: query,
                },
              },
            },
            pagination: { pageSize: size, page },
          },
        })
        .then((response) => {
          // response.data.data.forEach((item) => {
          //   this.coaLists.push({
          //     id: item?.id,
          //     time_report: item?.time_report,
          //     description: item?.description,
          //     credit: item?.credit,
          //     debit: item?.debit,
          //     coa: item?.coa,
          //   })
          // })
          // response.data.data.forEach((item) => {
          //   if (item.balances?.length) {
          //     const lastBalance = item.balances[item.balances.length - 1]
          //     this.coaLists.push({
          //       id: lastBalance.id,
          //       time_report: lastBalance.time_report,
          //       description: lastBalance.description,
          //       credit: lastBalance.credit,
          //       debit: lastBalance.debit,
          //       coa: item,
          //     })
          //   } else {
          //     this.coaLists.push({
          //     })
          //   }
          // })
        })
        .catch((error) => {
          console.log(error)
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.isCoaLoading = false
        })
    },
    onScroll() {
      const scrollEl = this.$refs.coaLists
      if (scrollEl.scrollTop + scrollEl.clientHeight >= scrollEl.scrollHeight && !this.isCoaLoading) {
        this.coaListsPagination.currentPage += 1
        this.getCOAs('', this.coaListsPagination.currentPage, this.coaListsPagination.perPage)
      }
    },
    closeCoaLists() {
      this.coaLists = []
      this.coaListsPagination.currentPage = 1
      this.coaListsPagination.perPage = 10
    },
    onSelectCOA(item) {
      this.$emit('onSelect', item)
      this.closeCoaLists()
    },
    clearCOA() {
      this.$emit('onSelect', null)
    },
  },
}
</script>

<style scoped>
.coa-lists-container {
  position: relative;
}

.coa-lists {
  list-style: none;
  background-color: white;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  padding: 1rem;
  z-index: 100;
  top: 3rem;
  border: 1px solid;
}

.coa-lists li {
  padding: 5px;
  cursor: pointer;
}

.coa-lists li:hover {
  background-color: #f1f1f1;
}
</style>
