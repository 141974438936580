<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li
      v-for="(item, index) in filteredHiddenItems"
      :key="index"
      :class="
        item.is_heading
          ? 'iq-menu-title'
          : activeLink(item) && item.children
            ? 'active'
            : activeLink(item)
              ? 'active'
              : ''
      "
    >
      <i v-if="item.is_heading" class="ri-subtract-line" />
      <span v-if="item.is_heading">{{ item.title }}</span>
      <router-link
        @click.native="collapseSidebar(item)"
        :to="item.link"
        v-if="!item.is_heading"
        :class="`iq-waves-effect ${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`"
        v-b-toggle="item.name"
      >
        <i :class="item.icon" v-if="item.is_icon_class" />
        <template v-else v-html="item.icon"></template>
        <span>{{ item.title }}</span>
        <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
        <small v-html="item.append" :class="item.append_class" />
      </router-link>
      <List
        v-if="item.children"
        :items="item.children"
        :open="
          item.link.name !== '' && activeLink(item) && item.children
            ? true
            : !!(item.link.name !== '' && activeLink(item))
        "
        :idName="item.name"
        :accordianName="`sidebar-accordion ${item.class_name}`"
        :className="`iq-submenu ${item.class_name}`"
      />
    </li>
  </b-collapse>
</template>
<script>
import List from './ListStyle1'
import { findash } from '@/plugins/findash'
export default {
  name: 'List',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu collapse show' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'iq-sidebar-toggle' },
    accordianName: { type: String, default: 'sidebar' },
  },
  components: {
    List,
  },
  computed: {
    filteredHiddenItems() {
      return this.items?.filter((item) => !item.is_hidden)
    },
  },
  methods: {
    activeLink(item) {
      return findash.getActiveLink(item, this.$route.name)
    },
    collapseSidebar(item) {
      if (item.link) {
        this.$store.dispatch('findash/toggleSidebar')
      }
    },
  },
}
</script>
