import { mapGetters } from 'vuex'

const mixin = {
  computed: mapGetters({
    user: 'auth/getUser',
    company: 'auth/getSelectedCompany',
    isAdmin: 'auth/getIsAdmin',
  }),
}

export default mixin
