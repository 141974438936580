<template>
  <div>
    <b-modal id="PdfModal" size="xl" :title="pdf.title" @hidden="onClosePdfModal">
      <template v-if="!loading && pdf">
        <div style="height: 100vh">
          <iframe
            :src="`${pdf.src}#toolbar=0`"
            frameborder="0"
            style="overflow: hidden; height: 100%; width: 100%"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </template>
      <template v-if="!loading && !pdf">
        <div class="text-center text-danger my-2">
          <strong class="mx-2">No PDF to display.</strong>
        </div>
      </template>
      <template v-if="loading">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
      <template #modal-footer="{ close }">
        <b-button type="button" variant="primary" @click="close"> CLOSE </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PdfModal',
  props: {
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      loading: 'report/pdfPreviewLoading',
      pdf: 'report/pdf',
    }),
  },
  methods: {
    onClosePdfModal() {
      this.$store.dispatch('report/setPdf', {
        src: '',
        title: '',
      })
    },
  },
}
</script>
