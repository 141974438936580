<template>
  <ValidationProvider :name="adjustmentForm.label" v-slot="{ errors }">
    <b-form-group v-if="adjustmentForm.name === 'adjustment_number'" :invalid-feedback="errors[0]">
      <FormModalCoaListAutoComplete
        :coa="adjustment_number ? adjustment_number.coa : null"
        @onSelect="onSelectCOA"
        @onClear="onClearCOA"
        :ref="'autocompleteContra' + index"
      ></FormModalCoaListAutoComplete>
    </b-form-group>
    <b-form-group v-else :label="adjustmentForm.label" :invalid-feedback="errors[0] || adjustmentAmountError">
      <NumberFormattedInput
        v-model="adjustment_amount"
        :state="errors[0] || adjustmentAmountError ? false : null"
        :error="errors[0] || adjustmentAmountError"
        placeholder="0.00"
        icon
      />
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import NumberFormattedInput from '../NumberFormattedInput.vue'

export default {
  name: 'FormModalContraAdjustment',
  components: { NumberFormattedInput },
  props: {
    index: {
      type: Number,
    },
    adjustmentForm: {
      type: Object,
      default: () => {
        return {}
      },
    },
    adjustmentAmountError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      isSearching: false,
      adjustment_number: '',
      adjustment_amount: 0,
    }
  },
  watch: {
    adjustmentForm: {
      handler(newVal) {
        if (newVal.name === 'adjustment_number') {
          this.adjustment_number = newVal.value
        }
        if (newVal.name === 'adjustment_amount') {
          this.adjustment_amount = newVal.value
        }
      },
      immediate: true,
      deep: true,
    },
    adjustment_amount: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        this.$emit('updateAmount', { value: newVal, index: this.index })
      },
    },
    adjustment_number: {
      handler(newVal) {
        this.$emit('updateNumber', { value: newVal, index: this.index })
      },
    },
  },
  methods: {
    onSelectCOA(item) {
      // console.log(item)
      // if (!item.id) {
      //   this.$refs[`autocompleteContra${this.index}`].$refs.autocomplete.value = ''
      //   this.$showError('Please select another Chart Of Account')
      //   return
      // }
      this.$emit('updateNumber', { value: item, index: this.index })
    },
    onClearCOA() {
      this.$refs[`autocompleteContra${this.index}`].value = ''
      // this.$refs.tbFormModal.refs['Chart Of Account'].setErrors([])
    },
  },
}
</script>
