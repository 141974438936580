<template>
  <div>
    <DetailModal v-bind="detailModalProps" @update="getList" />
    <CardWrapper>
      <template #icon>
        <i class="las la-search" style="font-size: 25px"></i>
      </template>
      <template #title>Pending Reviews</template>
      <template #header>
        <div class="d-flex align-items-end justify-content-between">
          <div>
            <b-form-select :value="filter.type" no-mb :options="filterOptions" @change="updateFilter"></b-form-select>
          </div>
        </div>
      </template>
      <template #body>
        <TheTable :records="listItems" :fields="columns" :loading="isLoading">
          <template #cell(checkbox)="data">
            <b-form-checkbox v-model="selectedRow" :value="data.item" />
          </template>
          <template #cell(avatar)="{ value }">
            <div class="text-center">
              <b-avatar variant="primary" :src="value"></b-avatar>
            </div>
          </template>
          <template #cell(status)="{ item }">
            <BadgeStatus v-if="item.status" :status="item.status">
              {{ item.status }}
            </BadgeStatus>
          </template>
          <template #cell(action)="{ item }">
            <div class="text-center">
              <b-button size="sm" variant="primary" @click="viewDetail(item.id, item.status)"> Details </b-button>
            </div>
          </template>
        </TheTable>
        <TablePagination
          :currentPage="currentPage"
          :rows="rows"
          :perPage="perPage"
          @pagination="handlePagination"
          @entries="handleEntries"
        />
      </template>
    </CardWrapper>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import moment from 'moment'
// import ReviewModal from '@/components/ChartOfAccounts/ReviewModal.vue'
// import ReviewConversationModal from '@/components/ChartOfAccounts/ReviewConversationModal.vue'
import DetailModal from '@/components/Review/DetailModal.vue'

export default {
  name: 'RequestsReview',
  components: {
    DetailModal,
  },
  data: () => ({
    fields: [
      {
        id: 1,
        key: 'id',
        label: '#',
        viewRecord: false,
      },
      {
        id: 2,
        key: 'avatar',
        label: '',
        viewRecord: true,
        formatter: (value, key, item) => {
          console.log(item.user_company_setting?.user_email?.efinop_user?.avatar?.url)
          return item.user_company_setting?.user_email?.efinop_user?.avatar?.url || ''
        },
        size: '80px',
      },
      {
        id: 3,
        key: 'name',
        label: 'Name',
        viewRecord: true,
        formatter: (value, key, item) => {
          const user = item.user_company_setting?.user_email?.efinop_user
          return `${user?.first_name || ''} ${user?.last_name || ''}`
        },
        size: '100px',
      },
      {
        id: 4,
        key: 'createdAt',
        label: 'Request Date',
        viewRecord: true,
        formatter: (value, key, item) => {
          return moment(value).format('DD/MM/YYYY HH:mm A')
        },
        size: '150px',
      },
      {
        id: 5,
        key: 'type',
        label: 'Type',
        viewRecord: false,
        formatter: (value) => {
          return value?.toUpperCase() || ''
        },
        size: '140px',
      },
      {
        id: 6,
        key: 'email',
        label: 'Email',
        viewRecord: true,
        formatter: (value, key, item) => {
          return item.user_company_setting?.user_email?.email_address || ''
        },
        size: '150px',
      },
      {
        id: 7,
        key: 'phone_number',
        label: 'Phone Number',
        viewRecord: true,
        formatter: (value, key, item) => {
          const user = item.user_company_setting?.user_email?.efinop_user
          return user?.phone_number || ''
        },
        size: '150px',
      },
      {
        id: 8,
        key: 'status',
        label: 'Status',
        viewRecord: true,
        size: '100px',
      },
      {
        id: 9,
        key: 'action',
        label: '',
        viewRecord: true,
        size: '100px',
      },
    ],
    listItems: [],
    currentPage: 1,
    rows: 0,
    perPage: 50,
    fetchStatus: 'idle',
    sort: {
      sortBy: 'name',
      sortDesc: false,
    },
    review_modal_review_id: null,
    conversation_modal_review_id: null,
    reviewId: null,
    reviewStatus: null,
    selectedRow: null,
    filterOptions: [
      {
        text: 'Chart of Accounts',
        value: 'COA',
      },
      {
        text: 'Trial Balance',
        value: 'BALANCE',
      },
      {
        text: 'Haircut Detail',
        value: 'HAIRCUT_DETAIL',
      },
      {
        text: 'Haircut Option',
        value: 'HAIRCUT_OPTION',
      },
    ],
    filter: {
      type: 'COA',
    },
  }),
  computed: {
    columns() {
      return this.fields.filter((item) => item.viewRecord)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
    detailModalProps() {
      return {
        id: this.reviewId,
        status: this.reviewStatus,
        type: this.filter.type,
      }
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.filter.type = this.$route.query.filter
    }
    this.getList()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setLoadingDesc: 'loader/setDescription',
    }),
    async getList() {
      this.fetchStatus = 'pending'
      try {
        const params = {
          params: {
            pagination: {
              page: this.currentPage,
              pageSize: this.perPage,
            },
            sort: ['id:asc'],
            populate: [
              'user_company_setting',
              'user_company_setting.user_email',
              'user_company_setting.user_email.efinop_user',
              'user_company_setting.user_email.efinop_user.avatar',
            ],
            filters: {
              type: this.filter.type,
            },
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get(`/review-requests`, params)
        if (response.status === 200 && response.data?.data && response.data?.pagination) {
          this.fetchStatus = 'success'
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.listItems = response.data.data
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
    handleStatus(id, status) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to ' + status + ' this request?', {
          title: 'Confirmation',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === false) {
            return
          }
          if (value) {
            this.setLoading(true)
            this.$api
              .get(`/review-requests/${id}?company_id=${this.company.id}&type=${status}`)
              .then(() => {
                this.getList()
                this.selectedRow = null
                this.$showSuccess('Request Updated Successfully')
              })
              .catch((err) => {
                this.$showError(err.response?.data?.error?.message || 'Unable to update the request')
              })
              .finally(() => {
                this.setLoading(false)
              })
          }
        })
    },
    viewCOA(id) {
      this.review_modal_review_id = null
      this.$bvModal.show('coa-review-modal')
      this.$nextTick(() => {
        this.review_modal_review_id = id
      })
    },
    viewConversation(id) {
      this.conversation_modal_review_id = null
      this.$bvModal.show('conversation-modal')
      this.$nextTick(() => {
        this.conversation_modal_review_id = id
      })
    },
    viewDetail(id, status) {
      this.reviewId = null
      this.reviewStatus = status
      this.$nextTick(() => {
        this.reviewId = id
      })
      this.$bvModal.show('review-detail-modal')
    },
    updateFilter(e) {
      this.filter.type = e
      if (this.filter.type !== this.$route.query.filter) {
        this.$router.push({
          query: {
            filter: this.filter.type,
          },
        })
      }
      this.getList()
    },
  },
}
</script>
