<template>
  <div class="d-flex flex-column" style="gap: 15px">
    <TableActionsWrapper>
      <template #options>
        <label class="mr-2 mt-1">Report Date</label>
        <b-form-group class="mb-0">
          <b-form-select v-model="reportDate" :options="reportDates" class="mb-0" @change="getList">
            <b-form-select-option v-if="reportDates.length === 0" value=""> No Report Date </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </template>

      <template #pagination>
        <CommonPerPage :perPage="perPage" @change="handleEntries" />
      </template>

      <template #actions>
        <b-button variant="primary" to="/inventory/edit/options"> Edit Options </b-button>
      </template>
    </TableActionsWrapper>

    <TheTable
      :records="records"
      :fields="listTableFields"
      :loading="isLoading"
      @sort-changed="handleSortChange"
      no-local-sorting
    >
      <template #cell(status)="{ item }">
        <BadgeStatus :status="item.status">
          {{ item.status }}
        </BadgeStatus>
      </template>
    </TheTable>
    <TablePagination
      :currentPage="currentPage"
      :rows="rows"
      :perPage="perPage"
      @pagination="handlePagination"
      @entries="handleEntries"
    />
  </div>
</template>

<script>
// PDF
import JSONfn from 'json-fn'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

import { dateFormatter } from '@/utils/helpers'
import { haircutFields } from '../fields'

export default {
  name: 'InventoryListView',
  data() {
    return {
      reportDate: '',
      reportDates: [],
      records: [],
      currentPage: 1,
      rows: 0,
      perPage: 50,
      fetchStatus: 'idle',
      sort: {
        sortBy: '',
        sortDesc: false,
      },
    }
  },
  computed: {
    listTableFields() {
      return haircutFields.filter((field) => field.listTable)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
  },
  async created() {
    await this.getAvailableReportDates()
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.fetchStatus = 'pending'
        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && { sort: [`${this.sort.sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}`] }),
            ...(this.reportDate && { filters: { report_date: this.reportDate } }),
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/options-inventories', params)
        if (response.status === 200 && response.data?.data && response.data?.pagination) {
          this.fetchStatus = 'success'
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.records = response.data.data
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    async getAvailableReportDates() {
      this.fetchStatus = 'pending'
      try {
        const response = await this.$api.get(`/options-inventories/available-report-date?company_id=${this.company.id}`)
        if (response.status === 200 && response.data?.data) {
          this.fetchStatus = 'success'
          this.reportDates = response.data.data.map((report) => {
            return {
              value: report.report_date,
              text: dateFormatter(report.report_date),
            }
          })
          this.reportDate = this.reportDates[0]?.value || ''
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
    async downloadReport() {
      try {
        const response = await this.$api.get('/options-inventories', {
          params: {
            filters: {
              report_date: this.reportDate || null,
            },
            company_id: this.company.id,
            download: true,
          },
        })
        if (response.status === 200 && response.data?.data) {
          const {
            data: { tableLayout, docDefinition },
          } = response.data

          const table = docDefinition.content.find((ob) => ob.table)
          let layout
          if (table) {
            try {
              eval(`layout = ${table.layout}`)
            } catch (error) {
              eval(`layout = "${table.layout}"`)
            }

            table.layout = layout
          }
          const reportName = this.reportDate
            ? new Date(this.reportDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : 'All'
          pdfMake.createPdf(docDefinition).download(`Inventory_${reportName}`)
        }
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      }
    },
  },
}
</script>
