<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Sign Up</h1>
    <p class="auth-description">Please enter your Mobile Number and Password.</p>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <!-- <div class="mt-3">
        <b-alert show fade variant="primary">
          <p class="mb-0">
            <i class="fas fa-info-circle"></i>&nbsp;
            Password must contain at least 6 characters, including at least one letter, one number and one special
            character.
          </p>
        </b-alert>
      </div> -->

      <form class="mt-3" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider vid="mobile" name="Mobile" rules="required" v-slot="{ errors }">
          <div class="d-flex justify-content-start">
            <div class="col-md-2 p-0" style="height: 45px">
              <country-select @onSelectCountry="onSelect" @click.native="$refs.form.reset()"></country-select>
            </div>
            <b-form-group class="col-md-10 pl-4 pr-0 mb-3" invalid-feedback="Phone Number is required">
              <b-form-input
                type="number"
                v-model="formData.mobile_number"
                id="mobile"
                placeholder="Mobile Number"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </div>
        </ValidationProvider>

        <ValidationProvider vid="password" name="Password" rules="required|custom-password" v-slot="{ errors }">
          <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3" :state="errors[0] ? false : null">
            <b-form-input
              type="password"
              v-model="formData.password"
              id="password"
              placeholder="Password"
              :state="errors[0] ? false : null"
            >
            </b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          vid="confirm"
          name="Confirm Password"
          :rules="{
            required: true,
            confirmed: formData.password,
          }"
          v-slot="{ errors }"
        >
          <b-form-group invalid-feedback="Confirm Password is not matched" class="col p-0 mb-3">
            <b-form-input
              type="password"
              v-model="formData.confirm_password"
              id="confirm_password"
              placeholder="Confirm Password"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </ValidationProvider>

        <div class="form-group">
          <div class="d-inline-block w-100 text-dark text-center">
            <b-form-checkbox id="super_admin" v-model="formData.super_admin" name="super_admin">
              Are you super admin to manage this account?
            </b-form-checkbox>
          </div>
        </div>

        <div
          class="d-inline-block w-100"
          style="display: flex !important; justify-content: center; margin-bottom: 15px"
        >
          <vue-recaptcha :sitekey="sitekey" :loadRecaptchaScript="true" @verify="validateCaptcha"></vue-recaptcha>
        </div>

        <b-overlay
          class="d-inline-block mt-3 w-100"
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button type="submit" :disabled="isLoading" variant="primary" class="w-100"> REGISTER </b-button>
        </b-overlay>

        <div class="d-inline-block w-100 mt-3 text-center">
          <span class="text-dark">Already Have Account?</span>
          <router-link to="/auth/sign-in" class="iq-waves-effect pl-1 text-underline"> Sign in </router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'SendOtpView',
  components: {
    VueRecaptcha,
  },
  data: () => ({
    formData: {
      mobile_number: '',
      country_code: '',
      password: '',
      confirm_password: '',
      super_admin: true,
    },
    isLoading: false,
    // sitekey: '6LcslMceAAAAACcF4Yf1Mk2LEKvHiJAc0iyZ3p4t',
    sitekey: '6Lc8-3AoAAAAAKRTarLfS02aAm2dZEoGHm-46xk6',
    recaptcha: true,
  }),
  watch: {
    formData: {
      handler(val) {
        this.$refs.form.reset()
      },
      deep: true,
    },
  },
  methods: {
    onSelect(dialCode) {
      this.formData.country_code = `+${dialCode}`
    },
    validateCaptcha(emit) {
      this.recaptcha = !!emit
    },
    onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch('auth/sendRegisterOTP', {
          mobileNumber: this.formData.mobile_number,
          countryCode: this.formData.country_code,
          password: this.formData.password,
          superAdmin: this.formData.super_admin,
        })
        .then((res) => {
          this.$showSuccess(res?.data?.message || 'OTP sent successfully')
          // this.$router.push('/auth/register/user-form')
          // should go to verify otp but for now go to user form
          this.$router.push('user-form')
        })
        .catch((error) => {
          this.$showError(
            error?.response?.data?.error?.details?.message ||
              error?.response?.data?.error?.message ||
              'Something went wrong',
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
