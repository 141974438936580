<template>
  <div class="d-flex flex-column" style="gap: 15px">
    <TableActionsWrapper>
      <template #options>
        <label class="mr-2 mt-1">Report Date</label>
        <b-form-group class="mb-0">
          <b-form-select v-model="reportDate" :options="reportDates" class="mb-0" @change="getList">
            <template #first>
              <b-form-select-option value=""> All Report Dates </b-form-select-option>
            </template>
            <b-form-select-option v-if="reportDates.length === 0" value=""> No Report Date </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </template>

      <template #pagination>
        <CommonPerPage :perPage="perPage" @change="handleEntries" />
      </template>

      <template #actions>
        <div>
          <b-button variant="danger" @click="isDeleteMode = !isDeleteMode">
            <span v-if="isDeleteMode">Exit</span> Delete Mode
          </b-button>
          <template v-if="!isDeleteMode">
            <b-button variant="primary" class="ml-1" @click="downloadTemplate"> Download Template </b-button>
            <b-button variant="primary" class="ml-1" @click="onAdd"> Add Inventory </b-button>
            <b-button variant="primary" class="ml-1" v-b-modal.haircut-detail-import-modal> Import Inventory </b-button>
            <b-button class="ml-1" variant="primary" @click="onSave" :disabled="modifiedItems.length === 0">
              Save Inventory
            </b-button>
            <b-button variant="primary" class="ml-1" @click="onMarkDone"> Mark Done </b-button>
          </template>
          <template v-else>
            <b-button variant="primary" class="ml-1" @click="saveDeleteItems" :disabled="deleteItems.length === 0">
              Confirm Delete
            </b-button>
          </template>
        </div>
      </template>
    </TableActionsWrapper>

    <TheTable
      :records="records"
      :fields="editTableFields"
      :loading="isLoading"
      @sort-changed="handleSortChange"
      selectable
      @row-selected="onRowSelected"
      no-local-sorting
    >
      <template #head(checkbox)="data">
        <b-form-checkbox @change="(e) => toggleSelectAll(e, data)"></b-form-checkbox>
      </template>
      <template #cell(checkbox)="{ item }">
        <b-form-checkbox v-model="deleteItems" :value="item"></b-form-checkbox>
      </template>
      <template #cell(action)="{ item }">
        <div class="d-flex flex-wrap justify-content-center" style="gap: 5px">
          <b-button v-if="!item.is_deleted" size="sm" variant="primary" @click="onEdit(item)"> Edit </b-button>
          <b-button v-if="canRevert(item)" class="ml-2" size="sm" variant="warning" @click="revertItem(item)">
            Revert
          </b-button>
          <b-button size="sm" class="ml-2" v-if="item.valid_at === null" variant="warning" @click="onRestore(item)">
            Restore
          </b-button>
        </div>
      </template>
    </TheTable>
    <TablePagination
      :currentPage="currentPage"
      :rows="rows"
      :perPage="perPage"
      @pagination="handlePagination"
      @entries="handleEntries"
    />
    <FormModal :mode="mode" :haircut-data="haircutData" @hc-added="onAdded" @hc-updated="onEdited" />
    <ImportModal @uploaded="onUploaded" />
  </div>
</template>

<script>
import FormModal from '@/components/Inventory/EditInventoryFormModal.vue'
import ImportModal from '@/components/Inventory/EditInventoryImportModal.vue'
import { mapActions } from 'vuex'
import { inventoryFields } from '../fields'
import { dateFormatter } from '@/utils/helpers'

export default {
  name: 'InventoryEditView',
  components: {
    FormModal,
    ImportModal,
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.isSavedItemsNotMarkedDone) {
  //     this.$bvModal.msgBoxConfirm('You haven’t mark as done your changes. Admin will not be able to see your changes. Proceed?', {
  //       title: 'Please Confirm',
  //       size: 'sm',
  //       buttonSize: 'sm',
  //       okVariant: 'primary',
  //       okTitle: 'Leave',
  //       cancelTitle: 'Cancel',
  //       footerClass: 'p-2',
  //       hideHeaderClose: false,
  //       centered: true,
  //     })
  //       .then(value => {
  //         if (value) {
  //           next()
  //         } else {
  //           next(false)
  //         }
  //       }).catch(err => {
  //         console.log(err)
  //       })
  //   } else {
  //     next()
  //   }
  // },
  data: () => ({
    reportDate: '',
    reportDates: [],
    isDeleteMode: false,
    records: [],
    deleteItems: [],
    modifiedItems: [],
    originalItems: [],
    mode: 'add',
    haircutData: null,
    currentPage: 1,
    rows: 0,
    perPage: 50,
    fetchStatus: 'idle',
    sort: {
      sortBy: 'id',
      sortDesc: true,
    },
  }),
  watch: {
    isDeleteMode() {
      this.$refs.table?.$children[0]?.clearSelected()
    },
  },
  computed: {
    editTableFields() {
      return this.isDeleteMode
        ? inventoryFields.filter((_) => _.editTable && _.key !== 'action')
        : inventoryFields.filter((_) => _.editTable && _.key !== 'checkbox')
    },
    isSavedItemsNotMarkedDone() {
      return this.records.some((item) => item.efinop_user?.id === this.user.id && item.review_request === null)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
    formModalData() {
      return {
        mode: this.mode,
        haircutData: this.haircutData,
      }
    },
  },
  async created() {
    await this.getAvailableReportDates()
    this.getList()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
    }),
    async getList() {
      try {
        this.fetchStatus = 'pending'
        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && { sort: [{ [this.sort.sortBy]: this.sort.sortDesc ? 'desc' : 'asc' }] }),
            ...(this.reportDate && { filters: { report_date: this.reportDate } }),
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/non-options-inventories/edit-mode', params)
        if (response.data?.data && response.data?.pagination) {
          this.fetchStatus = 'success'
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.records = response.data?.data
          this.records.forEach((item) => {
            if (item.efinop_user?.id === this.user.id && item.valid_at === null) {
              switch (item.is_deleted) {
                case null:
                  item._rowVariant = 'warning'
                  break
                case false:
                  item._rowVariant = 'warning'
                  break
                case true:
                  item._rowVariant = 'danger'
                  break
              }
            }
          })
          if (this.modifiedItems.length > 0) {
            this.modifiedItems.forEach((item) => {
              const index = this.records.findIndex((listItem) => listItem.id === item.id)
              if (index === -1) {
                this.records.unshift({
                  ...item,
                  _rowVariant: 'warning',
                })
              } else {
                this.records[index] = {
                  ...this.records[index],
                  ...item,
                  _rowVariant: 'warning',
                }
              }
            })
          }
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    async getAvailableReportDates() {
      this.fetchStatus = 'pending'
      try {
        const response = await this.$api.get(
          `/non-options-inventories/available-report-date?company_id=${this.company.id}`,
        )
        if (response.status === 200 && response.data?.data) {
          this.fetchStatus = 'success'
          this.reportDates = response.data.data.map((report) => {
            return {
              value: report.report_date,
              text: dateFormatter(report.report_date),
            }
          })
          // this.reportDate = this.reportDates[0]?.value || ''
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
    onAdd() {
      this.mode = 'add'
      this.haircutData = null
      this.$bvModal.show('non-options-inventory-form-modal')
    },
    onEdit(item) {
      this.mode = 'edit'
      this.haircutData = {
        ...item,
      }
      this.$bvModal.show('non-options-inventory-form-modal')
    },
    onEdited(editedForm) {
      if (!editedForm) return
      const modifiedItemIdx = this.modifiedItems.findIndex((_) => _.id === editedForm.id)
      if (modifiedItemIdx > -1) {
        if (!this.modifiedItems[modifiedItemIdx].valid_at === null) {
          this.modifiedItems[modifiedItemIdx].updatedAt = new Date().toISOString()
        }
        this.modifiedItems[modifiedItemIdx] = {
          ...this.modifiedItems[modifiedItemIdx],
          ...editedForm,
        }
      } else {
        this.modifiedItems.push({
          ...editedForm,
        })
      }

      this.records = this.records.map((_) => {
        if (_.id === editedForm.id) {
          this.originalItems.push({
            ..._,
          })
          return {
            ..._,
            ...editedForm,
            _rowVariant: 'warning',
          }
        }
        return _
      })
      console.log(this.records)
    },
    onAdded(form) {
      if (!form) return
      form.id = Math.floor(Math.random() * 1000)
      this.modifiedItems.push({
        ...form,
        updatedAt: new Date().toISOString(),
        isNew: true,
      })

      this.records.unshift({
        ...form,
        isNew: true,
        _rowVariant: 'warning',
      })
    },
    async onSave() {
      if (this.modifiedItems.length > 0) {
        this.setLoading(true)
        this.setDescription('Saving Non-Options Inventory...')
        const data = this.modifiedItems.map((record) => {
          if (record.isNew) {
            return {
              identifier: record.identifier,
              cusipIsin: record.cusip_isin,
              type: record.type,
              subtype: record.subtype,
              currency: record.currency,
              qty: record.qty,
              purchPr: record.purch_pr || 0,
              longOrShort: record.long_or_short,
              closingPrice: record.closing_price,
              maturity: record.maturity,
              totPosVal: record.tot_pos_val,
              haircutAmount: record.haircut_amount || 0,
              bondsFaceVal: record.bonds_face_val,
              updatedAt: new Date(record.updatedAt).toISOString(),
              reportDate: record.report_date,
            }
          }
          return {
            id: record.id,
            identifier: record.identifier,
            cusipIsin: record.cusip_isin,
            type: record.type,
            subtype: record.subtype,
            currency: record.currency,
            qty: record.qty,
            purchPr: record.purch_pr || 0,
            longOrShort: record.long_or_short,
            closingPrice: record.closing_price,
            maturity: record.maturity,
            totPosVal: record.tot_pos_val,
            haircutAmount: record.haircut_amount || 0,
            bondsFaceVal: record.bonds_face_val,
            reportDate: record.report_date,
          }
        })

        try {
          await this.$api.put(`/non-options-inventories?company_id=${this.company.id}`, {
            data,
          })
          this.$showSuccess('Records updated successfully.')
          this.modifiedItems = []
          await this.getAvailableReportDates()
          this.getList()
        } catch (error) {
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        } finally {
          this.setLoading(false)
          this.setDescription('')
        }
      }
    },
    async onMarkDone() {
      this.setLoading(true)
      this.setDescription('Marking Non-Options Inventory as Done...')
      try {
        await this.$api.get(`/non-options-inventories/mark-done?company_id=${this.company.id}`)
        this.$showSuccess('Records updated successfully.')
        await this.getAvailableReportDates()
        this.getList()
        this.modifiedItems = []
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      } finally {
        this.setLoading(false)
        this.setDescription('')
      }
    },
    async downloadTemplate() {
      try {
        const response = await this.$api.get('/sample/download', {
          params: {
            type: 'HAIRCUT_DETAIL',
            company_id: this.company.id,
          },
        })
        if (response.status === 200 && response.data?.data?.url) {
          this.$showSuccess('Downloaded successfully.')
          window.open(response.data.data.url, '_blank')
        }
      } catch (error) {
        this.$showError(error.response?.data?.error?.message || 'Something went wrong')
      }
    },
    onRestore(item) {
      this.$bvModal
        .msgBoxConfirm('This record will be restored. Are you sure?', {
          title: 'Restore',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.setLoading(true)
            this.setDescription('Restoring record...')
            try {
              const response = await this.$api.put(`/non-options-inventories?company_id=${this.company.id}`, {
                data: [
                  {
                    id: item.id,
                    haircutAmount: item.haircut_amount,
                    reportDate: item.report_date,
                    identifier: item.identifier,
                    cusipIsin: item.cusip_isin,
                    nameDescription: item.name_description,
                    type: item.type,
                    qty: item.qty,
                    purchPr: item.purch_pr,
                    longOrShort: item.long_or_short,
                    closingPrice: item.closing_price,
                    maturity: item.maturity,
                    bondsFaceVal: item.bonds_face_val,
                    totPosVal: item.tot_pos_val,
                    subtype: item.subtype,
                    currency: item.currency,
                    isUndo: true,
                  },
                ],
              })

              if (response.status === 200) {
                this.modifiedItems = this.modifiedItems.filter((record) => record.id !== item.id)
                this.$showSuccess('Record restored successfully.')
                await this.getAvailableReportDates()
                this.getList()
              }
            } catch (error) {
              this.$showError(error.response?.data?.error?.message || 'Something went wrong')
            } finally {
              this.setLoading(false)
            }
          }
        })
    },
    canRevert(item) {
      return this.modifiedItems.findIndex((record) => record.id === item.id) > -1 && item.valid_at !== null
    },
    revertItem(item) {
      const index = this.modifiedItems.findIndex((record) => record.id === item.id)
      if (index > -1) {
        this.modifiedItems.splice(index, 1)
      }
      if (item.isNew) {
        const index = this.records.findIndex((record) => record.id === item.id)
        if (index > -1) {
          this.records.splice(index, 1)
          return
        }
      }
      const originalItem = this.originalItems.find((record) => record.id === item.id)
      if (originalItem) {
        this.$set(
          this.records,
          this.records.findIndex((record) => record.id === item.id),
          originalItem,
        )
      }
      this.originalItems = this.originalItems.filter((record) => record.id !== item.id)
    },
    toggleSelectAll(value, data) {
      value ? data.selectAllRows() : data.clearSelected()
    },
    onRowSelected(items) {
      this.deleteItems = items
    },
    saveDeleteItems() {
      this.$bvModal
        .msgBoxConfirm('This records will be saved as deleted. Are you sure?', {
          title: 'Confirm Delete',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.setLoading(true)
            this.setDescription('Saving deleted record...')

            try {
              const data = this.deleteItems
                .filter((record) => !record.isNew)
                .map((record) => ({
                  id: record.id,
                  haircutAmount: record.haircut_amount,
                  identifier: record.identifier,
                  cusipIsin: record.cusip_isin,
                  nameDescription: record.name_description,
                  type: record.type,
                  qty: record.qty,
                  purchPr: record.purch_pr,
                  longOrShort: record.long_or_short,
                  closingPrice: record.closing_price,
                  maturity: record.maturity,
                  bondsFaceVal: record.bonds_face_val,
                  totPosVal: record.tot_pos_val,
                  subtype: record.subtype,
                  currency: record.currency,
                  reportDate: record.report_date,
                  isDeleted: true,
                }))

              if (data?.length > 0) {
                await this.$api.put(`/non-options-inventories?company_id=${this.company.id}`, {
                  data,
                })
              }
              this.modifiedItems = this.modifiedItems.filter(
                (record) => !this.deleteItems.some((_) => _.id === record.id),
              )
              this.$showSuccess('Record updated successfully.')
              this.deleteItems = []
              this.isDeleteMode = false
              await this.getAvailableReportDates()
              this.getList()
            } catch (error) {
              this.$showError(error.response?.data?.error?.message || 'Something went wrong')
            } finally {
              this.setLoading(false)
            }
          }
        })
    },
    async onUploaded() {
      await this.getAvailableReportDates()
      this.getList()
    },
  },
}
</script>
