<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Sign Up</h1>
    <p class="auth-description">Please fill the details below.</p>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <div style="max-height: 55vh; overflow-y: auto">
          <ValidationProvider vid="phoneNumber" name="Mobile" rules="required" v-slot="{ errors }">
            <div class="d-flex justify-content-start">
              <div class="col-md-2 p-0" style="height: 45px">
                <country-select @onSelectCountry="onSelect" @click.native="$refs.form.reset()"></country-select>
              </div>
              <b-form-group class="col-md-10 pl-4 pr-0 mb-3" invalid-feedback="Phone Number is required">
                <b-form-input
                  type="number"
                  v-model="formData.phoneNumber"
                  id="phoneNumber"
                  placeholder="Mobile Number"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </div>
          </ValidationProvider>

          <ValidationProvider vid="firstName" name="First Name" rules="required" v-slot="{ errors }">
            <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.firstName"
                id="firstName"
                placeholder="First Name"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="middleName" name="Middle Name">
            <b-form-group>
              <b-form-input type="text" v-model="formData.middleName" id="middleName" placeholder="Middle Name" />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="lastName" name="Last Name" rules="required" v-slot="{ errors }">
            <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.lastName"
                id="lastName"
                placeholder="Last Name"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="userTitle" name="User Title" rules="required" v-slot="{ errors }">
            <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.userTitle"
                id="userTitle"
                placeholder="User Title"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="password" name="password" rules="custom-password|required" v-slot="{ errors }">
            <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3">
              <b-form-input
                type="password"
                autocomplete="new-password"
                v-model="formData.password"
                id="password"
                :state="errors[0] ? false : null"
                placeholder="Password"
              >
              </b-form-input>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            vid="repass"
            name="Repeat Password"
            :rules="{
              required: true,
              confirmed: formData.password,
            }"
            v-slot="{ errors }"
          >
            <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3">
              <b-form-input
                type="password"
                v-model="formData.confirmPassword"
                id="confirmPassword"
                placeholder="Repeat Password"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </div>
        <div style="display: flex !important; justify-content: center; margin-top: 15px">
          <b-overlay
            class="d-inline-block mt-3 w-100"
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <b-button type="submit" :disabled="isLoading" variant="primary" class="w-100"> REGISTER </b-button>
          </b-overlay>
        </div>

        <div class="d-inline-block w-100 mt-3 text-center">
          <span class="text-dark">Already Have Account?</span>
          <router-link to="/auth/sign-in" class="iq-waves-effect pl-1 text-underline"> Sign in </router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'RegisterInviteView',
  data: () => ({
    isLoading: false,
    formData: {
      firstName: '',
      middleName: '',
      lastName: '',
      userTitle: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      token: '',
      dialCode: '',
    },
  }),
  created() {
    if (!this.$route.query.token) {
      this.$router.replace('/auth/sign-in')
    }
    this.formData.token = this.$route.query.token
    this.formData.firstName = this.$route.query.first_name || ''
    this.formData.middleName = this.$route.query.middle_name || ''
    this.formData.lastName = this.$route.query.last_name || ''
    this.formData.userTitle = this.$route.query.user_title || ''
  },
  watch: {
    formData: {
      handler(val) {
        this.$refs.form.reset()
      },
      deep: true,
    },
  },
  methods: {
    async onSelect(dialCode) {
      this.formData.dialCode = `+${dialCode}`
    },
    async onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch('auth/registerInvite', {
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          middleName: this.formData.middleName,
          userTitle: this.formData.userTitle,
          phoneNumber: `${this.formData.dialCode}${this.formData.phoneNumber}`,
          password: this.formData.password,
          token: this.formData.token,
        })
        .then((res) => {
          this.$showSuccess(res.data?.message || 'Registered Successfully')
          this.$router.replace('/register/verify-otp?isInvite=true')
        })
        .catch((error) => {
          this.$showError(error.response?.data?.error?.message || error.response?.data?.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
