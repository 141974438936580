import Vue from 'vue'
import { IconsPlugin, BToast } from 'bootstrap-vue'

const alertPlugin = {
  install(Vue, options) {
    Vue.prototype.$showSuccess = function (message, title = 'Success') {
      let bootStrapToaster = new BToast()
      bootStrapToaster.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-top-right',
        solid: true,
        variant: 'success',
      })
    }

    Vue.prototype.$showWarning = function (message) {
      let bootStrapToaster = new BToast()
      bootStrapToaster.$bvToast.toast(message, {
        title: 'Warning',
        toaster: 'b-toaster-top-right',
        solid: true,
        variant: 'warning',
      })
    }

    Vue.prototype.$showInfo = function (message) {
      let bootStrapToaster = new BToast()
      bootStrapToaster.$bvToast.toast(message, {
        title: 'Info',
        toaster: 'b-toaster-top-right',
        solid: true,
        variant: 'info',
        appendToast: true,
      })
    }

    Vue.prototype.$showError = function (message, title = 'Error') {
      let bootStrapToaster = new BToast()
      bootStrapToaster.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-top-right',
        solid: true,
        variant: 'danger',
        appendToast: true,
      })
    }
  },
}

Vue.use(alertPlugin)
Vue.use(IconsPlugin)
