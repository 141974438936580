<template>
  <div>
    <!-- nav -->
    <header class="py-4">
      <div class="container">
        <nav class="navbar">
          <div class="navbar-logo">
            <div class="navbar-logo-links">
              <!-- <a href="#features">Features</a> -->
              <!-- <a href="#testimonials">Testimonials</a> -->
              <!-- <a href="#pricing">Pricing</a> -->
            </div>
          </div>
          <div class="navbar-actions">
            <router-link to="/auth/sign-in">Sign in</router-link>
            <b-button type="button" variant="primary" to="/auth/register/send-otp">Get Started</b-button>
            <!-- <div class="-mr-1 md:hidden"> -->
            <!--   <MobileNavigation /> -->
            <!-- </div> -->
          </div>
        </nav>
      </div>
    </header>
    <!-- hero -->
    <div class="container">
      <div class="hero">
        <div class="logo">
          <img :src="logo" alt="logo" width="120" height="120" />
          <span>eFINOP</span>
        </div>
        <div class="features">
          <div>
            <div class="features-wrapper">
              <img :src="checkmark" alt="checkmark" width="30" />
              <span>Automate your Broker-Dealer ’s FOCUS and SSOI Reports</span>
            </div>
            <div class="features-wrapper">
              <img :src="checkmark" alt="checkmark" width="30" />
              <span>Finish your FOCUS and SSOI reports in minutes with all relevant supporting workpapers</span>
            </div>
            <div class="features-wrapper">
              <img :src="checkmark" alt="checkmark" width="30" />
              <span>Prevent errors</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../../assets/images/logo.png'
import CheckMarkSVG from '../../components/icons/checkmark.svg'

export default {
  name: 'Landing',
  data() {
    return {
      logo: logo,
      checkmark: CheckMarkSVG,
    }
  },
}
</script>

<style scoped>
.navbar {
  position: relative;
  z-index: 50;
  display: flex;
  justify-content: space-between;
}

.navbar-logo-links {
  display: flex;
  gap: 1rem;
}

.navbar-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hero {
  padding-top: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  text-align: center;
  max-width: 80rem;
}

.hero .logo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.hero .logo span {
  font-size: 2rem;
  font-weight: 700;
  color: #1d3765;
}

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.features-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.features-wrapper span {
  font-size: 1.25rem;
  font-weight: 500;
  color: #1d3765;
}
</style>
