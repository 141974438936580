<template>
  <div>
    <div class="d-flex align-items-center" style="flex: 1 1 auto; gap: 10px">
      <b-form-select
        v-model="period"
        @change="onSelectChange"
        :options="periodOptions"
        size="md"
        class="mb-0 w-75"
        :state="state"
      >
      </b-form-select>

      <b-form-select v-model="selectedYear" @change="onYearChange" :options="yearOptions" size="md" class="mb-0 w-25">
      </b-form-select>
    </div>
    <!-- error message -->
    <b-form-invalid-feedback :state="state"> Please select one </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: 'FormTimeReportInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedYear: parseInt(new Date().getFullYear().toString().substr(-2)),
      period: null,
    }
  },
  computed: {
    yearOptions() {
      let options = []
      for (let i = 0; i < 5; i++) {
        options.push({
          value: parseInt(new Date().getFullYear().toString().substr(-2)) - i,
          text: parseInt(new Date().getFullYear().toString()) - i,
        })
      }
      return options
    },
    periodOptions() {
      let options = []
      if (this.focusReportingFrequency === 'Monthly') {
        for (let i = 0; i < 12; i++) {
          options.push({
            value: new Date(new Date().getFullYear(), i, 1).toLocaleString('default', { month: 'short' }).toUpperCase(),
            text: new Date(new Date().getFullYear(), i, 1).toLocaleString('default', { month: 'short' }).toUpperCase(),
          })
        }
      } else if (this.focusReportingFrequency === 'Quarterly') {
        for (let i = 0; i < 4; i++) {
          options.push({
            value: `Q${i + 1}`,
            text: `Q${i + 1}`,
          })
        }
      } else {
        options = []
      }
      return options
    },
    focusReportingFrequency() {
      return this.company.focus_reporting_frequency ?? 'Monthly'
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          const [period, year] = val.split(' ')
          this.period = period
          this.selectedYear = parseInt(year)
        } else {
          this.period = this.periodOptions[0].value
        }
      },
    },
  },
  methods: {
    onSelectChange() {
      this.$emit('change', `${this.period} ${this.selectedYear}`)
      this.$emit('input', `${this.period} ${this.selectedYear}`)
    },
    onYearChange() {
      this.$emit('change', `${this.period} ${this.selectedYear}`)
      this.$emit('input', `${this.period} ${this.selectedYear}`)
    },
  },
}
</script>
