<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveBillingInfo)">
        <b-row>
          <b-col sm="12" lg="6">
            <div class="d-flex align-items-center mb-3">
              <h1 class="tab-title mb-0">BILLING INFORMATION</h1>
              <b-button pill variant="primary" size="sm" class="ml-3" @click="sameAsPrimaryContact">
                Same as primary contact information
              </b-button>
            </div>

            <ValidationProvider vid="Company name" name="Company Name" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Company Name"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" id="Company name" v-model="billing.name" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Billing Address 1" name="Billing Address 1" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Billing Address 1"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input
                  type="text"
                  id="Billing Address 1"
                  v-model="billing.address_1"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <b-form-group class="col p-0 mb-3" label="Billing Address 2" :disabled="disableForm">
              <b-form-input type="text" v-model="billing.address_2" id="Billing Address 2" />
            </b-form-group>

            <b-form-group class="col p-0 mb-3" label="Billing Address 3" :disabled="disableForm">
              <b-form-input type="text" v-model="billing.address_3" id="Billing Address 3" />
            </b-form-group>

            <ValidationProvider vid="Billing Email" name="Billing Email" rules="required|email" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Billing Email"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input
                  type="text"
                  id="Billing Email"
                  v-model="billing.email"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Billing Telephone"
              name="Billing Telephone"
              rules="required|length:14"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Billing Telephone"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" id="Billing Telephone" v-model="phone" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12" lg="6">
            <!-- <h1 class="card-body-title">BILLING INFORMATION</h1> -->
            <ValidationProvider vid="Country" name="Country" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Country"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" id="Country" v-model="billing.country" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="State" name="State" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="State"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" id="State" v-model="billing.state" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="City" name="City" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="City"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" id="City" v-model="billing.city" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider vid="Postal Code" name="Postal Code" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Postal Code"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input
                  type="text"
                  id="Postal Code"
                  v-model="billing.postal_code"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- save button -->
        <b-row class="form-footer">
          <b-col>
            <b-button type="submit" variant="primary" class="float-right" :disabled="!isAdmin"> Save </b-button>
            <b-button variant="primary" class="float-right mr-2" to="other-information"> Next </b-button>
            <b-button variant="primary" class="float-right mr-2" to="general-information"> Back </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { pickBy, identity } from 'lodash'

export default {
  name: 'BillingInformationView',
  data: () => ({
    billing: {
      city: '',
      name: '',
      email: '',
      phone: '',
      state: '',
      country: '',
      address_1: '',
      address_2: '',
      address_3: '',
      postal_code: '',
    },
  }),
  computed: {
    status() {
      return this.$store.state.company.status
    },
    userCompany() {
      return this.$store.state.company.userCompany
    },
    userPrimaryContact() {
      return this.$store.state.company.userPrimaryContact
    },
    disableForm() {
      return this.status === 'loading'
    },
    phone: {
      get() {
        return this.formatPhoneNumber(this.billing.phone)
      },
      set(value) {
        this.billing.phone = value.replace(/[^\d.]/g, '')
      },
    },
  },
  watch: {
    userCompany: {
      handler() {
        this.billing = { ...this.billing, ...pickBy(this.userCompany.billing_information, identity) }
      },
      deep: true,
    },
  },
  methods: {
    sameAsPrimaryContact() {
      this.billing.name = this.userPrimaryContact.company_name
      this.billing.email = this.userPrimaryContact.email
      this.billing.address_1 = this.userPrimaryContact.address.address_1
      this.billing.address_2 = this.userPrimaryContact.address.address_2
      this.billing.address_3 = this.userPrimaryContact.address.address_3
      this.billing.phone = this.userPrimaryContact.main_office_phone
      this.billing.country = this.userPrimaryContact.address.country
      this.billing.state = this.userPrimaryContact.address.state
      this.billing.city = this.userPrimaryContact.address.city
      this.billing.postal_code = this.userPrimaryContact.address.postal_code
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return phoneNumber
      }
      return '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
    },
    async saveBillingInfo() {
      this.$store.dispatch('company/setStatus', { status: 'loading' })
      try {
        const response = await this.$api.put(`/companies/${this.company.id}`, {
          data: {
            billing_information: pickBy(this.billing, identity),
          },
        })
        if (response.status === 200) {
          this.$store.dispatch('company/setStatus', { status: 'updateSuccess' })
        }
      } catch (error) {
        console.log('submit-error', error)
        this.$store.dispatch('company/setStatus', {
          status: 'error',
          message: error?.response?.data?.error?.message || 'Something went wrong',
        })
      }
    },
  },
}
</script>

<style scoped>
.form-footer {
  margin-top: 10px;
  border-top: 1px solid #e9ecef;
  padding-top: 1.25rem;
}
</style>
