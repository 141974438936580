import { render, staticRenderFns } from "./SSOIFormModal.vue?vue&type=template&id=5fd45c56&scoped=true"
import script from "./SSOIFormModal.vue?vue&type=script&lang=js"
export * from "./SSOIFormModal.vue?vue&type=script&lang=js"
import style0 from "./SSOIFormModal.vue?vue&type=style&index=0&id=5fd45c56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd45c56",
  null
  
)

export default component.exports