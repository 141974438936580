<template>
  <div>
    <ScreenLoader />
    <router-view />
  </div>
</template>
<script>
import { findash } from '@/plugins/findash'
import ScreenLoader from '@/components/ScreenLoader.vue'

export default {
  name: 'App',
  components: {
    ScreenLoader,
  },
  created() {
    findash.mainIndex()
  },
}
</script>

<style lang="scss">
@import 'assets/scss/style.scss';
</style>
