<template>
  <b-input-group :prepend="icon ? '$' : ''">
    <b-form-input
      :name="name"
      type="text"
      :value="formattedValue"
      :placeholder="placeholder"
      :state="state"
      @blur="onBlur"
      @focus="onFocus"
      @change="onChange"
    />
    <b-form-invalid-feedback>{{ error }}</b-form-invalid-feedback>
  </b-input-group>
</template>

<script>
import { numberFormatter } from '@/utils/helpers'

export default {
  name: 'NumberFormattedInput',
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: [Number, String, null],
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // formattedValue: '',
      isOnFocus: false,
    }
  },
  // watch: {
  //   value: {
  //     handler(newVal) {
  //       if (newVal === undefined || newVal === '') return
  //       this.formattedValue = numberFormatter(newVal)
  //     },
  //     immediate: true,
  //   },
  // },
  computed: {
    formattedValue: {
      get() {
        if (this.isOnFocus || this.value === '') return this.value
        return numberFormatter(this.value)
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    moneyIcon() {
      return this.icon ? 'b-input-group' : 'div'
    },
  },
  methods: {
    parseNumber(numStr) {
      if (!numStr) return numStr
      if (numStr.includes('(') && numStr.includes(')')) {
        numStr = numStr.replace('(', '').replace(')', '')
        numStr = '-' + numStr
      }
      numStr = numStr.replace(/,/g, '').replace(/\s/g, '')
      return parseFloat(numStr)
    },
    onFocus(e) {
      this.isOnFocus = true
      this.formattedValue = this.parseNumber(e.target.value)
    },
    onBlur(e) {
      this.isOnFocus = false
      if (e.target.value === '') {
        this.$emit('input', this.defaultValue)
        return
      }
      this.$emit('input', this.parseNumber(e.target.value))
    },
    onChange(e) {
      this.$emit('change', e)
    },
  },
}
</script>
