<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <b-form-group class="mb-0">
        <b-form-select v-model="reportDate" :options="reportDates" class="mb-0" @change="getReport">
          <template #first>
            <b-form-select-option :value="null" disabled>Select Report Date</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <div class="d-flex align-items-center">
        <b-button variant="primary" size="sm" :disabled="!reportDate" class="mr-3" @click="downloadReport('view')">
          Preview PDF
        </b-button>
        <b-button variant="primary" size="sm" :disabled="!reportDate" @click="downloadReport('download')">
          Download PDF
        </b-button>
      </div>
    </div>
    <b-table
      responsive
      outlined
      bordered
      sticky-header="100vh"
      :items="records"
      :busy="tableLoading"
      :fields="fields"
      show-empty
      :tbody-tr-class="rowClass"
    >
      <template #cell(security_description)="{ item }">
        <span :class="item.security_description === 'Total Haircuts' ? 'text-right d-block' : ''">
          {{ item.security_description }}
        </span>
      </template>
      <template #cell(haircut)="{ item }">
        <div class="d-flex justify-content-between">
          <span>$</span>
          <span>
            {{ item.haircut }}
          </span>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { numberFormatter, dateFormatter, manageReportFiles } from '@/utils/helpers'

export default {
  name: 'HaircutSummaryView',
  data() {
    return {
      fields: [
        {
          id: 1,
          label: 'ID',
          viewRecord: false,
        },
        {
          id: 2,
          key: 'security_description',
          label: 'Security Description',
          viewRecord: true,
        },
        {
          id: 3,
          key: 'haircut',
          label: 'Haircut',
          viewRecord: true,
        },
      ],
      status: '',
      records: [],
      reportDate: null,
      reportDates: [],
    }
  },
  computed: {
    tableLoading() {
      return this.status === 'loading'
    },
  },
  created() {
    this.getAvailableTimeReports()
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setPdf: 'report/setPdf',
    }),
    async getReport() {
      try {
        this.status = 'loading'
        this.records = []
        const response = await this.$api.get('/haircut-summary', {
          params: {
            reportDate: this.reportDate,
            company_id: this.company.id,
          },
        })

        const sortedObj = this.sortRecords(response.data?.data)
        Object.keys(sortedObj).forEach((key) => {
          this.records.push({
            security_description: key,
            haircut: numberFormatter(sortedObj[key]) ?? '0.00',
          })
        })
        this.status = 'success'
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
    sortRecords(records) {
      const order = [
        'Total US & Canadian Government Debt Haircut',
        'Total Municipal Securities Haircut',
        'Total Municipal Securities Haircut (MUNI0TH)',
        'Total Commercial Paper Haircut',
        'Total Non-Convertible Debt Haircut',
        'Total Money Markets Haircut',
        'Total Equities Haircut',
        'Total Options Haircut',
        'Total Non-Marketable Securities',
        'Total Other Securities',
        'Total Currencies Haircut',
        'Total Excess Equities Haircut',
        'Total Haircuts',
      ]

      let sortedObj = {}
      for (let i = 0; i < order.length; i++) {
        Object.keys(records).forEach((key) => {
          if (key === order[i]) {
            sortedObj[key] = records[key]
          }
        })
      }

      return sortedObj
    },
    rowClass(row, index) {
      if (row?.security_description === 'Total Haircuts') {
        return 'font-weight-bold text-uppercase bg-gradient'
      }
    },
    downloadReport(mode) {
      this.setLoading(true)
      const params = {
        params: {
          reportDate: this.reportDate,
          download: true,
        },
      }
      this.$api
        .get(`/haircut-summary?company_id=${this.company.id}`, params)
        .then((res) => {
          if (!res.data?.data) {
            this.$showInfo('There is no report.')
            return
          }
          const createdPDF = manageReportFiles(res.data)
          if (mode === 'download') {
            createdPDF.download(`Haircut_Summary_${dateFormatter(this.reportDate)}.pdf`)
            return
          }
          createdPDF.getDataUrl((dataUrl) => {
            this.setPdf({
              src: dataUrl,
              title: `Haircut_Summary_${dateFormatter(this.reportDate)}`,
            })
            this.$bvModal.show('PdfModal')
          })
        })
        .catch((err) => {
          this.$showError(err.response?.data?.error?.message || 'Unable to fetch api records')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    async getAvailableTimeReports() {
      this.status = 'loading'
      try {
        const response = await this.$api.get(`/inventories/available-report-date?company_id=${this.company.id}`)
        this.status = 'success'
        if (response.status === 200 && response.data?.data?.length > 0) {
          this.reportDates = response.data.data.map((report) => {
            return {
              value: report.report_date,
              text: dateFormatter(report.report_date),
            }
          })
          if (this.reportDates.length > 0) {
            this.reportDate = this.reportDates[0].value
            this.getReport()
          }
        }
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
  },
}
</script>
