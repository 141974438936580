<template>
  <div class="iq-comingsoon pt-5">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-6" style="align-items: center; display: flex; justify-content: center">
          <h1>
            Watch this video to <br />
            see how eFINOP works
          </h1>
        </div>
        <div class="col-sm-6 text-end">
          <youtube :video-id="videoId" ref="youtube" @playing="playing"></youtube>
        </div>
      </div>
      <div class="row justify-content-center">
        <b-col class="padding-20">
          <div class="home-items">
            <div class="home-box">
              <b-link class="mx-auto" to="coa/final">
                <i class="ri-bar-chart-grouped-line icon-size"></i>
                <div>CHART OF ACCOUNTS</div>
              </b-link>
            </div>
            <div class="home-box">
              <b-link class="mx-auto" to="trial-balance/upload">
                <i class="ri-bar-chart-grouped-line icon-size"></i>
                <div>TRIAL BALANCE IMPORT</div>
              </b-link>
            </div>
            <div class="home-box">
              <b-link class="mx-auto" to="inventory">
                <i class="ri-bar-chart-grouped-line icon-size"></i>
                <div>INVENTORY</div>
              </b-link>
            </div>
          </div>
          <div class="home-items">
            <div class="home-box">
              <b-link class="mx-auto" to="report">
                <i class="ri-bar-chart-grouped-line icon-size"></i>
                <div>REPORT</div>
              </b-link>
            </div>
            <div class="home-box">
              <b-link class="mx-auto" to="tutorial">
                <i class="ri-bar-chart-grouped-line icon-size"></i>
                <div>TUTORIALS</div>
              </b-link>
            </div>
            <div class="home-box">
              <b-link class="mx-auto" to="settings/users">
                <i class="ri-bar-chart-grouped-line icon-size"></i>
                <div>SETTINGS</div>
              </b-link>
            </div>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)

export default {
  name: 'Introduction',
  data: () => ({
    videoId: 'lG0Ys-2d4MA',
  }),
  methods: {
    async playing(e) {
      console.log('video playign', e)
    },
  },
}
</script>

<style scoped>
/* .home-box {
  text-align: center;
  width: 70%;
  height: 198px;
  border: 5px solid #1f3d73;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  flex-direction: row;
  padding: 15px;
  font-size: 15px;
} */

.home-col {
  display: flex;
  justify-content: center;
}

.home-icons {
  font-size: 72px !important;
  width: 55px !important;
  height: 48px !important;
}

.home-items {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 35%;
  height: 140px;
  padding-top: 25px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.home-box {
  text-align: center;
  border: 3px solid #1f3d73;
  border-radius: 22px;
  padding: 10px;
  font-size: 12px;
  flex: 1 1 0px;
  display: flex;
  height: 100%;
  align-items: center;
}

.icon-size {
  font-size: 25px !important;
}

.padding-20 {
  padding: 25px;
}
</style>
