<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <div class="d-flex align-items-center">
          <slot name="icon"></slot>
          <h4 class="card-title">
            <span class="text-primary font-size-16 ml-3">
              <slot name="title"></slot>
            </span>
          </h4>
        </div>
      </template>
      <template v-slot:body>
        <div class="px-1 pb-3">
          <slot name="header"></slot>
        </div>
        <slot name="body"></slot>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
export default {
  name: 'CardWrapper',
}
</script>
