<template>
  <CardWrapper>
    <template #icon>
      <i class="las la-wallet" style="font-size: 25px"></i>
    </template>
    <template #title>Trial Balance</template>
    <template #header>
      <TableActionsWrapper>
        <template #options>
          <label class="mr-2 mt-1">Time Report</label>
          <b-form-group class="mb-0">
            <b-form-select v-model="timeReport" :options="timeReports" class="mb-0" @change="getList">
              <template #first>
                <b-form-select-option value="">All Time Reports</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </template>

        <template #pagination>
          <CommonPerPage :perPage="perPage" @change="handleEntries" />
        </template>

        <template #actions>
          <b-button variant="primary" to="edit" data-cy="manage-trial-balance">Manage Trial Balance</b-button>
        </template>
      </TableActionsWrapper>
    </template>
    <template #body>
      <TheTable
        :records="records"
        :fields="listTableFields"
        :loading="isLoading"
        :sort-by="sort.sortBy"
        :sort-desc="sort.sortDesc"
        @sort-changed="handleSortChange"
        no-local-sorting
        data-cy="trial-balance-table"
      >
        <template #cell(status)="{ item }">
          <BadgeStatus :status="item.status">
            {{ item.status }}
          </BadgeStatus>
        </template>
        <template #cell(credit)="{ value }">
          <div v-if="value" class="d-flex justify-content-between">
            <span>$</span>
            <span>{{ value }}</span>
          </div>
        </template>
        <template #cell(debit)="{ value }">
          <div v-if="value" class="d-flex justify-content-between">
            <span>$</span>
            <span>{{ value }}</span>
          </div>
        </template>
      </TheTable>
      <TablePagination
        :currentPage="currentPage"
        :rows="rows"
        :perPage="perPage"
        @pagination="handlePagination"
        @entries="handleEntries"
      />
    </template>
  </CardWrapper>
</template>

<script>
import { fields } from './fields'

export default {
  name: 'TrialBalanceListView',
  data() {
    return {
      records: [],
      timeReport: '',
      timeReports: [],
      currentPage: 1,
      rows: 0,
      perPage: 50,
      fetchStatus: 'idle',
      sort: {
        sortBy: 'coa',
        sortDesc: false,
      },
    }
  },
  computed: {
    listTableFields() {
      return fields.filter((_) => _.listTable)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.fetchStatus = 'pending'
        let sortBy = ''
        if (this.sort.sortBy === 'coa') {
          sortBy = 'coa.account_number'
        } else if (this.sort.sortBy === 'focus_setting') {
          sortBy = 'coa.focus_setting.id'
        } else if (this.sort.sortBy === 'ssoi_setting') {
          sortBy = 'coa.ssoi_setting.id'
        } else {
          sortBy = this.sort.sortBy
        }
        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && {
              sort: [`${sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}`, 'time_report:asc'],
            }),
            ...(this.timeReport && { filters: { time_report: this.timeReport } }),
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/trial-balances', params)
        if (response.status === 200 && response.data?.data && response.data?.pagination) {
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.records = response.data.data.map((item) => {
            if (item.debit > 0 && item.credit === 0) {
              item.credit = null
            } else if (item.credit > 0 && item.debit === 0) {
              item.debit = null
            } else if (item.debit === 0 && item.credit === 0) {
              item.credit = null
            }
            return item
          })
          this.fetchStatus = 'success'
          await this.getAvailableTimeReports()
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    async getAvailableTimeReports() {
      this.fetchStatus = 'pending'
      try {
        const response = await this.$api.get(`/trial-balances/available-time-report?company_id=${this.company.id}`)
        if (response.status === 200 && response.data?.data) {
          this.timeReports = response.data.data.map((timeReport) => {
            return {
              value: timeReport.time_report,
              text: timeReport.time_report,
            }
          })
          this.fetchStatus = 'success'
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
  },
}
</script>
