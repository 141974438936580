<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Sign Up</h1>
    <p class="auth-description">
      We have sent an OTP {{ formData.is_phone_number ? 'to your phone number' : 'to your email address' }}.
    </p>

    <v-otp-input
      ref="otpInput"
      input-classes="otp-input"
      separator="-"
      :num-inputs="6"
      :should-auto-focus="true"
      :is-input-num="true"
      @on-complete="handleOTPOnComplete"
      class="d-flex justify-content-center mt-5"
    />

    <b-button
      :disabled="isLoading || isCounting"
      data-cy="resend-otp"
      variant="primary"
      class="mt-5 w-100"
      @click="handleResendOTP"
    >
      <template v-if="isOTPValidating"> Verifying... </template>
      <template v-else>
        <vac ref="countdown" :left-time="120000" @start="handleCountDownStart" @finish="handleCountDownFinish">
          <span slot="process" slot-scope="{ timeObj }"> Resend OTP in {{ `${timeObj.m}:${timeObj.s}` }} </span>
          <span slot="finish"> Resend </span>
        </vac>
      </template>
    </b-button>

    <div class="d-inline-block w-100 mt-3 text-center">
      <span class="text-dark">Already Have an account?</span>
      <router-link to="/auth/sign-in" class="iq-waves-effect pl-1 text-underline"> Sign in </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown, 'vac')

export default {
  name: 'VerifyOtp',
  computed: {
    ...mapGetters({
      userId: 'auth/getAuthUserId',
    }),
  },
  data: () => ({
    isLoading: false,
    formData: {
      otp: '',
      is_phone_number: true,
    },
    isCounting: false,
    isOTPValidating: false,
  }),
  created() {
    if (!this.userId) {
      this.$router.push('/auth/sign-in')
    }
    if (this.$route.query.is_phone_number === 'false') {
      this.formData.is_phone_number = false
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch('auth/verifyRegisterOTP', {
          userId: this.userId,
          otp: this.formData.otp,
          isPhoneNumber: this.formData.is_phone_number,
          type: this.formData.is_phone_number ? 'mobile' : 'email',
        })
        .then((res) => {
          this.$showSuccess(res?.data?.message)
          if (this.formData.is_phone_number === false || this.$route.query.isInvite === 'true') {
            this.$router.push('/auth/sign-in')
          } else {
            this.$router.push('/auth/register/user-form')
          }
        })
        .catch((error) => {
          this.$showError(error?.response?.data?.error?.details?.message || 'Something went wrong')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleResendOTP() {
      this.isLoading = true
      const method = this.formData.is_phone_number ? 'auth/resendRegisterOTP' : 'auth/resendEmailOTP'
      this.$store
        .dispatch(method, {
          userId: this.userId,
        })
        .then((res) => {
          this.$refs.countdown.startCountdown('restart')
          this.$showSuccess(res.data?.message || 'OTP sent successfully')
        })
        .catch((error) => {
          this.$showError(error?.response?.data?.message || 'Something went wrong')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleOTPOnComplete(value) {
      this.formData.otp = value
      this.isOTPValidating = true
      this.onSubmit()
    },
    handleCountDownStart() {
      this.isCounting = true
    },
    handleCountDownFinish() {
      this.isCounting = false
    },
  },
}
</script>
<style>
.loading-btn {
  background: var(--iq-primary) !important;
  border-color: var(--iq-primary) !important;
  padding: 6px 12px 6px 12px !important;
}

.resend-otp-span {
  color: #1e3d73;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.otp-input {
  width: 38px;
  height: 38px;
  padding: 5px;
  margin: 0 8px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
