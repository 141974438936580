<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Sign in</h1>
    <p class="auth-description">Enter your mobile OTP to access admin panel.</p>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider vid="code" name="Code" rules="required" v-slot="{ errors }">
          <div class="form-group">
            <label for="code">OTP</label>
            <input
              type="text"
              v-model="formData.code"
              :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
              id="code"
              placeholder="Mobile OTP"
            />
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>

        <div
          class="d-inline-block w-100"
          style="display: flex !important; justify-content: center; align-items: center"
        >
          <VueLoadingButton
            class="btn btn-primary loading-btn"
            aria-label="Post message"
            @click.native="onSubmit"
            :disabled="invalid"
            :loading="isLoading"
            styled
            >Submit</VueLoadingButton
          >
          <div style="color: black; margin-left: 10px">
            <vac :end-time="new Date().getTime() + 30000" ref="timer" :key="updateTimer">
              <span slot="process" slot-scope="{ timeObj }">{{ `${timeObj.m}:${timeObj.s}` }}</span>
              <span class="resend-otp-span" slot="finish" @click="resendOTP">Resend OTP</span>
            </vac>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import VueLoadingButton from 'vue-loading-button'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown, 'vac')

export default {
  name: 'SignInOtpView',
  components: {
    VueLoadingButton,
  },
  data: () => ({
    isLoading: false,
    updateTimer: 0,
    formData: {
      code: '',
    },
  }),
  computed: {
    ...mapGetters({
      session: 'auth/getSession',
    }),
  },
  created() {
    if (!this.session) {
      this.$router.push('/sign-in')
    }
  },
  methods: {
    resendOTP() {
      this.$store
        .dispatch('auth/resendLoginOTP', {
          username: this.session.username,
        })
        .then((res) => {
          this.$showSuccess(res?.data?.message)
          this.updateTimer++
        })
        .catch((error) => {
          this.$showError(error.response?.data?.error?.message || 'Invalid OTP Code.')
        })
    },
    onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch('auth/verifyLoginOTP', {
          code: this.formData.code,
          session: this.session.session,
          username: this.session.username,
        })
        .then((res) => {
          this.$showSuccess(res.data?.message)
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          this.$showError(error.response?.data?.error?.message || 'Invalid OTP Code.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style>
.loading-btn {
  background: var(--iq-primary) !important;
  border-color: var(--iq-primary) !important;
  padding: 6px 12px 6px 12px !important;
}

.resend-otp-span {
  color: #1e3d73;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
</style>
