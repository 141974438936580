<template>
  <div class="d-flex align-items-end justify-content-between">
    <div class="d-flex" style="gap: 5px">
      <div class="d-flex align-items-start flex-column">
        <slot name="options"></slot>
      </div>

      <div class="d-flex align-items-start flex-column">
        <slot name="pagination"></slot>
      </div>
    </div>

    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {
  name: 'TableActionsWrapper',
}
</script>
