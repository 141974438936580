<template>
  <div>
    <FormModalFocusModal @onSelect="onSelectFocusSetting" />
    <FormModalSSOIModal @onSelect="onSelectSSOISetting" :account-type="form.account_type" />
    <b-modal
      id="coa-form-modal"
      :title="title"
      scrollable
      :ok-title="okButtonTitle"
      @ok="onSubmitCOA"
      @shown="bindForm"
    >
      <ValidationObserver ref="coaModalForm">
        <b-form>
          <b-row>
            <b-col md="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="Account Name" label-for="accountName" invalid-feedback="Account Name is required">
                  <b-form-input
                    id="accountName"
                    :state="errors[0] ? false : null"
                    type="text"
                    v-model="form.account_name"
                    :disabled="fetchStatus === 'loading'"
                  />
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider rules="required|min1" v-slot="{ errors }">
                <b-form-group
                  label="Account Number"
                  label-for="accountNumber"
                  invalid-feedback="Account Number is required"
                >
                  <b-form-input
                    id="accountNumber"
                    :state="errors[0] ? false : null"
                    type="number"
                    v-model.number="form.account_number"
                    :disabled="fetchStatus === 'loading'"
                  />
                </b-form-group>
              </ValidationProvider>

              <b-form-group label="Map Focus Setting" label-for="focus_setting" :disabled="fetchStatus === 'loading'">
                <b-input-group>
                  <b-form-input
                    id="focus_setting"
                    :value="hasFocusSetting ? form.focus_setting.focus_id : 'No Focus Setting Selected'"
                    @click="$bvModal.show('focus-setting-modal')"
                    readonly
                    class="bg-white"
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button v-if="!hasFocusSetting" variant="primary" v-b-modal.focus-setting-modal>
                      Select FOCUS
                    </b-button>
                    <b-input-group-text v-else>
                      <b-icon icon="x" style="cursor: pointer" @click="onClearFocusSetting" />
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <ValidationProvider>
                <b-form-group label="Account Type" label-for="accountType">
                  <b-form-input id="accountType" type="text" v-model="form.account_type" disabled />
                </b-form-group>
              </ValidationProvider>

              <b-form-group label="Map SSOI Setting" label-for="ssoi_setting">
                <b-input-group>
                  <b-form-input
                    id="ssoi_setting"
                    :value="hasSSOISetting ? form.ssoi_setting.ssoi_id : 'No SSOI Setting Selected'"
                    @click="$bvModal.show('ssoi-setting-modal')"
                    readonly
                    class="bg-white"
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button v-if="!hasSSOISetting" variant="primary" v-b-modal.ssoi-setting-modal>
                      Select SSOI
                    </b-button>
                    <b-input-group-text v-else>
                      <b-icon icon="x" style="cursor: pointer" @click="onClearSSOISetting" />
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import FormModalFocusModal from './FormModalFocusModal.vue'
import FormModalSSOIModal from './FormModalSSOIModal.vue'

export default {
  name: 'COAModalForm',
  components: {
    FormModalFocusModal,
    FormModalSSOIModal,
  },
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    coaForm: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
      default: 0,
    },
    accountName: {
      type: String,
      default: '',
    },
    accountNumber: {
      type: Number,
      default: 0,
    },
    accountType: {
      type: String,
      default: '',
    },
    focusSetting: {
      type: Object,
      default: null,
    },
    ssoiSetting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: 0,
        account_name: '',
        account_number: 0,
        account_type: '',
        focus_setting: null,
        ssoi_setting: null,
      },
      clonedForm: {},
      fetchStatus: 'idle',
    }
  },
  computed: {
    title() {
      return this.mode === 'add' ? 'Add New Chart of Account' : 'Edit Chart of Account'
    },
    okButtonTitle() {
      return this.mode === 'add' ? 'Add' : 'Update'
    },
    hasFocusSetting() {
      return this.form.focus_setting && this.form.focus_setting.focus_id !== null
    },
    hasSSOISetting() {
      return this.form.ssoi_setting && this.form.ssoi_setting.ssoi_id !== null
    },
  },
  methods: {
    bindForm() {
      this.form = {
        id: this.id,
        account_name: this.accountName,
        account_number: this.accountNumber,
        account_type: this.accountType,
        focus_setting: this.focusSetting,
        ssoi_setting: this.ssoiSetting,
      }
      this.clonedForm = { ...this.form }
    },
    onSubmitCOA(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.coaModalForm.validate().then(async (valid) => {
        if (!valid) {
          this.$showError('Please fill all the required fields')
          return
        }
        if (Object.keys(this.form).every((key) => this.form[key] === this.clonedForm[key])) {
          this.$emit('coa-updated')
          this.$bvModal.hide('coa-form-modal')
          return
        }
        this.$emit(this.mode === 'add' ? 'coa-added' : 'coa-updated', this.form)
        this.$bvModal.hide('coa-form-modal')
      })
    },
    async updateCOA() {
      try {
        this.fetchStatus = 'loading'
        const response = await this.$api.put(`/coas/new?company_id=${this.company.id}`, {
          data: [
            {
              id: this.form.id,
              accountName: this.form.account_name,
              accountNumber: this.form.account_number,
              focusSetting: this.form.focus_setting?.id || null,
              ssoiSetting: this.form.ssoi_setting?.id || null,
            },
          ],
        })
        if (response.status === 200) this.fetchStatus = 'success'
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error?.message || 'Something went wrong')
      }
    },
    async createCOA() {
      try {
        this.fetchStatus = 'loading'
        const response = await this.$api.post(`/coas?company_id=${this.company.id}`, {
          data: {
            accountName: this.form.account_name,
            accountNumber: this.form.account_number,
            focusSetting: this.form.focus_setting?.id || null,
            ssoiSetting: this.form.ssoi_setting?.id || null,
          },
        })
        if (response.status === 200) this.fetchStatus = 'success'
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error?.message || 'Something went wrong')
      }
    },
    onCloseCOAFormModal() {
      this.form = {
        id: 0,
        account_name: '',
        account_number: 0,
        account_type: '',
        focus_setting: null,
        ssoi_setting: null,
      }
    },
    onSelectFocusSetting(item) {
      this.form.focus_setting = item
      this.form.account_type = item.type
    },
    onClearFocusSetting() {
      this.form.focus_setting = null
    },
    onSelectSSOISetting(item) {
      this.form.ssoi_setting = item
    },
    onClearSSOISetting() {
      this.form.ssoi_setting = null
    },
  },
}
</script>
