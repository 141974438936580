<template>
  <CardWrapper>
    <template #icon>
      <i class="las la-file-invoice-dollar" style="font-size: 25px"></i>
    </template>
    <template #title>Chart of Accounts</template>
    <template #header>
      <TableActionsWrapper>
        <template #pagination>
          <CommonPerPage :perPage="perPage" @change="handleEntries" />
        </template>

        <template #actions>
          <b-button variant="primary" to="edit" data-cy="manage-coa"> Manage Chart of Accounts </b-button>
        </template>
      </TableActionsWrapper>
    </template>
    <template #body>
      <TheTable
        :records="listItems"
        :fields="listTableFields"
        :loading="isLoading"
        :sort-by="sort.sortBy"
        :sort-desc="sort.sortDesc"
        @sort-changed="handleSortChange"
        no-local-sorting
        data-cy="coa-table"
      >
      </TheTable>
      <TablePagination
        :currentPage="currentPage"
        :rows="rows"
        :perPage="perPage"
        @pagination="handlePagination"
        @entries="handleEntries"
      />
    </template>
  </CardWrapper>
</template>

<script>
import { fields } from './fields'

export default {
  name: 'COAList',
  data: () => ({
    listItems: [],
    currentPage: 1,
    rows: 0,
    perPage: 50,
    fetchStatus: 'idle',
    sort: {
      sortBy: 'account_number',
      sortDesc: false,
    },
  }),
  created() {
    this.getList()
  },
  computed: {
    listTableFields() {
      return fields.filter((_) => _.listTable)
    },
    isLoading() {
      return this.fetchStatus === 'pending' || this.fetchStatus === 'idle'
    },
  },
  methods: {
    async getList() {
      try {
        this.fetchStatus = 'pending'
        let sortBy
        if (this.sort.sortBy === 'focus_setting') {
          sortBy = 'focus_setting.focus_id'
        } else if (this.sort.sortBy === 'ssoi_setting') {
          sortBy = 'ssoi_setting.ssoi_id'
        } else {
          sortBy = this.sort.sortBy
        }

        const params = {
          params: {
            ...(this.perPage !== 'All' && {
              pagination: {
                page: this.currentPage,
                pageSize: this.perPage,
              },
            }),
            ...(this.sort.sortBy && { 'sort[]': `${sortBy}:${this.sort.sortDesc ? 'desc' : 'asc'}` }),
            populate: {
              focus_setting: {
                populate: ['focus_setting'],
              },
              ssoi_setting: {
                populate: ['ssoi_setting'],
              },
            },
            company_id: this.company.id,
          },
        }
        const response = await this.$api.get('/coas', params)
        if (response.status === 200 && response.data?.data && response.data?.pagination) {
          this.fetchStatus = 'success'
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
          this.listItems = response.data.data
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    handlePagination(value) {
      this.currentPage = value
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.getList()
    },
    handleSortChange(value) {
      this.sort.sortBy = value.sortBy
      this.sort.sortDesc = value.sortDesc
      this.getList()
    },
  },
}
</script>
