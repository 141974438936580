<template>
  <b-container fluid>
    <iq-card class="card-profile" body-class="card-body-no-padding-top">
      <template v-slot:headerTitle>
        <div class="d-flex align-items-center justify-content-between mt-4">
          <b-breadcrumb class="mb-0" :items="breadcrumbs"></b-breadcrumb>
          <div class="d-flex align-items-center" style="gap: 5px">
            <b-button
              v-if="showReopen"
              type="button"
              :disabled="isLoading"
              variant="primary"
              @click="handleStatus('reopen')"
            >
              <b-icon icon="arrow-counterclockwise"></b-icon>
              Reopen
            </b-button>
            <b-button
              v-if="showOtherButtons"
              type="button"
              :disabled="isLoading"
              variant="danger"
              @click="handleStatus('close')"
            >
              <b-icon icon="x"></b-icon>
              Close
            </b-button>
            <b-button
              v-if="showOtherButtons"
              type="button"
              :disabled="isLoading"
              variant="warning"
              @click="handleStatus('reject')"
            >
              <b-icon icon="clipboard-x"></b-icon>
              Reject
            </b-button>
            <b-button
              v-if="showOtherButtons"
              type="button"
              :disabled="isLoading"
              variant="success"
              style="background-color: green"
              @click="handleStatus('approve')"
            >
              <b-icon icon="check"></b-icon>
              Approve
            </b-button>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <b-tabs v-model="tabIndex">
          <b-tab title="Edited" active v-if="isAdmin">
            <TheTable
              caption="Edited Records"
              caption-top
              :records="editedRecords"
              :fields="columns"
              :loading="isLoading"
            >
            </TheTable>
            <TheTable
              caption="Original Records"
              caption-top
              :records="oldRecords"
              :fields="columns"
              :loading="isLoading"
            >
            </TheTable>
          </b-tab>
          <b-tab title="New" v-if="isAdmin">
            <TheTable :records="createdRecords" :fields="columns" :loading="isLoading"> </TheTable>
          </b-tab>
          <b-tab title="Conversations">
            <DetailModalConversation :id="id" />
          </b-tab>
        </b-tabs>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DetailModalConversation from '@/components/Review/DetailModalConversation.vue'

export default {
  name: 'ReviewDetailView',
  components: {
    DetailModalConversation,
  },
  data() {
    return {
      id: null,
      status: null,
      filter: null,
      createdRecords: [],
      oldRecords: [],
      editedRecords: [],
      coaDetailColumns: [
        {
          id: 1,
          key: 'id',
          label: 'ID',
          viewRecord: false,
        },
        {
          id: 2,
          key: 'account_number',
          label: 'Account Number',
          viewRecord: true,
          sortable: true,
        },
        {
          id: 3,
          key: 'account_name',
          label: 'Account Name',
          viewRecord: true,
          sortable: true,
        },
        {
          id: 4,
          key: 'account_type',
          label: 'Account Type',
          viewRecord: true,
          sortable: true,
        },
        {
          id: 5,
          key: 'allowable',
          label: 'Allowable/ Non-Allowable',
          viewRecord: true,
          sortable: true,
          formatter: (value) => {
            return value ? 'Allowable' : 'Not Allowable'
          },
        },
        {
          id: 6,
          key: 'created_at',
          label: 'Created Date',
          viewRecord: false,
        },
        {
          id: 7,
          key: 'updated_at',
          label: 'Updated Date',
          viewRecord: false,
        },
        {
          id: 9,
          key: 'focus_setting',
          label: 'FOCUS ID',
          viewRecord: true,
          formatter: (value, key, item) => {
            return item.focus_setting ? item.focus_setting.focus_id : null
          },
        },
        {
          id: 10,
          key: 'ssoi_setting',
          label: 'SSOI ID',
          viewRecord: true,
          formatter: (value, key, item) => {
            return item.ssoi_setting ? item.ssoi_setting.ssoi_id : null
          },
        },
      ],
      hcDetailColumns: [
        {
          id: 0,
          key: 'checkbox',
          label: '',
          viewRecord: false,
        },
        {
          id: 1,
          key: 'id',
          label: '#',
          viewRecord: false,
        },
        {
          id: 2,
          key: 'time_report',
          label: 'Time Report',
          viewRecord: false,
        },
        {
          id: 3,
          key: 'type',
          label: 'Type',
          viewRecord: true,
        },
        {
          id: 4,
          key: 'qty',
          label: 'Qty',
          viewRecord: true,
        },
        {
          id: 5,
          key: 'name_description',
          label: 'Description',
          viewRecord: true,
        },
        {
          id: 6,
          key: 'long_or_short',
          label: 'Long/Short',
          viewRecord: true,
        },
        {
          id: 7,
          key: 'identifier',
          label: 'Identifier',
          viewRecord: true,
        },
        {
          id: 8,
          key: 'cusip_isin',
          label: 'Cusip/Isin',
          viewRecord: true,
        },
        {
          id: 9,
          key: 'haircut_amount',
          label: 'Haircut Amount',
          viewRecord: true,
        },
        {
          id: 10,
          key: 'closing_price',
          label: 'Closing Price',
          viewRecord: true,
        },
        {
          id: 11,
          key: 'bonds_face_val',
          label: 'Bonds Face Value',
          viewRecord: true,
        },
        {
          id: 12,
          key: 'tot_pos_val',
          label: 'Total Position Value',
          viewRecord: true,
        },
      ],
      tabIndex: 0,
      breadcrumbs: [
        {
          text: 'Review Requests',
          to: { name: 'app.requests-review', query: { filter: this.filter } },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      fetchStatus: 'idle',
    }
  },
  created() {
    const { id, status } = this.$route.params
    if (!id || !status || !this.$route.query.filter) {
      this.$router.push({ name: 'app.requests-review' })
    }
    this.breadcrumbs[1].text = String(id)
    this.id = Number(id)
    this.status = status
    this.filter = this.$route.query.filter
    if (this.isAdmin) {
      this.getModalList(this.id)
    }
  },
  computed: {
    title() {
      return ''
    },
    columns() {
      switch (this.filter) {
        case 'HAIRCUT_DETAIL':
          return this.hcDetailColumns.filter((column) => column.viewRecord)
        case 'COA':
          return this.coaDetailColumns.filter((column) => column.viewRecord)
        default:
          return []
      }
    },
    isEditTabAndRecordsExist() {
      return this.tabIndex === 0 && (this.editedRecords.length > 0 || this.oldRecords.length > 0)
    },
    isNewTabAndRecordsExist() {
      return this.tabIndex === 1 && this.createdRecords.length > 0
    },
    showReopen() {
      return this.status === 'CLOSED' && this.isAdmin && (this.isEditTabAndRecordsExist || this.isNewTabAndRecordsExist)
    },
    showOtherButtons() {
      return this.status === 'OPEN' && this.isAdmin && (this.isEditTabAndRecordsExist || this.isNewTabAndRecordsExist)
    },
    isLoading() {
      return this.fetchStatus === 'loading'
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setLoadingDesc: 'loader/setDescription',
    }),
    async getModalList(id) {
      this.fetchStatus = 'loading'
      try {
        const response = await this.$api.get(`/review-requests/${id}/contents?company_id=${this.company.id}`)
        const data = response.data?.data
        console.log(data)
        this.createdRecords = data?.data || []
        // this.editedRecords = response.data?.data?.data.edit.map((record) => record.new) || []
        // this.oldRecords = response.data?.data?.data.edit.map((record) => record.old) || []
        this.fetchStatus = 'success'
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    resetModal() {
      this.createdRecords = []
      this.oldRecords = []
      this.editedRecords = []
    },
    handleStatus(status) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to ' + status + ' this request?', {
          title: 'Confirmation',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.setLoading(true)
            this.setLoadingDesc('Updating Request...')
            this.$api
              .get(`/review-requests/${this.id}?company_id=${this.company.id}&type=${status}`)
              .then(() => {
                this.$showSuccess('Request Updated Successfully')
                this.$router.push({ name: 'app.requests-review' })
              })
              .catch((err) => {
                this.$showError(err.response?.data?.error?.message || 'Unable to update the request')
              })
              .finally(() => {
                this.setLoading(false)
                this.setLoadingDesc('')
              })
          }
        })
    },
  },
}
</script>
