<template>
  <b-badge :variant="variant" :style="style">
    <slot></slot>
  </b-badge>
</template>

<script>
export default {
  name: 'BadgeStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    variant() {
      return {
        SUCCESS: 'success',
        ERROR: 'danger',
        PENDING: 'primary',
        ACTIVE: 'success',
        EDITING: 'warning',
        REJECTED: 'danger',
        OPEN: 'primary',
        APPROVED: 'success',
      }[this.status]
    },
    style() {
      return {
        backgroundColor:
          this.variant === 'success'
            ? 'green!important'
            : this.variant === 'danger!important'
              ? 'red'
              : 'primary!important',
      }
    },
  },
}
</script>
