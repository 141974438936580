<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveOtherInformationInfo)">
        <b-row class="overflow-auto" style="height: 90vh">
          <b-col sm="12" lg="6">
            <ValidationProvider
              vid="FINRA registered broker-dealer"
              name="FINRA registered broker-dealer"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Is your company a FINRA registered broker-dealer?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.is_finra"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Broker-Dealer CRD number"
              name="Broker-Dealer CRD number"
              :rules="otherInformation.is_finra ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="If yes, please enter your CRD number"
                :disabled="disableForm || !otherInformation.is_finra"
              >
                <b-form-input type="text" v-model="otherInformation.crd_number" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Do you have a parent company that is a broker-dealer or does your company own another broker-dealer?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="hasParentBrokerDealer"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Company CRD Number"
              name="Company CRD Number"
              :rules="hasParentBrokerDealer ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Parent or Affiliate Company CRD Number (broker-dealer)"
                label-class="required-asterisk"
                :disabled="disableForm || !hasParentBrokerDealer"
              >
                <b-form-input
                  type="text"
                  v-model="otherInformation.finra_crd_number"
                  id="Company CRD Number"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <b-form-group label="Accounting Software" class="col-sm-12 profile-field px-0">
              <b-form-select v-model="otherInformation.accounting_software" :options="accountingSoftwareOptions">
              </b-form-select>
            </b-form-group>

            <ValidationProvider
              vid="Company SEC File Number"
              name="Company SEC File Number"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Company SEC File Number (broker-dealer)"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input
                  type="text"
                  v-model="otherInformation.sec_file_number"
                  id="Company CRD Number"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="Minimum Net Capital Requirement"
              name="Minimum Net Capital Requirement"
              rules="required|noNaN"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Minimum Net Capital Requirement"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  name="Minimum Net Capital Requirement"
                  v-model="otherInformation.min_net_capital_req"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                  icon
                />
                <!-- <b-input-group prepend="$">
                  <NumberFormattedInput
                    v-model="otherInformation.min_net_capital_req"
                    :default-value="null"
                    :state="errors[0] ? false : null"
                  />
                  <b-form-invalid-feedback :state="errors[0] ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-input-group> -->
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="FOCUS Reporting Frequency"
              name="FOCUS Reporting Frequency"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="FOCUS Reporting Frequency"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.focus_reporting_frequency"
                  :options="[
                    { text: 'Monthly', value: 'Monthly' },
                    { text: 'Quarterly', value: 'Quarterly' },
                  ]"
                  :state="errors[0] ? false : null"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              vid="FOCUS Report Pursuant to"
              name="FOCUS Report Pursuant to"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="FOCUS Report Pursuant to"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-select
                  v-model="otherInformation.focus_report_pursuant"
                  :options="[
                    { text: 'None of the above', value: 'None of the above' },
                    { text: 'Rule 17a-5(a)', value: 'Rule 17a-5(a)' },
                    { text: 'Rule 17a-5(b)', value: 'Rule 17a-5(b)' },
                    { text: 'Rule 17a-11', value: 'Rule 17a-11' },
                    {
                      text: 'Special request by designated examining authority',
                      value: 'Special request by designated examining authority',
                    },
                    {
                      text: 'Other',
                      value: 'Other',
                    },
                  ]"
                  :state="errors[0] ? false : null"
                >
                </b-form-select>
              </b-form-group>
            </ValidationProvider>

            <!-- <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3" label="Is disclosed broker?"
                label-class="required-asterisk" :disabled="disableForm">
                <b-form-radio-group v-model="otherInformation.is_disclosed_broker" :options="[
                  { text: 'Yes', value: true },
                  { text: 'No', value: false },
                ]" :state="errors[0] ? false : null">
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider> -->

            <!-- 4. In the "Other Information" tab. There is a duplicate question regarding carrying customer accounts. Eliminate first one "Does respondent carry customer accounts?" -->
            <!-- <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3"
                label="Does respondent carry customer accounts?" label-class="required-asterisk"
                :disabled="disableForm">
                <b-form-radio-group v-model="otherInformation.company.does_respondent_carry_customer_accounts"
                  @input="(e) => onUpdateCompanyProfile('does_respondent_carry_customer_accounts', e)" :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]" :state="errors[0] ? false : null">
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider> -->

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Does Firm carry customer accounts?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.has_firm_carry_customer_accounts"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Does Firm trade for its own account?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.has_firm_trade_own_account"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Does Firm trade on a riskless principal basis?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.has_firm_trade_riskless_principal_basis"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Does Firm trade hold securities for its own account?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.has_firm_trade_hold_securities"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Does the Firm report on a Fully Disclosed basis?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.has_firm_report_fully_disclosed_basis"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <b-form-group
              label="Name of the Firm's clearing firm"
              label-for="clearing_firm_names"
              class="col-sm-12 profile-field px-0"
            >
              <b-form-input
                id="clearing_firm_names"
                v-for="(clearingFirm, index) in 4"
                :key="index"
                type="text"
                v-model="clearing_firm_names[index]"
                class="mt-3"
                :disabled="disableForm"
              >
              </b-form-input>
            </b-form-group>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Is the Firm a self-clearing broker-dealer?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.is_firm_self_clearing_broker_dealer"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Is your company a Registered Investment Advisor?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.is_investment_advisor"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider :rules="otherInformation.is_investment_advisor ? 'required' : ''" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="If yes, please enter the CRD number"
                :disabled="disableForm || !otherInformation.is_investment_advisor"
              >
                <b-form-input
                  type="text"
                  v-model="otherInformation.investment_advisor_crd_number"
                  id="Company CRD Number"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                class="col p-0 mb-3"
                label="Is the RIA an affiliate or owned by the broker-dealer?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.is_ria"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <!-- <ValidationProvider :rules="company.is_ria ? 'required' : ''" v-slot="{ errors }">
              <b-form-group :invalid-feedback="errors[0]" class="col p-0 mb-3" label="If yes, please enter the CRD number"
                :disabled="disableForm || !company.is_ria">
                <b-form-input type="text" :value="company.ria_crd_number"
                  @input="(e) => onUpdateCompanyProfile('ria_crd_number', e)" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider> -->

            <ValidationProvider
              :rules="otherInformation.is_ria ? 'required' : ''"
              name="RIA Custodian/Prime Broker Name"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="RIA Custodian/Prime Broker Name"
                :disabled="disableForm || !otherInformation.is_ria"
              >
                <b-form-input
                  type="text"
                  v-model="otherInformation.prime_broker_name"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <b-form-group label="Fiscal year end">
              <b-form-datepicker
                v-model="otherInformation.fiscal_year_end_date"
                :min="fiscalYear.sDate"
                :max="fiscalYear.eDate"
                no-flip
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
              />
            </b-form-group>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Is your Independent Public Accountant an individual or a firm?"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="public_accountant.category"
                  :options="[
                    {
                      text: 'Individual',
                      value: 'Individual',
                    },
                    {
                      text: 'Firm',
                      value: 'Firm',
                    },
                  ]"
                  :state="errors[0] ? false : null"
                >
                  <b-form-invalid-feedback :state="errors[0] ? false : null">Please select one</b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Name" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Name of Independent Public Accountant"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="public_accountant.address.name" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Number and Street" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Independent Public Accountant Number and Street"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input
                  type="text"
                  v-model="public_accountant.address.street"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Accountant City" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Independent Public Accountant City"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="public_accountant.address.city" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Accountant State" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Independent Public Accountant State"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="public_accountant.address.state" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Accountant Zip Code" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Independent Public Accountant Zip Code"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input
                  type="text"
                  v-model="public_accountant.address.postal_code"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="Independent Public Accountant" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Is your Independent Public Accountant one of the following:"
                label-class="required-asterisk"
              >
                <b-form-radio-group
                  v-model="public_accountant.type"
                  :options="[
                    {
                      text: 'Certified Public Accountant',
                      value: 'Certified Public Accountant',
                    },
                    {
                      text: 'Public Accountant',
                      value: 'Public Accountant',
                    },
                    {
                      text: 'Accountant not resident in United States or its possessions',
                      value: 'Accountant not resident in United States or its possessions',
                    },
                  ]"
                  :state="errors[0] ? false : null"
                  :disabled="disableForm"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <b-form-group label="Names of Subsidiaries and Affiliates" label-size="md">
              <div v-for="(subsidiarie, index) in name_of_subsidiaries_affiliates" :key="index" class="d-block">
                <ValidationProvider
                  :vid="`subsidiaries_name_${index}`"
                  :name="`subsidiaries_name_${index}`"
                  :rules="subsidiarie.phone ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    invalid-feedback="Name is required"
                    label="Subsidiaries/Affiliates:"
                    label-for="subsidiaries"
                    class="px-0"
                  >
                    <b-form-input
                      id="subsidiaries"
                      type="text"
                      :value="subsidiarie.name"
                      @input="subsidiarieInput(index, $event, 'name')"
                      :state="errors[0] ? false : null"
                      :disabled="disableForm"
                    >
                    </b-form-input>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  :vid="`subsidiaries${index}`"
                  :name="`subsidiaries_phone_${index}`"
                  :rules="subsidiarie.name ? 'required|length:14' : ''"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    invalid-feedback="Please enter valid phone number"
                    label="Subsidiary/Affiliate Telephone Number:"
                    label-for="subsidiaries"
                    class="px-0"
                  >
                    <b-form-input
                      id="subsidiaries"
                      type="text"
                      :value="formatPhoneNumber(subsidiarie.phone)"
                      @input="subsidiarieInput(index, $event, 'phone')"
                      :state="errors[0] ? false : null"
                      :disabled="disableForm"
                    >
                    </b-form-input>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </b-form-group>
          </b-col>

          <b-col sm="12" lg="6">
            <h1 class="tab-title">FINOP INFORMATION</h1>
            <b-form-checkbox
              name="same_as_primary_contact"
              class="py-2"
              v-model="isPrimaryContactCFOFirm"
              @change="setAsPrimaryContact"
            >
              Is Primary Contact the CFO of the Firm and the Firm's FINOP?
            </b-form-checkbox>
            <div class="d-lg-flex" style="gap: 4px">
              <ValidationProvider name="First Name" rules="required" v-slot="{ errors }" class="w-100">
                <b-form-group
                  :invalid-feedback="errors[0]"
                  class="col p-0 mb-3"
                  label="First Name"
                  :disabled="disableForm"
                  label-class="required-asterisk"
                >
                  <b-form-input type="text" v-model="finop.first_name" :state="errors[0] ? false : null" />
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="Middle Name" class="w-100">
                <b-form-group class="col p-0 mb-3" label="Middle Name" :disabled="disableForm">
                  <b-form-input type="text" v-model="finop.middle_name" />
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }" class="w-100">
                <b-form-group
                  :invalid-feedback="errors[0]"
                  class="col p-0 mb-3"
                  label="Last Name"
                  label-class="required-asterisk"
                  :disabled="disableForm"
                >
                  <b-form-input type="text" v-model="finop.last_name" :state="errors[0] ? false : null" />
                </b-form-group>
              </ValidationProvider>
            </div>
            <ValidationProvider name="Title" rules="required" v-slot="{ errors }" class="w-100">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Title"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="finop.title" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Office Telephone" rules="required|length:14" v-slot="{ errors }" class="w-100">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Office Telephone"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="officePhone" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Direct Telephone" rules="required|length:14" v-slot="{ errors }" class="w-100">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Direct Telephone"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="directPhone" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Mobile Telephone" rules="required|length:14" v-slot="{ errors }" class="w-100">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Mobile Telephone"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="mobilePhone" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }" class="w-100">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Email"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="finop.email" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              vid="FINOP CRD number"
              name="FINOP CRD number"
              rules="required"
              v-slot="{ errors }"
              class="w-100"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="FINOP CRD number"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-input type="text" v-model="finop.crd_number" :state="errors[0] ? false : null" />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="Finop License Type" rules="required" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Finop License Type"
                label-class="required-asterisk"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="finop.license_type"
                  :options="[
                    { text: 'Series 27', value: 'Series 27' },
                    { text: 'Series 28', value: 'Series 28' },
                  ]"
                  :state="errors[0] ? false : null"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <h1 class="tab-title mt-4">Important eFINOP Beginning Balances</h1>

            <p class="prior-end-paragraph">
              Please enter information where applicable. If you fail to enter the information, the results of your
              company’s FOCUS reports may not be accurate. If all values are left blank, it is assumed your company was
              formed during the current fiscal year.
            </p>

            <ValidationProvider name="Company Partnership" v-slot="{ errors }">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Your company is:"
                :disabled="disableForm"
              >
                <b-form-radio-group
                  v-model="otherInformation.company_type"
                  :options="[
                    {
                      text: 'Sole Proprietorship',
                      value: 'A Sole Proprietorship',
                    },
                    {
                      text: 'Partnership',
                      value: 'A Partnership',
                    },
                    {
                      text: 'Corporation',
                      value: 'Corporation',
                    },
                  ]"
                  :state="errors[0] ? false : null"
                >
                </b-form-radio-group>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              name="prior_end_of_fiscal_year_equity"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                description="(1770) or (1780)"
                :disabled="disableForm"
              >
                <label class="d-block">Prior End of Year Fiscal Year Equity Values</label>
                <label style="font-size: 12px">Sole Proprietorship or Partnership Equity</label>
                <NumberFormattedInput
                  v-model="otherInformation.prior_end_of_fiscal_year_equity"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="preferred_stock" v-slot="{ errors }" class="w-100" rules="noNaN" mode="lazy">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Preferred Stock"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.preferred_stock"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="preferred_stock_coa_number"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Preferred Stock COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.preferred_stock_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="common_stock" v-slot="{ errors }" class="w-100" rules="noNaN" mode="lazy">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Common Stock"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.common_stock"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="common_stock_coa_number"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Common Stock COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.common_stock_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              name="additional_paid_in_capital"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Additional Paid-In Capital"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.additional_paid_in_capital"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="additional_paid_in_capital_coa_number"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Additional Paid-In Capital COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.additional_paid_in_capital_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              name="shareholder_distributions"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Shareholder Distributions"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.shareholder_distributions"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="shareholder_distributions_coa_number"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Shareholder Distributions COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.shareholder_distributions_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="retained_earnings" v-slot="{ errors }" class="w-100" rules="noNaN" mode="lazy">
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Retained Earnings"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.retained_earnings"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="retained_earnings_coa_number"
              v-slot="{ errors }"
              class="w-100"
              rules="noNaN"
              mode="lazy"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Retained Earnings COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.retained_earnings_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              name="capital_stock_in_treasury"
              v-slot="{ errors }"
              class="w-100"
              mode="lazy"
              rules="noNaN"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Capital Stock in Treasury"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.capital_stock_in_treasury"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="capital_stock_in_treasury_coa_number"
              v-slot="{ errors }"
              class="w-100"
              mode="lazy"
              rules="noNaN"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Capital Stock in Treasury COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.capital_stock_in_treasury_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              name="subordinated_liabilities"
              v-slot="{ errors }"
              class="w-100"
              mode="lazy"
              rules="noNaN"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Subordinated Liabilities"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.subordinated_liabilities"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              name="subordinated_liabilities_coa_number"
              v-slot="{ errors }"
              class="w-100"
              mode="lazy"
              rules="noNaN"
            >
              <b-form-group
                :invalid-feedback="errors[0]"
                class="col p-0 mb-3"
                label="Subordinated Liabilities COA Number"
                :disabled="disableForm"
              >
                <NumberFormattedInput
                  v-model="otherInformation.subordinated_liabilities_coa_number"
                  :default-value="null"
                  :state="errors[0] ? false : null"
                  :error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- save button -->
        <b-row class="form-footer">
          <b-col>
            <b-button type="submit" variant="primary" class="float-right" :disabled="!isAdmin"> Save </b-button>
            <b-button variant="primary" class="float-right mr-2" to="user-list"> Next </b-button>
            <b-button variant="primary" class="float-right mr-2" to="billing-information"> Back </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { pickBy, identity, pick, mergeWith, cloneDeep, omit, set, get } from 'lodash'
import { numberFormatter } from '@/utils/helpers'

function numberFormattedProperty(propertyName) {
  return {
    get() {
      if (!this.otherInformation[propertyName]) return
      return numberFormatter(this.otherInformation[propertyName])
    },
    set(value) {
      const formattedValue = this.parsePropertyInput(value)
      this.otherInformation[propertyName] = isNaN(formattedValue) ? null : formattedValue
    },
  }
}

function phoneNumberFormattedProperty(propertyName) {
  return {
    get() {
      return this.formatPhoneNumber(this.finop[propertyName])
    },
    set(value) {
      this.finop[propertyName] = value.replace(/[^\d.]/g, '')
    },
  }
}

export default {
  name: 'OtherInformationView',
  data() {
    return {
      hasParentBrokerDealer: false,
      isPrimaryContactCFOFirm: false,
      accountingSoftwareOptions: [
        { value: null, text: 'Please select an option' },
        {
          text: 'QuickBooks',
          value: 'QUICKSBOOKS',
        },
        {
          text: 'Other',
          value: 'OTHER',
        },
      ],
      otherInformation: {
        crd_number: null,
        finra_crd_number: null,
        sec_file_number: null,
        min_net_capital_req: null,
        prior_end_of_fiscal_year_equity: null,
        company_type: null,
        preferred_stock: null,
        common_stock: null,
        additional_paid_in_capital: null,
        retained_earnings: null,
        capital_stock_in_treasury: null,
        report_frequency: null,
        fiscal_year_end_date: null,
        is_disclosed_broker: null,
        accounting_software: 'QUICKSBOOKS',
        is_finra: null,
        is_investment_advisor: null,
        is_ria: null,
        clearing_broker_name: null,
        investment_advisor_crd_number: null,
        finra_approved_business_lines: null,
        firm_id: 2,
        principal_place_of_business: null,
        does_respondent_carry_customer_accounts: null,
        main_phone: null,
        focus_reporting_frequency: null,
        focus_report_pursuant: [],
        has_firm_carry_customer_accounts: null,
        has_firm_trade_own_account: null,
        has_firm_trade_riskless_principal_basis: null,
        has_firm_trade_hold_securities: null,
        has_firm_report_fully_disclosed_basis: null,
        is_firm_self_clearing_broker_dealer: null,
        ria_crd_number: '',
        prime_broker_name: '',
        shareholder_distributions: null,
        subordinated_liabilities: null,
        preferred_stock_coa_number: null,
        common_stock_coa_number: null,
        additional_paid_in_capital_coa_number: null,
        retained_earnings_coa_number: null,
        capital_stock_in_treasury_coa_number: null,
        subordinated_liabilities_coa_number: null,
        shareholder_distributions_coa_number: null,
      },
      clearing_firm_names: ['', '', ''],
      finop: {
        email: '',
        title: '',
        last_name: '',
        crd_number: '',
        first_name: '',
        middle_name: '',
        direct_phone: '',
        license_type: '',
        mobile_phone: '',
        main_office_phone: '',
      },
      public_accountant: {
        type: '',
        address: {
          city: '',
          name: '',
          state: '',
          street: '',
          postal_code: '',
        },
        category: '',
      },
      name_of_subsidiaries_affiliates: [
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        },
      ],
    }
  },
  computed: {
    status() {
      return this.$store.state.company.status
    },
    userCompany() {
      return this.$store.state.company.userCompany
    },
    userPrimaryContact() {
      return this.$store.state.company.userPrimaryContact
    },
    disableForm() {
      return this.status === 'loading'
    },
    fiscalYear() {
      //start of this year
      const fiscalYearStart = new Date(new Date().getFullYear(), 0, 1)
      //end of this year
      const fiscalYearEnd = new Date(new Date().getFullYear(), 11, 31)
      return {
        sDate: fiscalYearStart,
        eDate: fiscalYearEnd,
      }
    },
    officePhone: phoneNumberFormattedProperty('main_office_phone'),
    directPhone: phoneNumberFormattedProperty('direct_phone'),
    mobilePhone: phoneNumberFormattedProperty('mobile_phone'),
    retained_earnings: numberFormattedProperty('retained_earnings'),
    min_net_capital_req: numberFormattedProperty('min_net_capital_req'),
    prior_end_of_fiscal_year_equity: numberFormattedProperty('prior_end_of_fiscal_year_equity'),
    preferred_stock: numberFormattedProperty('preferred_stock'),
    common_stock: numberFormattedProperty('common_stock'),
    additional_paid_in_capital: numberFormattedProperty('additional_paid_in_capital'),
    shareholder_distributions: numberFormattedProperty('shareholder_distributions'),
    capital_stock_in_treasury: numberFormattedProperty('capital_stock_in_treasury'),
  },
  watch: {
    'otherInformation.is_finra': function (val) {
      if (val === false) {
        this.otherInformation.crd_number = null
      }
    },
    'otherInformation.is_ria': function (val) {
      if (val === false) {
        this.otherInformation.prime_broker_name = null
      }
    },
    'otherInformation.is_investment_advisor': function (val) {
      if (val === false) {
        this.otherInformation.investment_advisor_crd_number = null
      }
    },
    hasParentBrokerDealer: function (val) {
      if (val === false) {
        this.otherInformation.finra_crd_number = null
      }
    },
    userCompany: {
      handler() {
        // const filtered = pickBy(this.userCompany, identity)
        console.log('ran watch')
        if (!this.userCompany) return
        this.otherInformation = {
          ...this.otherInformation,
          ...pick(this.userCompany, Object.keys(this.otherInformation)),
        }
        this.otherInformation.accounting_software = this.userCompany.accounting_software || 'QUICKSBOOKS'
        this.otherInformation.focus_report_pursuant = this.userCompany.focus_report_pursuant || []
        this.clearing_firm_names = this.userCompany.clearing_firm_names
          ? [...this.userCompany.clearing_firm_names]
          : ['', '', '']
        this.hasParentBrokerDealer = this.otherInformation.finra_crd_number !== null

        this.public_accountant = { ...this.public_accountant, ...pickBy(this.userCompany.public_accountant, identity) }
        this.public_accountant.address = {
          ...this.public_accountant.address,
          ...pickBy(this.userCompany.public_accountant?.address, identity),
        }
        if (this.userCompany.name_of_subsidiaries_affiliates) {
          const mergedSubsidiaries = mergeWith(
            this.name_of_subsidiaries_affiliates,
            this.userCompany.name_of_subsidiaries_affiliates,
            (objValue, srcValue) => {
              if (srcValue.name !== '' || srcValue.phone !== '') {
                return srcValue
              }
            },
          )
          this.name_of_subsidiaries_affiliates = cloneDeep(mergedSubsidiaries)
        }

        this.finop = { ...this.finop, ...pickBy(this.userCompany.finop, identity) }

        this.isPrimaryContactCFOFirm = this.userPrimaryContact.is_cfo_firm || false
      },
      deep: true,
    },
  },
  methods: {
    parsePropertyInput(value) {
      if (value === null) return
      const cleanedValue = value.replace(/[^\d.]/g, '')
      const isNegativeString = (value.includes('(') && value.includes(')')) || value.includes('-')
      return isNegativeString ? parseFloat(`-${cleanedValue}`) : parseFloat(cleanedValue)
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return phoneNumber
      }
      return (
        '(' +
        phoneNumber.substr(0, 3) +
        ') ' +
        phoneNumber.substr(3, 3) +
        '-' +
        phoneNumber.substr(6, 4) +
        phoneNumber.substr(10)
      )
    },
    formatFiscalYearEnd(value) {
      if (!value) return value
      if (value.length === 3) {
        return '0' + value.substr(0, 1) + '-' + value.substr(1, 2)
      }
      if (value.length === 4) {
        return value.substr(0, 2) + '-' + value.substr(2, 2)
      }
      return value
    },
    onUpdateDate(value) {
      const isoString = value[0].toISOString()
      this.$store.dispatch('company/updateCompanyProfile', { field: 'fiscal_year_end_date', value: isoString })
    },
    subsidiarieInput(index, e, key) {
      if (key === 'phone') {
        this.name_of_subsidiaries_affiliates[index][key] = e.replace(/[^\d.]/g, '')
        return
      }
      this.name_of_subsidiaries_affiliates[index][key] = e
    },
    setAsPrimaryContact() {
      if (this.isPrimaryContactCFOFirm) {
        this.finop = { ...this.finop, ...pick(this.userPrimaryContact, Object.keys(this.finop)) }
      } else {
        this.finop = {
          email: '',
          title: '',
          last_name: '',
          crd_number: '',
          first_name: '',
          middle_name: '',
          direct_phone: '',
          license_type: '',
          mobile_phone: '',
          main_office_phone: '',
        }
      }
    },
    async saveOtherInformationInfo() {
      this.$store.dispatch('company/setStatus', { status: 'loading' })
      try {
        const filteredSubsidiaries = this.name_of_subsidiaries_affiliates.filter(
          (val) => val.name !== '' && val.phone !== '',
        )

        const hasError = this.name_of_subsidiaries_affiliates.some((obj) => {
          const hasName = obj.name !== ''
          const hasPhone = obj.phone !== ''
          return (hasName && !hasPhone) || (!hasName && hasPhone)
        })

        if (hasError) {
          this.$store.dispatch('company/setStatus', {
            status: 'error',
            message: 'If you want to add a subsidiary, please fill out both name and phone number fields',
          })
          return
        }
        console.log(this.otherInformation)

        const response = await this.$api.put(`/companies/${this.company.id}`, {
          data: {
            primary_contact: this.isPrimaryContactCFOFirm
              ? { ...omit(this.userPrimaryContact, ['address', 'company_name']), is_cfo_firm: true }
              : { ...omit(this.userPrimaryContact, ['address', 'company_name']), is_cfo_firm: false },
            ...this.otherInformation,
            public_accountant: pickBy(this.public_accountant, identity),
            name_of_subsidiaries_affiliates:
              filteredSubsidiaries && filteredSubsidiaries.length > 0 ? filteredSubsidiaries : null,
            clearing_firm_names: this.clearing_firm_names.every((val) => val === '') ? null : this.clearing_firm_names,
            finop: pickBy(this.finop, identity),
          },
        })
        if (response.status === 200) {
          this.$store.dispatch('company/setStatus', { status: 'updateSuccess' })
        }
      } catch (error) {
        console.log('submit-error', error)
        this.$store.dispatch('company/setStatus', {
          status: 'error',
          message: error?.response?.data?.error?.message || 'Something went wrong',
        })
      }
    },
  },
}
</script>

<style scoped>
.prior-end-paragraph {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.form-footer {
  margin-top: 10px;
  border-top: 1px solid #e9ecef;
  padding-top: 1.25rem;
}
</style>
