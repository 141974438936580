<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Password Recovery</h1>
    <p class="auth-description">
      Enter your email address and we'll send you an email with instructions to reset your password.
    </p>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider vid="email" name="email" rules="required|email" v-slot="{ errors }">
          <b-form-group invalid-feedback="Email is invalid" class="col p-0 mb-3">
            <b-form-input
              type="email"
              v-model="formData.email"
              placeholder="Email Address"
              @keyup.enter="handleSubmit(onSubmit)"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </ValidationProvider>

        <b-overlay
          class="d-inline-block mt-3 w-100"
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button type="submit" :disabled="isLoading" variant="primary" class="w-100"> SUBMIT </b-button>
        </b-overlay>

        <div class="d-inline-block w-100 mt-3 text-center">
          <span class="text-dark">Try to signing in?</span>
          <router-link to="sign-in" class="iq-waves-effect pl-1 text-underline"> Sign in </router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordView',
  data: () => ({
    isLoading: false,
    formData: {
      email: '',
    },
  }),
  methods: {
    onSubmit() {
      this.isLoading = true

      this.$store
        .dispatch('auth/forgotPassword', {
          email: this.formData.email,
        })
        .then((res) => {
          console.log('res', res)
          this.$showSuccess(res.data?.data?.message || 'Email sent successfully')
          this.$router.push('reset-password')
        })
        .catch((err) => {
          const error = err.response?.data?.error?.message
          const user_id = err.response?.data?.error?.details?.userId
          switch (error) {
            case 'Your email is not submitted Please complete your registration':
              this.$showInfo(error)
              this.$store.dispatch('auth/setAuthUserId', user_id)
              this.$router.push('register/user-form')
              break
            case 'Your email is not verified':
              this.$showInfo(error)
              this.$store
                .dispatch('auth/resendEmailOTP', { user_id, password: this.formData.password })
                .then((res) => {
                  this.$showSuccess(res?.data?.message)
                  this.$store.dispatch('auth/setAuthUserId', user_id)
                  this.$router.push('register/verify-otp?is_phone_number=false')
                })
                .catch((err) => {
                  this.$showError('Unable to send resend email otp. Please try again later.')
                })
              break
            case 'Your phone number was not confirmed':
              this.$showInfo(error)
              this.$store
                .dispatch('auth/resendRegisterOTP', { userId: user_id })
                .then((res) => {
                  this.$showSuccess(res?.data?.message)
                  this.$store.dispatch('auth/setAuthUserId', user_id)
                  this.$router.push('register/verify-otp?isInvite=true')
                })
                .catch((err) => {
                  this.$showError('Unable to send resend otp. Please try again later.')
                })
              break
            default:
              this.$showError(error)
              break
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
