<template>
  <b-modal id="report-header" :title="title" @hidden="resetModal" hide-footer>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <b-form-group
            label="Name"
            label-for="name"
            invalid-feedback="This field is required"
            label-class="required-asterisk"
          >
            <b-form-input id="name" v-model="form.name" :state="errors[0] ? false : null" type="text"></b-form-input>
          </b-form-group>
        </ValidationProvider>
        <b-form-group label="Description" label-for="description">
          <b-form-input id="description" v-model="form.description" type="text"></b-form-input>
        </b-form-group>
        <b-row class="mt-4">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button type="button" variant="secondary" @click="$bvModal.hide('report-header')"> Cancel</b-button>
            <b-button type="submit" class="ml-2" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  name: 'ReportHeader',
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        // time_report: '',
        description: '',
        name: '',
      },
      nameState: null,
    }
  },
  computed: {
    title() {
      return this.type === 'focus' ? 'FOCUS: NEW REPORT' : 'SSOI: NEW REPORT'
    },
  },
  methods: {
    resetModal() {
      this.form = {
        time_report: '',
        description: '',
        name: '',
      }
      this.nameState = null
    },
    onSubmit() {
      const formData = Object.assign({}, this.form)
      Object.keys(formData).forEach((key) => {
        if (!formData[key]) {
          delete formData[key]
        }
      })
      this.$emit('submit', formData)
      this.$bvModal.hide('report-header')
    },
  },
}
</script>

<style scoped>
.control {
  width: 100%;
}

.control span {
  display: block;
}

.control input[type='text'] {
  padding: 5px 10px;
}

.control.invalid input[type='text'],
.control.invalid span {
  color: #eb0600;
}

.control.invalid input[tabindex='0'] {
  border: 1px #eb0600 solid;
}

.control.invalid span {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
