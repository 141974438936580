<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Sign Up</h1>
    <p class="auth-description">Please fill the details below.</p>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <template v-if="step === 1">
          <ValidationProvider vid="firstName" name="First Name" rules="required" v-slot="{ errors }">
            <b-form-group invalid-feedback="First Name is required" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.firstName"
                id="firstName"
                placeholder="First Name"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="middleName" name="Middle Name">
            <b-form-group>
              <b-form-input type="text" v-model="formData.middleName" id="middleName" placeholder="Middle Name" />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="lastName" name="Last Name">
            <b-form-group>
              <b-form-input type="text" v-model="formData.lastName" id="lastName" placeholder="Last Name" />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="companyName" name="Company Name" rules="required" v-slot="{ errors }">
            <b-form-group invalid-feedback="Company Name is required" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.companyName"
                id="companyName"
                placeholder="Company Name"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </template>
        <template v-if="step === 2">
          <ValidationProvider vid="userTitle" name="User Title" rules="required" v-slot="{ errors }">
            <b-form-group invalid-feedback="User Title is required" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.userTitle"
                id="userTitle"
                placeholder="User Title"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="domain" name="Domain" rules="required" v-slot="{ errors }">
            <b-form-group invalid-feedback="Domain is required" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.domain"
                id="domain"
                placeholder="Domain"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="emailAddress" name="Email Address" rules="required|email" v-slot="{ errors }">
            <b-form-group invalid-feedback="Email is required" class="col p-0 mb-3">
              <b-form-input
                type="email"
                v-model="formData.emailAddress"
                id="emailAddress"
                aria-describedby="emailHelp"
                placeholder="Email Address"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider vid="crdNumber" name="CRD Number" rules="required" v-slot="{ errors }">
            <b-form-group invalid-feedback="CRD Number is required" class="col p-0 mb-3">
              <b-form-input
                type="text"
                v-model="formData.crdNumber"
                id="crdNumber"
                placeholder="CRD Number"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </template>

        <div style="display: flex !important; justify-content: center; margin-top: 15px">
          <template v-if="step === 1">
            <b-button variant="primary" class="d-inline-block w-100" @click="handleNext"> NEXT </b-button>
          </template>
          <template v-if="step === 2">
            <b-overlay
              class="d-inline-block w-100 mr-2"
              :show="isLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
            >
              <b-button :disabled="isLoading" variant="outline-primary" class="w-100" @click="step = 1">
                BACK
              </b-button>
            </b-overlay>
            <b-overlay
              class="d-inline-block w-100"
              :show="isLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
            >
              <b-button type="submit" :disabled="isLoading" variant="primary" class="w-100"> CREATE ACCOUNT </b-button>
            </b-overlay>
          </template>
        </div>

        <div class="d-inline-block w-100 mt-3 text-center">
          <span class="text-dark">Already Have an account?</span>
          <router-link to="/auth/sign-in" class="iq-waves-effect pl-1 text-underline"> Sign in </router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RegisterUserForm',
  props: ['formType'],
  computed: {
    ...mapGetters({
      userId: 'auth/getAuthUserId',
    }),
  },
  data: () => ({
    isLoading: false,
    formData: {
      id: '',
      emailAddress: '',
      firstName: '',
      middleName: '',
      lastName: '',
      companyName: '',
      userTitle: '',
      domain: '',
      crdNumber: '',
    },
    step: 1,
  }),
  created() {
    // if (!this.userId) this.$router.replace('/auth/sign-in')
    this.formData.id = this.userId
  },
  methods: {
    onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch('auth/registerUser', this.formData)
        .then((res) => {
          this.$showSuccess(res?.data?.message)
          this.$router.push('verify-otp?is_phone_number=false')
        })
        .catch((error) => {
          this.$showError(error?.response?.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleNext() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.$refs.form.reset()
          this.step = 2
        }
      })
    },
  },
}
</script>
