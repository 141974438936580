<template>
  <div>
    <b-modal id="PreviewModal" size="xl" scrollable ref="previewModal" @show="getPrefillData">
      <template #modal-header>
        <div class="header">
          <div class="modal-title">
            <b-icon icon="paperclip"></b-icon>
            <div class="ml-1">SSOI Report <span>(Preview)</span></div>
          </div>
          <div class="d-flex align-items-center">
            <b-input-group size="sm">
              <b-input-group-prepend>
                <b-button size="sm" variant="primary">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group-prepend>
              <b-form-input type="text" placeholder="Search"></b-form-input>
            </b-input-group>
            <a class="ml-3" @click="close()"> <i class="ri-close-fill"></i></a>
          </div>
        </div>
      </template>
      <template v-if="!isLoading">
        <div class="grid">
          <div v-if="reportItems && reportItems?.length && reportItems[stepper]" class="font-weight-bold pl-2 mb-3">
            {{ reportItems[stepper]?.title }}
          </div>
          <div v-for="item in reportItems[stepper]?.contents" :key="item.number">
            <PreviewModalForm :data="item" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
      <template #modal-footer>
        <div class="preview-modal-footer">
          <div class="text">
            <div class="text-title">Property of Efinop</div>
            <div class="text-desc">Privileged and Confidential Information</div>
          </div>
          <div>
            <b-button type="button" @click="close()" variant="secondary" class="mr-3"> Cancel </b-button>
            <b-button type="button" variant="primary" class="mr-3" v-if="stepper > 0" @click="stepper--">
              <b-icon icon="chevron-left" font-scale="0.8" style="margin-bottom: 3px"></b-icon>
              Back
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mr-3"
              @click="stepper++"
              v-if="stepper + 1 !== reportItems?.length"
            >
              Next
              <b-icon icon="chevron-right" font-scale="0.8" style="margin-bottom: 3px"></b-icon>
            </b-button>
            <b-button v-if="stepper + 1 === reportItems?.length" type="button" @click="onSubmit" variant="primary">
              Submit
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <div class="spinner" v-if="generatingReport">
      <b-spinner class="align-middle" style="width: 5rem; height: 5rem"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PreviewModalForm from './PreviewModalForm.vue'

export default {
  name: 'ReportPreviewModal',
  props: {
    reportHeader: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      stepper: 0,
      isLoading: false,
      generatingReport: false,
      reportItems: [],
    }
  },
  computed: {
    ...mapGetters({
      prefillParams: 'report/ssoi/getPreviewPrefillParams',
      input: 'report/ssoi/getInput',
    }),
  },
  components: { PreviewModalForm },
  watch: {
    stepper: {
      handler: function () {
        document.getElementById('PreviewModal').scrollTo({ top: 0, behavior: 'smooth' })
      },
    },
  },
  methods: {
    ...mapActions('report/ssoi', ['clearInput']),
    getPrefillData() {
      this.isLoading = true
      this.$api
        .post(`/report/ssoi/preview?company_id=${this.company.id}`, { data: this.prefillParams })
        .then((res) => {
          this.reportItems = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onSubmit() {
      this.generatingReport = true
      const data = {
        ...this.reportHeader,
        input: this.input,
      }
      this.$api
        .post(`/report/ssoi/generate?company_id=${this.company.id}`, {
          data,
        })
        .then((Res) => {
          this.$bvToast.toast('Success', {
            title: 'Report Generated Successfully.',
            variant: 'success',
            solid: true,
          })
          this.$emit('success')
          this.close()
        })
        .catch((Err) => {
          const ErrorText =
            (Err && Err.response && Err.response.data && Err.response.data.error && Err.response.data.error.message) ||
            'Unable to generate report.'
          this.$bvToast.toast('Error', {
            title: ErrorText,
            variant: 'danger',
            solid: true,
          })
        })
        .finally(() => {
          this.generatingReport = false
        })
    },
    close() {
      this.stepper = 0
      this.$bvModal.hide('PreviewModal')
      this.clearInput()
    },
  },
}
</script>

<style scoped>
.modal-title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #212529;
}

.header {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
}

.header span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #a0a0a0;
}

.content-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.item-number {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #a0a0a0;
}

.item-value {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #212529;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
}

.grid-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.grid-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
  padding: 0.5rem;
  border-bottom: 1px solid #e9ecef;
}

.grid-item-header-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.grid-item-header-number {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.grid-item-header-value {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #212529;
}

.header .sub-heading {
  font-size: 18px;
}

.preview-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 1.5rem 0rem 1.5rem 0; */
}

.text {
  padding: 0 1.5rem;
}

.text-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.text-desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
}

.spinner {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
