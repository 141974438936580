<template>
  <div>
    <label class="mr-2 mt-1">Entries</label>
    <b-form-select :value="perPage" :options="['50', '100', '250', 'All']" @change="handleEntries"> </b-form-select>
  </div>
</template>

<script>
export default {
  name: 'CommonPerPage',
  props: {
    perPage: {
      type: [Number, String],
      default: 10,
    },
  },
  methods: {
    handleEntries(e) {
      this.$emit('change', e)
    },
  },
}
</script>
