<template>
  <div class="iq-sidebar">
    <div class="iq-navbar-logo d-flex justify-content-between align-items-center" style="cursor: pointer">
      <span style="font-size: 14px" class="ml-2" @click="miniSidebar">Menu</span>
      <div class="iq-menu-bt align-self-center" @click="miniSidebar">
        <div class="wrapper-menu">
          <div class="main-circle"><i class="ri-menu-line"></i></div>
          <div class="hover-circle"></div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :items="items" :open="true" :horizontal="horizontal" />
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
</template>

<script>
import List from '../menus/ListStyle1'
export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'app.home' }) },
    items: { type: Array },
    logo: {
      type: String,
      default: require('../../../assets/images/efinop-logo.png'),
    },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
  },
  components: {
    List,
  },
  methods: {
    miniSidebar() {
      this.$emit('toggle')
    },
  },
  computed: {
    toggleSidebar() {
      return this.$store.state.findash.toggleSidebar
    },
  },
  watch: {
    toggleSidebar() {
      if (window.innerWidth < 1300) {
        this.$emit('toggle')
      }
    },
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
.font-14-px {
  font-size: 14px;
}

.wave {
  transition: font-size 12s;
}

.app-title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #496475;
  margin: 0px;
}
</style>
