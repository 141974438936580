<template>
  <div>
    <b-modal id="conversation-modal" scrollable title="Conversation" size="lg" @hidden="resetModal">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <div class="card shadow-0 border" style="background-color: #f0f2f5">
            <div class="card-body p-4">
              <div class="form-outline">
                <div v-for="item in records" :key="item.id" class="card mb-4">
                  <div class="card-body" v-if="item">
                    <template v-if="commentEdit && item.id === form.comment_id">
                      <textarea
                        name="item_comment"
                        v-model="form.editedComment"
                        rows="2"
                        max-rows="3"
                        class="form-control bg-white"
                      >
                      </textarea>
                    </template>
                    <template v-else>
                      <p>{{ item.comment }}</p>
                    </template>

                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex flex-row align-items-center">
                        <b-avatar variant="primary" :src="getAvatar(item.efinop_user.avatar)"></b-avatar>
                        <div class="d-flex flex-column">
                          <p class="small mb-0 ms-2 ml-2">
                            {{ fullNameFormat(item.efinop_user.first_name, item.efinop_user.last_name) }}
                          </p>
                          <p class="small text-muted mb-0 ms-2 ml-2">
                            {{ dateFormat(item.createdAt, 'DD/MM/YYYY hh:mm:ss') }}
                          </p>
                        </div>
                      </div>

                      <div v-if="user.id === item.efinop_user.id" class="d-flex flex-row align-items-center">
                        <template v-if="commentEdit">
                          <i
                            @click="commentEdit = false"
                            class="far fa-times-circle mx-2 fa-md text-black"
                            style="margin-top: -0.16rem; cursor: pointer"
                          ></i>
                          <i
                            @click="handleComments('update', item.id)"
                            class="far fa-save mx-2 fa-md text-black"
                            style="margin-top: -0.16rem; cursor: pointer"
                          ></i>
                        </template>
                        <template v-else>
                          <i
                            @click="setForm(item)"
                            class="far fa-edit mx-2 fa-md text-black"
                            style="margin-top: -0.16rem; cursor: pointer"
                          ></i>
                          <i
                            @click="deleteComment(item.id)"
                            class="far fa-trash-alt mx-2 fa-md text-black"
                            style="margin-top: -0.16rem; cursor: pointer"
                          ></i>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group label="Comment" label-for="comment">
                    <textarea
                      name="comment"
                      id="comment"
                      v-model="form.comment"
                      rows="3"
                      max-rows="4"
                      class="form-control bg-white"
                    >
                    </textarea>
                  </b-form-group>
                  <div class="d-flex justify-content-end">
                    <b-button v-if="!form.comment_id" variant="primary" @click="handleComments('create')">
                      Add Comment
                    </b-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-between align-items-center" style="width: 100%">
          <div class="pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="handlePagination"
              class="my-0"
            >
            </b-pagination>
          </div>
          <b-button type="button" variant="outline-secondary" @click="$bvModal.hide('conversation-modal')">
            close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dateNameFormat from '@/mixins/date-name-format-mixins'

export default {
  mixins: [dateNameFormat],
  name: 'ReviewConversationModal',
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      coa_review_id: null,
      perPage: 5,
      currentPage: 1,
      rows: 0,
      records: [],
      form: {
        comment: '',
        editedComment: '',
        comment_id: null,
      },
      comments_columns: [
        {
          id: 1,
          key: 'id',
          label: '#',
          editRecord: false,
          viewRecord: false,
          type: 'text',
        },
        {
          id: 2,
          key: 'comment',
          label: 'COMMENT',
          editRecord: true,
          viewRecord: true,
          type: 'textarea',
          rows: 3,
          maxRows: 4,
          placeholder: 'Enter the comment...',
        },
        {
          id: 3,
          key: 'created_at',
          label: 'CREATED DATE',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 4,
          key: 'updated_at',
          label: 'UPDATED DATE',
          editRecord: false,
          viewRecord: true,
          type: 'text',
        },
        {
          id: 5,
          key: 'published_at',
          label: 'PUBLISHED DATE',
          editRecord: false,
          viewRecord: false,
          type: 'text',
        },
      ],
      commentEdit: false,
    }
  },
  computed: {
    columns() {
      return this.comments_columns.filter((item) => item.editRecord)
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getCommentsList(this.id)
      }
    },
  },
  methods: {
    getCommentsList(id) {
      this.loading = true

      if (id) this.coa_review_id = id

      const params = {
        params: {
          pagination: {
            pageSize: this.perPage,
            page: this.currentPage,
          },
          populate: {
            efinop_user: {
              populate: ['avatar'],
            },
          },
          sort: ['id:asc'],
          filters: {
            review_request: {
              id,
            },
          },
        },
      }

      this.$api
        .get(`/review-comments?company_id=${this.company.id}`, params)
        .then((res) => {
          this.records = res.data.data || []
          const { page, pageCount, pageSize, total } = res.data.pagination
          this.currentPage = page
          this.rows = total
        })
        .catch((err) => {
          this.$showError(err.response.data.error.message || 'Unable to fetch api records')
        })
        .finally(() => {
          this.loading = false
        })
    },
    setFormValue(e) {
      if (e.target?.value) this.form[e.target.name] = e.target.value
    },
    handleComments(type) {
      this.editComment = false
      const method = type === 'create' ? 'post' : 'put'
      const payload =
        type === 'create'
          ? {
              comment: this.form.comment,
              reviewRequestId: this.coa_review_id,
            }
          : {
              commentId: this.form.comment_id,
              comment: this.form.editedComment,
            }
      this.$api[method](`review-comments?company_id=${this.company.id}`, {
        data: payload,
      })
        .then((res) => {
          this.form = {
            comment: '',
            comment_id: null,
          }
          this.getCommentsList(this.coa_review_id)
          this.$showSuccess('Successfully added the comment')
        })
        .catch((err) => {
          this.$showError(err.response.data.error.message || 'Unable to perform the action')
        })
    },
    deleteComment(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this comment?', {
          title: 'Delete Comment',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$api
              .delete(`review-comments?id=${id}&company_id=${this.company.id}`)
              .then(() => {
                this.getCommentsList()
                this.$showSuccess('Successfully deleted the comment')
              })
              .catch((err) => {
                this.$showError(err.response.data.error.message || 'Unable to perform the action')
              })
          }
        })
    },
    resetModal() {
      this.records = []
      this.perPage = 10
      this.currentPage = 1
    },
    handlePagination(page) {
      this.currentPage = page
      this.getCommentsList(this.coa_review_id)
    },
    getAvatar(avatar) {
      return avatar?.formats?.thumbnail?.url || ''
    },
    setForm(data) {
      this.commentEdit = true
      this.form = {
        editedComment: data.comment,
        comment_id: data.id,
      }
    },
  },
}
</script>

<style scoped>
.commentBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 25px;
  padding-left: 0px;
}

.commentBox img {
  width: 6 0px;
  height: 60px;
  margin-right: 25px;
}
</style>
