<template>
  <div>
    <h1 class="auth-title pt-2 pb-2">Set Password</h1>
    <p class="auth-description">We have sent an OTP on your email address.</p>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <b-tooltip target="password-tooltip" triggers="hover">
        <div>Password must be:</div>
        <div>8 characters long</div>
        <div>one uppercase letter</div>
        <div>one lowercase letter</div>
        <div>one number</div>
        <div>one special character</div>
      </b-tooltip>

      <form class="mt-4" @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group invalid-feedback="OTP is invalid" label="OTP" label-for="otp">
            <b-form-input
              id="otp"
              type="text"
              :state="errors[0] ? false : null"
              v-model="formData.otp"
              placeholder="Enter OTP from your email"
            />
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider vid="password" rules="required|custom-password" v-slot="{ errors }">
          <b-form-group :invalid-feedback="errors[0]" label-for="password">
            <template slot="label">
              Password
              <b-icon id="password-tooltip" ref="tooltip" icon="question-circle" aria-hidden="true" />
            </template>
            <b-form-input
              id="password"
              ref="password"
              type="password"
              :state="errors[0] ? false : null"
              v-model="formData.new_password"
              placeholder="Enter password"
            />
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider rules="required|confirmed:@password" v-slot="{ errors }">
          <b-form-group :invalid-feedback="errors[0]" label="Confirm Password" label-for="confirm-password">
            <b-form-input
              id="confirm-password"
              type="password"
              :state="errors[0] ? false : null"
              v-model="formData.confirm_password"
              placeholder="Confirm Password"
            />
          </b-form-group>
        </ValidationProvider>

        <b-overlay
          class="d-inline-block mt-3 w-100"
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button type="submit" :disabled="isLoading" variant="primary" class="w-100"> RESET </b-button>
        </b-overlay>

        <div class="d-inline-block w-100 mt-3 text-center">
          <span class="text-dark">Already Have Account?</span>
          <router-link to="sign-in" class="iq-waves-effect pl-1 text-underline"> Sign in </router-link>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ResetPasswordView',
  created() {
    if (!this.user_id) {
      this.$router.push('sign-in')
    }
  },
  computed: {
    ...mapGetters({
      user_id: 'auth/getAuthUserId',
    }),
  },
  data: () => ({
    isLoading: false,
    formData: {
      otp: '',
      new_password: '',
      confirm_password: '',
    },
  }),
  methods: {
    async onSubmit() {
      this.isLoading = true
      const { new_password, otp } = this.formData
      this.$store
        .dispatch('auth/resetPassword', { otp, new_password, id: this.user_id })
        .then((res) => {
          this.$showSuccess(res.data?.message || 'Password Reset Successfully.')
          this.$router.push('sign-in')
        })
        .catch((error) => {
          this.$showError(error.response?.data?.error?.message || 'Email Verification Failed.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
