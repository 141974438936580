<template>
  <ValidationProvider ref="provider" :name="name" :rules="rules" v-slot="{ errors, classes }">
    <b-form-group
      :class="{ 'mb-0': noMb }"
      class="month-picker-custom"
      :label="label"
      :label-class="rules ? 'required-asterisk' : ''"
    >
      <b-form-input
        type="month"
        :size="size"
        :class="classes"
        v-model="selectedMonth"
        @input="debouncedOnInput"
        :min="min"
        :max="max"
      ></b-form-input>
      <span v-if="errors.length > 0" class="error-text">
        {{ errors[0] }}
      </span>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import moment from 'moment'
import { debounce } from 'lodash'

export default {
  name: 'MonthPicker',
  props: {
    name: {
      type: String,
      default: 'time_report',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: 'required',
    },
    label: {
      type: String,
      default: '',
    },
    noMb: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedMonth: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.selectedMonth = ''
          return
        }
        this.selectedMonth = moment(this.value, 'MMM YY').format('YYYY-MM')
      },
    },
  },
  created() {
    this.debouncedOnInput = debounce(this.onInput, 700)
  },
  methods: {
    async onInput() {
      if (!this.selectedMonth) {
        this.$emit('input', '')
        return
      }
      const date = moment(this.selectedMonth, 'YYYY-MM').format('MMM YY')
      await this.$refs.provider.validate(date)
      this.$emit('input', date.toUpperCase())
    },
  },
}
</script>

<style>
.month-picker-custom input.invalid {
  border-color: #dc3545;
  background-image: none;
  background-color: #fff;
  box-shadow: none;
}

.month-picker-custom .error-text {
  color: #dc3545;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  display: block;
}
</style>
