<template>
  <div>
    <PdfModal />
    <b-container fluid>
      <iq-card body-class="p-0">
        <template v-slot:headerTitle>
          <div class="d-flex align-items-center">
            <i class="las la-file-csv" style="font-size: 25px"></i>
            <h4 class="card-title">
              <span class="text-primary font-size-16 ml-3">Reports</span>
            </h4>
          </div>
        </template>
        <template v-slot:body>
          <div v-if="fetchStatus === 'error'">
            <b-alert variant="danger" show>
              <p class="mb-0">Unable to fetch records. Please try again later.</p>
            </b-alert>
          </div>
          <b-tabs v-if="fetchStatus === 'success'" class="w-100" v-model="tabIndex" pills card @input="onTabChanged">
            <b-tab v-for="tab in tabs" :key="tab.title" :title="tab.name" lazy>
              <router-view></router-view>
            </b-tab>
          </b-tabs>
        </template>
      </iq-card>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PdfModal from '@/components/Reports/PdfModal'

export default {
  name: 'ReportNew',
  data: () => ({
    tabIndex: 0,
    tabs: [
      {
        name: 'FOCUS',
        to: '/reports/focus',
        active: false,
      },
      {
        name: 'SSOI',
        to: '/reports/ssoi',
        active: false,
      },
      {
        name: 'Balance Sheet',
        to: '/reports/balance-sheet',
        active: false,
      },
      {
        name: 'Income Statement',
        to: '/reports/income-statement',
        active: false,
      },
      {
        name: 'Net Capital',
        to: '/reports/net-capital',
        active: false,
      },
      {
        name: 'Non-Allowable Assets',
        to: '/reports/non-allowable-assets',
        active: false,
      },
      {
        name: 'Aggregate Indebtedness',
        to: '/reports/aggregate-indebtedness',
        active: false,
      },
      {
        name: 'Haircut Summary',
        to: '/reports/haircut-summary',
        active: false,
      },
      {
        name: 'Haircut By Security',
        to: '/reports/haircut-by-security',
        active: false,
      },
    ],
    fetchStatus: 'loading',
  }),
  components: { PdfModal },
  computed: {
    ...mapGetters({
      loading: 'loader/getLoading',
    }),
  },
  watch: {
    $route(to) {
      this.tabIndex = this.tabs.findIndex((_) => _.to === to.path)
    },
  },
  async created() {
    this.fetchStatus = 'loading'
    if (this.$route.path === '/reports') {
      this.$router.push('/reports/focus')
    } else {
      this.tabIndex = this.tabs.findIndex((_) => _.to === this.$route.path)
    }
    // if (!this.focusReportDate && this.$route.path !== '/reports/focus') {
    //   await this.getRecords()
    // }
    this.fetchStatus = 'success'
    this.updateActiveTab()
  },
  methods: {
    onTabChanged(e) {
      const currentPath = this.$route.path
      if (currentPath.includes(this.tabs[e].to)) return
      this.$router.push(this.tabs[e].to)
    },
    updateActiveTab() {
      this.tabs.forEach((tab) => {
        tab.active = false
      })
      const tabIndex = this.tabs.findIndex((tab) => tab.path === this.$route.path)
      if (tabIndex !== -1) {
        this.tabs[tabIndex].active = true
      }
    },
    // async getRecords() {
    //   try {
    //     const response = await this.$api.get(`/reports?company_id=${this.company.id}`, {
    //       params: {
    //         sort: 'createdAt:desc',
    //         filters: {
    //           type: 'FOCUS',
    //         },
    //       },
    //     })
    //     console.log(response)
    //     if (response.data.data.length === 0) return
    //     this.setFocusReportDate(response.data.data[0].time_report)
    //   } catch (error) {
    //     if (error.status !== 401) {
    //       this.$showError('Unable to fetch records')
    //       this.fetchStatus = 'error'
    //     }
    //   }
    // },
  },
}
</script>
