<template>
  <div>
    <h1>Network Error</h1>
    <p>There was a network error. Please try again later.</p>

    <router-link to="/">Go to Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NetworkErrorPage',
}
</script>
