<template>
  <div>
    <b-table-simple responsive outlined bordered sticky-header="90vh">
      <template v-if="!tableLoading && fields.length > 0">
        <b-thead>
          <b-tr>
            <b-th v-for="field in fields" :key="field.id">
              <div style="width: 8rem; margin: auto; text-align: center">
                {{ field.label }}
              </div>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="data in tableData">
            <b-tr v-if="data.records.length > 0" class="row-header">
              <b-th :colspan="fields.length" class="text-uppercase font-weight-bold bg-gradient">
                {{ data.type }}
              </b-th>
            </b-tr>
            <ReportTableDataRow v-for="record in data.records" :key="record.id" :record="record" />
            <ReportTableTotalRow
              :colspan="staticFields.length"
              :name="data.totals.name"
              :totals-by-month="data.totals.totalsByMonth"
            />
            <!-- <b-tr v-if="data.records.length > 1">
              <b-td :colspan="fields.length"></b-td>
            </b-tr> -->
          </template>
          <template v-if="lastTotal && lastTotal.name">
            <ReportTableTotalRow
              :colspan="staticFields.length"
              :name="lastTotal.name"
              :totals-by-month="lastTotal.totals"
            />
          </template>
          <b-tr v-if="isNoData">
            <b-td colspan="7" class="text-center">There are no records to show</b-td>
          </b-tr>
        </b-tbody>
      </template>
      <template v-else>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
    </b-table-simple>
  </div>
</template>

<script>
import ReportTableTotalRow from './ReportTableTotalRow.vue'
import ReportTableDataRow from './ReportTableDataRow.vue'

export default {
  name: 'ReportTable',
  components: {
    ReportTableTotalRow,
    ReportTableDataRow,
  },
  props: {
    tableLoading: {
      type: Boolean,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    staticFields: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    lastTotal: {
      type: Object,
      required: false,
    },
    isNoData: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
