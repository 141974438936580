<template>
  <b-container fluid>
    <iq-card body-class="p-0">
      <template v-slot:headerTitle>
        <div class="d-flex align-items-center">
          <img :src="require('../../../assets/images/user/company.svg')" alt="icon" />
          <h4 class="card-title">
            <span class="text-primary font-size-16 ml-3">Company Profile Information</span>
          </h4>
        </div>
      </template>
      <template v-slot:body>
        <b-tabs v-model="tabIndex" class="w-100" pills card @input="onTabChanged">
          <b-tab v-for="tab in tabs" :key="tab.title" :title="tab.title">
            <component :ref="tab.title" :is="tab.component" />
          </b-tab>
        </b-tabs>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import { pickBy, identity, set, get } from 'lodash'

export default {
  name: 'Company',
  data() {
    return {
      tabIndex: 0,
      tabs: [
        {
          title: 'GENERAL INFORMATION',
          path: '/setting/company-profile/general-information',
          component: () => import('./GeneralInformationView.vue'),
        },
        {
          title: 'BILLING INFORMATION',
          path: '/setting/company-profile/billing-information',
          component: () => import('./BillingInformationView.vue'),
        },
        {
          title: 'OTHER INFORMATION',
          path: '/setting/company-profile/other-information',
          component: () => import('./OtherInformationView.vue'),
        },
        {
          title: 'USER LIST',
          path: '/setting/company-profile/user-list',
          component: () => import('./UserListView.vue'),
        },
      ],
    }
  },
  watch: {
    $route(to, from) {
      this.tabIndex = this.tabs.findIndex((tab) => tab.path === to.path)
    },
    status() {
      switch (this.status) {
        case 'updateSuccess': {
          this.$showSuccess('Company profile has been updated')
          this.getCompany()
          break
        }
        case 'error': {
          const message = this.$store.state.company.statusMessage
          this.$showError(message)
          break
        }
      }
    },
  },
  created() {
    this.tabIndex = this.tabs.findIndex((tab) => tab.path === this.$route.path)
    this.getCompany()
  },
  computed: {
    activeTab() {
      return this.tabs[this.tabIndex]
    },
    status() {
      return this.$store.state.company.status
    },
  },
  methods: {
    async getCompany() {
      this.$store.dispatch('company/setStatus', { status: 'loading' })
      try {
        const response = await this.$api.get(`/companies/${this.company.id}`)

        this.$store.dispatch('company/setStatus', { status: 'getSuccess' })
        this.$store.dispatch('company/setUserCompany', response.data.data?.company_info)
        this.$store.dispatch('auth/setCurrentCompany', response.data.data?.company_info)
      } catch (error) {
        this.$store.dispatch('company/setStatus', {
          status: 'error',
          message: error?.response?.data?.error?.message || 'Something went wrong',
        })
      }
    },
    onTabChanged(e) {
      const currentPath = this.$route.path
      if (currentPath.includes(this.tabs[e].path)) return
      this.$router.push(this.tabs[e].path)
    },
    saveCompany() {
      this.$store.dispatch('company/setStatus', 'loading')
      this.$refs[this.activeTab.title][0].$refs.form.validate().then(async (isValid) => {
        if (!isValid) {
          this.$showError('Please fill all required fields')
          this.$store.dispatch('company/setStatus', 'error')
          return
        }
        console.log('saving')
        const { company_name, primary_contact, address } = this.$refs['GENERAL INFORMATION'][0]
        const { billing } = this.$refs['BILLING INFORMATION'][0]
        const {
          otherInformation,
          isPrimaryContactCFOFirm,
          clearing_firm_names,
          public_accountant,
          name_of_subsidiaries_affiliates,
          finop,
        } = this.$refs['OTHER INFORMATION'][0]
        const filteredSubsidiaries = name_of_subsidiaries_affiliates.filter(
          (val) => val.name !== '' && val.phone !== '',
        )

        if (isPrimaryContactCFOFirm) {
          primary_contact.is_cfo_firm = true
        } else {
          primary_contact.is_cfo_firm = false
        }

        const hasError = name_of_subsidiaries_affiliates.some((obj) => {
          const hasName = obj.name !== ''
          const hasPhone = obj.phone !== ''
          return (hasName && !hasPhone) || (!hasName && hasPhone)
        })

        if (hasError) {
          this.$showError('If you want to add a subsidiary, please fill out both name and phone number fields')
          this.$store.dispatch('company/setStatus', 'error')
          return
        }
        try {
          const response = await this.$api.put(`/companies/${this.company.id}`, {
            data: {
              company_name,
              primary_contact: pickBy(primary_contact, identity),
              address: pickBy(address, identity),
              billing_information: pickBy(billing, identity),
              ...otherInformation,
              public_accountant: pickBy(public_accountant, identity),
              name_of_subsidiaries_affiliates:
                filteredSubsidiaries && filteredSubsidiaries.length > 0 ? filteredSubsidiaries : null,
              clearing_firm_names: clearing_firm_names.every((val) => val === '') ? null : clearing_firm_names,
              finop: pickBy(finop, identity),
            },
          })
          if (response.status === 200) {
            this.$store.dispatch('company/setStatus', 'success')
            this.$store.dispatch('auth/setCurrentCompany', response.data.data?.company_info)
            this.$showSuccess('Company profile has been updated')
            this.getCompany()
          }
        } catch (error) {
          console.log('submit-error', error)
          this.$store.dispatch('company/setStatus', 'error')
          this.$showError(error?.response?.data?.error?.message)
        }
      })
    },
    async onSaveAsDraft() {
      try {
        await this.$store.dispatch('company/saveCompanyProfileAsDraft', { id: this.company.id })
        this.$showSuccess('Company profile has been drafted')
      } catch (error) {
        console.log('submit-draft-error', error)
        this.$showError(error?.response?.data?.error?.message)
      }
    },
  },
}
</script>

<style>
.required-asterisk:after {
  content: ' *';
  color: red;
}

.tab-title {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 1.4rem !important;
}
</style>
