import Vue from 'vue'
import 'mutationobserver-shim'
import '@/utils/filters'
import App from './App.vue'
import router from './router'
import store from '@/stores'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import VueCountryCode from 'vue-country-code-select'
import globalMixin from './mixins/globalMixins'
import api from '@/utils/api'
import inactivityService from './services/inactivityService'
import OtpInput from '@bachdgvn/vue-otp-input'
import 'vue-search-select/dist/VueSearchSelect.css'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import infiniteScroll from 'vue-infinite-scroll'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)
Vue.use(infiniteScroll)

Vue.use(AlgoliaComponents)
Vue.use(VueCountryCode)
Vue.use(Autocomplete)
Vue.component('v-otp-input', OtpInput)

Vue.prototype.$api = api

Vue.mixin(globalMixin)

Vue.config.productionTip = false

let vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    this.setupInactivityDetection()
  },
  methods: {
    async setupInactivityDetection() {
      const inactivityCallback = () => {
        if (store.getters['auth/getUser'] === null) return
        // logout user in 2 minutes if not answered
        const forceLogout = setTimeout(
          async () => {
            const currentPath = router.currentRoute.path
            if (router.currentRoute.name !== 'auth.sign-in') router.push('/auth/sign-in' + '?redirect=' + currentPath)
            this.$root.$emit('bv::hide::modal', 'confirm-modal-logout')
            inactivityService.clearInactivityTimer()
            await store.dispatch('auth/logout')
          },
          1000 * 60 * 2,
        )

        this.$bvModal
          .msgBoxConfirm(
            'We have not noticed any activity from you in several minutes. You will be automatically logged out in 2 minutes. Do you wish to continue?',
            {
              id: 'confirm-modal-logout',
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'primary',
              okTitle: 'Continue',
              cancelTitle: 'Logout',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(async (value) => {
            if (value) {
              // 20 minutes in milliseconds
              const inactivityTimeout = 1000 * 60 * 20
              inactivityService.setupInactivityDetection(inactivityCallback, inactivityTimeout)
              clearTimeout(forceLogout)
            } else {
              clearTimeout(forceLogout)
              await store.dispatch('auth/logout')
              inactivityService.clearInactivityTimer()
              const currentPath = router.currentRoute.path
              if (router.currentRoute.name !== 'auth.sign-in') router.push('/auth/sign-in' + '?redirect=' + currentPath)
            }
          })
      }

      // 20 minutes in milliseconds
      const inactivityTimeout = 1000 * 60 * 20
      inactivityService.setupInactivityDetection(inactivityCallback, inactivityTimeout)
    },
  },
}).$mount('#app')

window.vm = vm
