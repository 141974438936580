<template>
  <div class="d-flex justify-content-end">
    <b-button v-if="selectedRows.length > 0" variant="danger" class="mr-2" size="sm" @click="onDelete">
      Delete
    </b-button>
    <b-button variant="primary" size="sm" @click="onSSOINewReport"> New SSOI Report </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SSOITableAction',
  computed: {
    ...mapGetters({
      selectedRows: 'report/ssoi/getSelectedRows',
    }),
  },
  methods: {
    ...mapActions({
      setReportData: 'report/setReportData',
      setLoading: 'loader/setLoading',
      setSelectedRows: 'report/ssoi/setSelectedRows',
      setTriggerFetch: 'report/ssoi/setTriggerFetch',
    }),
    onSSOINewReport() {
      this.setReportData({
        type: 'SSOI: NEW REPORT',
      })
      this.$bvModal.show('report-header')
    },
    onDelete() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete the selected records?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.setLoading(true)
            const ids = this.selectedRows.join(',')
            this.$api
              .delete(`/reports?id=${ids}&company_id=${this.company.id}`)
              .then(() => {
                this.setSelectedRows([])
                this.setTriggerFetch(true)
                this.$showSuccess('Records deleted successfully')
              })
              .catch((err) => {
                this.$showError(err.response.data?.error?.message || 'Unable to delete records')
              })
              .finally(() => {
                this.setLoading(false)
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
