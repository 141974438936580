<template>
  <b-modal id="coa-import-modal" ref="modal" title="Import Chart of Accounts" hide-footer @hidden="resetModal">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
        <ValidationProvider rules="required|csv" v-slot="{ errors }">
          <b-form-group
            label="Select File"
            description="Maximum file size is 10MB. Only csv is accepted"
            invalid-feedback="File is not valid."
          >
            <b-form-file
              v-model="form.files"
              :state="errors[0] ? false : null"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".csv"
              :disabled="isSubmitting"
            ></b-form-file>
          </b-form-group>
        </ValidationProvider>
        <b-row class="mt-4">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button type="button" variant="secondary" @click="$bvModal.hide('coa-import-modal')"> Cancel </b-button>
            <b-button type="reset" class="ml-2" variant="warning" @click="resetModal">Reset</b-button>
            <b-button type="submit" class="ml-2" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { uploadFile } from '@/utils/api'
import { formatBytes } from '@/utils/helpers'
import { mapActions } from 'vuex'

export default {
  name: 'COAImportCSV',
  data() {
    return {
      form: {
        files: null,
      },
      isSubmitting: false,
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setDescription: 'loader/setDescription',
    }),
    resetModal() {
      this.form.file = null
    },
    onSubmit() {
      this.setLoading(true)
      this.setDescription('Importing Chart of Accounts...')
      const files = this.form && this.form.files && [this.form.files]
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      )

      const formData = new FormData()
      formData.append('files', files[0])
      // formData.append('path', `upload/staging/UPLOAD-COA-REVIEW/${this.user.id}`)

      uploadFile(formData, 'UPLOAD_COA')
        .then((res) => {
          this.$showSuccess('File uploaded successfully.')
          this.$bvModal.hide('coa-import-modal')
          this.$emit('uploaded')
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error?.message || 'Unable to create record.'
          const errorDetails = error.response?.data?.error?.details
          const errors = Array.isArray(errorDetails) ? errorDetails : [errorDetails]
          const h = this.$createElement
          const vbMessage = h('p', {}, [
            h('span', { style: { fontWeight: 'bold' } }, errorMessage),
            errors.length > 0 &&
              h('ul', {}, [
                errors.map((error) => {
                  if (!error.message) {
                    return
                  }
                  return h('li', { style: { listStyleType: 'circle' } }, [error.message])
                }),
              ]),
          ])

          this.$showError(vbMessage)
        })
        .finally(() => {
          this.setLoading(false)
          this.setDescription('')
        })
    },
  },
}
</script>
