<template>
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col-lg-6">
        <div class="row" v-for="(card, idx) in dashboardCard" :key="idx">
          <div class="col-lg-6 col-md-6 d-flex" v-for="cardData in card.cardData" :key="cardData.title">
            <DashboardCard
              :icon="cardData.icon"
              :title="cardData.title"
              :description="cardData.description"
              :to="cardData.to"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex justify-content-between align-items-center">
            <div class="iq-header-title">
              <h4 class="card-title">
                <i class="las la-chart-bar mr-2"></i>
                <span class="font-weight-bold">{{ company.company_name || 'Unknown Company' }}</span> KPIs
              </h4>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <div class="d-flex">
                <b-form-group class="mb-0">
                  <b-form-select v-model="timeReport" :options="timeReports" class="mb-0" @change="getStatisticsData">
                    <template #first>
                      <b-form-select-option disabled value="">Select Time Report</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="iq-card-body row m-0 align-items-center">
            <b-table-simple responsive bordered>
              <thead class="thead-light">
                <tr>
                  <th v-for="column in statisticsColumns" :key="column.label">
                    {{ column.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="fetchStatus === 'loading'">
                  <tr>
                    <td colspan="2" class="text-center">
                      <TableSpinner />
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="item in statisticsRows" :key="item.type">
                    <td :class="{ 'pl-5': item.subCategory }">{{ item.label }}</td>
                    <td>
                      <div class="d-flex justify-content-between align-items-center">
                        <span>$</span>
                        <span>{{ item.totals }}</span>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardCard from '../../components/DashboardCard.vue'
import { numberFormatter } from '@/utils/helpers'

export default {
  name: 'Dashboard',
  data() {
    return {
      periodOptions: [
        {
          value: 'Month',
          text: 'Month',
        },
        {
          value: 'Quarter',
          text: 'Quarter',
        },
        {
          value: 'Year',
          text: 'Year',
        },
      ],
      selectedPeriod: 'Month',
      selectedMonth: 'JAN',
      selectedYear: null,
      statisticsColumns: [
        {
          key: 'type',
          label: 'Description',
          size: '200px',
        },
        {
          key: 'totals',
          label: 'Totals',
          size: '200px',
        },
      ],
      statisticsRows: [
        {
          type: 'total_net_capital',
          label: 'Net Capital',
          totals: 0,
        },
        {
          type: 'total_assets',
          label: 'Total Assets',
          totals: 0,
        },
        {
          type: 'total_allowable_assets',
          label: 'Total Allowable Assets',
          totals: 0,
          subCategory: true,
        },
        {
          type: 'total_non_allowable_assets',
          label: 'Total Non-allowable Assets',
          totals: 0,
          subCategory: true,
        },
        {
          type: 'total_liabilities',
          label: 'Total Liabilities',
          totals: 0,
        },
        {
          type: 'total_allowable_liabilities',
          label: 'Total Allowable Liabilities',
          totals: 0,
          subCategory: true,
        },
        {
          type: 'total_non_allowable_liabilities',
          label: 'Total Non-allowable Liabilities',
          totals: 0,
          subCategory: true,
        },
        {
          type: 'total_ownership_equity',
          label: 'Total Ownership Equity',
          totals: 0,
        },
        {
          type: 'total_aggregate_indebtedness',
          label: 'Total Aggregate Indebtedness',
          totals: 0,
        },
        {
          type: 'total_income_revenue',
          label: 'Total Revenue',
          totals: 0,
        },
        {
          type: 'total_expenses',
          label: 'Total Expenses',
          totals: 0,
        },
        {
          type: 'total_net_income',
          label: 'Total Net Income',
          totals: 0,
        },
      ],
      statisticsData: [],
      dashboardCard: [
        {
          cardData: [
            {
              icon: 'las la-search',
              title: 'Review Request',
              description: 'Review requests for approvals and/or changes.',
              to: '/requests-review',
            },
            {
              icon: 'las la-file-invoice-dollar',
              title: 'Chart of Accounts',
              description: 'Import and manage your chart of accounts.',
              to: '/chart-of-accounts/list',
            },
          ],
        },
        {
          cardData: [
            {
              icon: 'las la-wallet',
              title: 'Trial Balance',
              description: 'Import and manage your balances.',
              to: '/trial-balance/list',
            },
            {
              icon: 'las la-archive',
              title: 'Inventory & Haircuts',
              description: 'Import and manage your Inventory & Haircuts.',
              to: '/inventory/list',
            },
          ],
        },
        {
          cardData: [
            {
              icon: 'las la-file-csv',
              title: 'Reports',
              description: 'Produce, review and download reports',
              to: '/reports',
            },
            {
              icon: 'ri-video-line',
              title: 'Tutorials',
              description: 'Watch Tutorials',
              to: '/tutorial',
            },
          ],
        },
      ],
      fetchStatus: 'idle',
      timeReport: '',
      timeReports: [],
    }
  },
  created() {
    this.getAvailableTimeReports()
  },
  methods: {
    async getAvailableTimeReports() {
      this.fetchStatus = 'pending'
      try {
        const response = await this.$api.get(`/trial-balances/available-time-report?company_id=${this.company.id}`)
        if (response.status === 200 && response.data?.data) {
          this.timeReports = response.data.data.map((timeReport) => {
            return {
              value: timeReport.time_report,
              text: timeReport.time_report,
            }
          })
          if (this.timeReports.length > 0) {
            this.timeReport = this.timeReports[0].value
            await this.getStatisticsData()
            return
          }
          this.fetchStatus = 'success'
        }
      } catch (error) {
        this.fetchStatus = 'error'
        this.$showError(error.message || 'Unable to fetch record')
      }
    },
    getStatisticsData() {
      this.statisticsData = []
      this.statisticsRows.forEach((row) => {
        row.totals = 0
      })
      this.fetchStatus = 'loading'
      this.$api
        .get(`/trial-balance/get-totals?company_id=${this.company.id}`, {
          params: {
            time_reports: [this.timeReport],
          },
        })
        .then((res) => {
          const { totals } = res.data?.data?.[this.timeReport] || {}
          Object.keys(totals).forEach((key) => {
            this.statisticsRows.forEach((row) => {
              if (row.type === key) {
                row.totals = numberFormatter(totals[key])
              }
            })
          })
          this.fetchStatus = 'success'
        })
        .catch((error) => {
          console.log(error)
          this.fetchStatus = 'error'
          this.$showError(error.response?.data?.message || 'Something went wrong!')
        })
    },
  },
  components: { DashboardCard },
}
</script>
