import axios from 'axios'
import store from '@/stores'
import router from '@/router'

export const baseURL = process.env.VUE_APP_API_BASE_URL || 'https://api.efinop.com/api'

const api = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 40000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const noAuthApi = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 40000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/accessToken'] || localStorage.getItem('access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete api.defaults.headers.common['Authorization']
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log('error', error)

    if (error.code === 'ERR_NETWORK') {
      delete api.defaults.headers.common['Authorization']
      await store.dispatch('auth/logout')
      router.push('/maintenance')
      return
    }

    const originalRequest = error.config
    const rememberMe = localStorage.getItem('remember_me')
    const userInviteConfirmPath = '/auth/register/invite'
    const currentPath = router.currentRoute.path

    if (error.response.status === 401 && !originalRequest._retry) {
      const newAccessToken = await refreshToken()

      if (newAccessToken) {
        originalRequest._retry = true
        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`
        return api(originalRequest)
      }
    }

    if (error.response.status === 401 && currentPath !== userInviteConfirmPath) {
      delete api.defaults.headers.common['Authorization']
      await store.dispatch('auth/logout')
      if (router.currentRoute.name !== 'auth.sign-in') router.push('/auth/sign-in')
    }

    return Promise.reject(error)
  },
)

export default api

export function setAuthToken(authToken) {
  api.defaults.headers.common['Authorization'] = authToken
}

export function clearAuthToken() {
  delete api.defaults.headers.common['Authorization']
}

export function uploadFile(data, type) {
  const companyId = localStorage.getItem('selected_company_id')
  return api
    .post(`${baseURL}/upload?action=${type}&company_id=${companyId}`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data;',
      },
    })
    .then((response) => response.data)
}

async function refreshToken() {
  const token = localStorage.getItem('refresh_token')
  if (!token) {
    return null
  }

  const username = store.getters['auth/getUser']?.email?.email_address || localStorage.getItem('username')
  if (!username) {
    return null
  }

  localStorage.removeItem('access_token')

  try {
    const newToken = await store.dispatch('auth/updateAccessToken', {
      token,
      username,
    })
    return newToken
  } catch (error) {
    console.log('error-updateAccessToken', error)
    return null
  }
}
