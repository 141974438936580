<template>
  <div class="iq-card iq-card-block iq-card-stretch iq-card-height justify-content-between">
    <div class="iq-card-header iq-box-relative d-flex justify-content-between">
      <div class="iq-box-absolute icon iq-icon-box rounded-circle iq-bg-primary">
        <i :class="icon"></i>
      </div>
      <div class="iq-header-title">
        <h4 class="card-title">
          <span class="text-primary font-size-16">{{ title }}</span>
        </h4>
      </div>
    </div>
    <div class="iq-card-body">
      {{ description }}
    </div>
    <div class="px-3 py-2 text-right">
      <b-link :to="to" class="btn btn-primary">Go</b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },
}
</script>
