<template>
  <b-tr class="row-header">
    <b-td :colspan="colspan" class="text-right font-weight-bold bg-gradient">
      {{ name }}
    </b-td>
    <b-td v-for="(total, idx) in totalsByMonth" :key="idx" class="font-weight-bold bg-gradient text-right">
      {{ total }}
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: 'ReportTableTotalRow',
  props: {
    colspan: {
      type: Number,
      required: true,
      default: 0,
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    totalsByMonth: {
      type: Array,
      required: true,
    },
  },
}
</script>
