var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-collapse',{class:_vm.className,attrs:{"tag":"ul","visible":_vm.open,"id":_vm.idName,"accordion":_vm.accordianName}},_vm._l((_vm.filteredHiddenItems),function(item,index){return _c('li',{key:index,class:item.is_heading
        ? 'iq-menu-title'
        : _vm.activeLink(item) && item.children
          ? 'active'
          : _vm.activeLink(item)
            ? 'active'
            : ''},[(item.is_heading)?_c('i',{staticClass:"ri-subtract-line"}):_vm._e(),(item.is_heading)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._e(),(!item.is_heading)?_c('router-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(item.name),expression:"item.name"}],class:`iq-waves-effect ${_vm.activeLink(item) && item.children ? 'active' : _vm.activeLink(item) ? 'active' : ''}`,attrs:{"to":item.link},nativeOn:{"click":function($event){return _vm.collapseSidebar(item)}}},[(item.is_icon_class)?_c('i',{class:item.icon}):void 0,_c('span',[_vm._v(_vm._s(item.title))]),(item.children)?_c('i',{staticClass:"ri-arrow-right-s-line iq-arrow-right"}):_vm._e(),_c('small',{class:item.append_class,domProps:{"innerHTML":_vm._s(item.append)}})],2):_vm._e(),(item.children)?_c('List',{attrs:{"items":item.children,"open":item.link.name !== '' && _vm.activeLink(item) && item.children
          ? true
          : !!(item.link.name !== '' && _vm.activeLink(item)),"idName":item.name,"accordianName":`sidebar-accordion ${item.class_name}`,"className":`iq-submenu ${item.class_name}`}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }