import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    auth_user_id: 0,
    user: null,
    session: null,
    accessToken: null,
    selectedCompany: null,
    selectedCompanySetting: null,
  },
  getters,
  actions,
  mutations,
}
